import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Fonts, colors } from 'styles/theme';

export const Heading = styled(Fonts.Heading3)`
    margin-bottom: 24px;
`;

export const HeadingExplainer = styled(Fonts.BodyText)`
    margin-top: 4px;
    margin-bottom: 24px;
    max-width: 380px;
`;

export const AuthForm = styled.form`
    border: 2px solid ${colors.white};
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    min-width: 300px;
    max-width: 400px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
`;

export const BottomLink = styled(Link)`
    margin-top: 24px;
`;

export const Logo = styled.img`
    width: 140px;
    margin-bottom: 26px;
`;
