import React, { useCallback, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useSupabase } from 'hooks/useSupabase';
import { useAnalytics } from 'hooks/useAnalytics';
import { useToast } from 'hooks/useToast';

import BackgroundWrapper from 'components/BackgroundWrapper';
import RequiredAsterisk from 'components/RequiredAsterisk';
import TextInput from 'components/TextInput';
import LoadingButton from 'components/LoadingButton';

import GrandLogo from 'assets/grand-logo-green-vert.svg';

import { Fonts, Forms, Inputs, Spacing } from 'styles/theme';
import { ContentInnerWrapper } from 'styles/backgroundWrapper';
import {
    AuthForm,
    BottomLink,
    Heading,
    HeadingExplainer,
    Logo,
} from 'styles/auth';

function Confirm() {
    const { search } = useLocation();
    const { supabase, verifyAccount, userProfile } = useSupabase();
    const { sendEvent } = useAnalytics();
    const { showErrorToast } = useToast();

    const [verificationCode, setVerificationCode] = useState<string>('');
    const [isVerified, setIsVerified] = useState<boolean>(false);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [confirmedPassword, setConfirmedPassword] = useState<string>('');
    const [isPasswordConfirmed, setIsPasswordConfirmed] =
        useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { email, type } = useMemo(() => {
        const urlParams = new URLSearchParams(search);
        return {
            email: urlParams.get('email') ?? '',
            type: urlParams.get('type') as 'invite' | 'recovery' | null,
        };
    }, [search]);

    const onVerificationCodeInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setVerificationCode(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onToggleAcceptTerms = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setHasAcceptedTerms(target.checked);
        },
        []
    );

    const onVerifyAccount = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSubmitting) {
                return;
            }
            setIsSubmitting(true);

            const error = await verifyAccount?.({
                verificationCode,
                email,
                type,
            });

            if (error == null) {
                setIsVerified(true);
                sendEvent?.('sign_up', {
                    method: 'email_verification',
                });
            } else {
                setIsError(true);
                showErrorToast(error.message);
            }

            setIsSubmitting(false);
        },
        [
            email,
            isSubmitting,
            sendEvent,
            showErrorToast,
            type,
            verificationCode,
            verifyAccount,
        ]
    );

    const onPasswordInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setPassword(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onConfirmPasswordInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setConfirmedPassword(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onSetPassword = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSubmitting) {
                return;
            }
            setIsSubmitting(true);

            if (password.length < 8) {
                setIsError(true);
                showErrorToast('Your password must be at least 8 characters');
                setIsSubmitting(false);
                return;
            }

            if (password !== confirmedPassword) {
                setIsError(true);
                showErrorToast('Your passwords do not match');
                setIsSubmitting(false);
                return;
            }

            const { error } = await supabase.auth.updateUser({
                password,
            });

            if (error != null) {
                setIsError(true);
                showErrorToast(error.message);
            } else {
                setIsPasswordConfirmed(true);
            }

            setIsSubmitting(false);
        },
        [
            confirmedPassword,
            isSubmitting,
            password,
            showErrorToast,
            supabase.auth,
        ]
    );

    return (
        <BackgroundWrapper>
            <ContentInnerWrapper>
                {!isVerified && !isPasswordConfirmed ? (
                    <>
                        <Logo src={GrandLogo} alt="grand-logo" />
                        <AuthForm onSubmit={onVerifyAccount}>
                            <Fonts.Heading3>{`Verify`}</Fonts.Heading3>
                            <HeadingExplainer>
                                {`Please verify your account by entering the 6 digit code you received in your email.`}
                                <RequiredAsterisk />
                            </HeadingExplainer>
                            <TextInput
                                type="password"
                                id="verification-code"
                                name="verification-code"
                                placeholder="6-digit code"
                                onInput={onVerificationCodeInput}
                                isError={isError}
                                required
                            />
                            <Spacing.Bumper />
                            <Forms.CheckboxWrapper>
                                <Inputs.Checkbox
                                    type="checkbox"
                                    name="share"
                                    onChange={onToggleAcceptTerms}
                                    checked={hasAcceptedTerms}
                                    required
                                />
                                <Fonts.SmallText $textAlign="left">
                                    {`By checking this box, I acknowledge that I accept The Grand's`}
                                    &nbsp;
                                    <Fonts.SmallLink
                                        href="https://www.thegrand.world/termsofservice"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {`Terms of Service`}
                                    </Fonts.SmallLink>
                                    &nbsp;{`and`}&nbsp;
                                    <Fonts.SmallLink
                                        href="https://www.thegrand.world/privacypolicy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {`Privacy Policy`}
                                    </Fonts.SmallLink>
                                    <RequiredAsterisk />
                                </Fonts.SmallText>
                            </Forms.CheckboxWrapper>
                            <Spacing.Bumper />
                            <LoadingButton
                                type="submit"
                                value="Submit"
                                isLoading={isSubmitting}
                                $stretch
                            >
                                {`Verify`}
                            </LoadingButton>
                            <BottomLink to="/password-reset">
                                <Fonts.SmallLink>{`Invalid code? Get a new code here`}</Fonts.SmallLink>
                            </BottomLink>
                        </AuthForm>
                    </>
                ) : null}

                {isVerified && !isPasswordConfirmed ? (
                    <>
                        <Logo src={GrandLogo} alt="grand-logo" />
                        <AuthForm onSubmit={onSetPassword}>
                            <Fonts.Heading3>{`Welcome, ${
                                userProfile?.full_name?.split(' ')[0] ??
                                'Grand Explorer'
                            }!`}</Fonts.Heading3>
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <TextInput
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Password"
                                onInput={onPasswordInput}
                                isError={isError}
                                required
                            />
                            <Spacing.Bumper />
                            <TextInput
                                type="password"
                                id="confirm-password"
                                name="confirm-password"
                                placeholder="Re-enter password"
                                onInput={onConfirmPasswordInput}
                                isError={isError}
                                required
                            />
                            <Spacing.Bumper />
                            <LoadingButton
                                type="submit"
                                value="Submit"
                                isLoading={isSubmitting}
                                $stretch
                            >
                                {`Set Password`}
                            </LoadingButton>
                        </AuthForm>
                    </>
                ) : null}

                {isVerified && isPasswordConfirmed ? (
                    <>
                        <Heading>{`Your password has been updated!`}</Heading>
                        <Link to="/">
                            <LoadingButton $stretch>{`Continue`}</LoadingButton>
                        </Link>
                    </>
                ) : null}
            </ContentInnerWrapper>
        </BackgroundWrapper>
    );
}

export default Confirm;
