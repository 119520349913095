import React from 'react';

import BackgroundWrapper from 'components/BackgroundWrapper';

import { Fonts, Spacing } from 'styles/theme';
import {
    PulseWrapper,
    Pulse,
    PulseSecondary,
    AlertWrapper,
} from 'styles/downtimeAlert';

function DownTimeAlert() {
    return (
        <BackgroundWrapper>
            <AlertWrapper>
                <PulseWrapper>
                    <Pulse />
                    <PulseSecondary />
                </PulseWrapper>
                <Fonts.LargeText>{`It seems we've run into some technical issues 🤔`}</Fonts.LargeText>
                <Spacing.Bumper />
                <Fonts.LargeText>{`We're looking into it!`}</Fonts.LargeText>
            </AlertWrapper>
        </BackgroundWrapper>
    );
}

export default DownTimeAlert;
