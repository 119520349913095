import { CHARACTER_SET, PROFILE_FIELDS } from 'libs/constants';

// This function is used to map the assessment questions to database insert params
// Eg. The second of three questions in "Career Wellbeing" would be cw2
export const mapAssessmentQuestion = (category: string, index: number) => {
    const prefix = category
        .split(' ')
        .map((word) => word[0]?.toLowerCase())
        .join('');
    return `${prefix}${index + 1}`;
};

export const checkIsProfileComplete = (
    profileRawData: object | null
): boolean => {
    if (profileRawData == null) {
        return false;
    }

    let isProfileComplete = true;
    PROFILE_FIELDS.forEach((profileField) => {
        if (!Object.keys(profileRawData).includes(profileField)) {
            isProfileComplete = false;
        }
    }, []);

    return isProfileComplete;
};

export const generateRandomString = () => {
    let randomString = '';
    for (let i = 0; i < 10; i++) {
        randomString += CHARACTER_SET.charAt(
            Math.floor(Math.random() * CHARACTER_SET.length)
        );
    }
    return randomString;
};

export const getCurrentAndNextPeriod = () => {
    const currentDate = new Date();
    // Start quarters 12 days before actual start date, closer to solstices/equinoxes
    currentDate.setDate(currentDate.getDate() + 12);
    const currentMonth = currentDate.getMonth() + 1;
    const currentQuarter = Math.ceil(currentMonth / 3);
    const currentYear = currentDate.getFullYear();
    const nextQuarter = currentQuarter === 4 ? 1 : currentQuarter + 1;
    const nextYear = currentQuarter === 4 ? currentYear + 1 : currentYear;

    return {
        currentPeriod: `${currentYear} Q${currentQuarter}`,
        nextPeriod: `${nextYear} Q${nextQuarter}`,
    };
};

// Arrays in JavaScript are typed as objects, so this util works for comparing
// both hash maps `{}` and arrays `[]`
export const deepEqual = (object1: object, object2: object) => {
    if (object1 === object2) {
        return true;
    }
    if (
        typeof object1 !== 'object' ||
        typeof object2 !== 'object' ||
        object1 === null ||
        object2 === null
    ) {
        return false;
    }
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        // Not sure how to handle this type error since we don't know what objects
        // will be passed to the comparison function here
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!keys2.includes(key) || !deepEqual(object1[key], object2[key])) {
            return false;
        }
    }
    return true;
};
