import styled, { css } from 'styled-components';

import { colors } from 'styles/theme';

export const ProgressBarWrapper = styled.div`
    position: absolute;
    top: 28px;
    margin-left: 40px;
    margin-right: 40px;
    border-radius: 6px;
    background-color: ${colors.white};
    width: 80%;
    height: 8px;
    padding: 2px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 700px) {
        width: 65%;
    }
`;

export const ProgressBar = styled.div`
    border-radius: 6px;
    background-color: ${colors.primary2};
    height: 100%;
    transition: width 0.4s;
`;

export const SteppedFormContentWrapper = styled.div`
    width: calc(100vw - 64px);
    max-width: 600px;
`;

export const FormStepOuterWrapper = styled.div`
    position: relative;
    width: 100%;
`;

interface FormStepInnerWrapperProps {
    $isHidden?: boolean;
    $isPreviousStep: boolean;
    $isNextStep: boolean;
}

export const FormStepInnerWrapper = styled.div<FormStepInnerWrapperProps>`
    position: absolute;
    left: 0;
    width: 100%;
    transition: left 0.4s, opacity 0.4s;

    ${(props) => {
        switch (props.$isHidden) {
            case true: {
                return css`
                    display: none;
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }};

    ${(props) => {
        switch (props.$isPreviousStep) {
            case true: {
                return css`
                    left: -600px;
                    opacity: 0;
                    pointer-events: none;
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }};

    ${(props) => {
        switch (props.$isNextStep) {
            case true: {
                return css`
                    left: 600px;
                    opacity: 0;
                    pointer-events: none;
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }};
`;
