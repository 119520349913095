import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { PrimaryButtonProps } from 'styles/theme';

import React from 'react';

import Loader from 'components/Loader';

import Lock from 'assets/lock-white.svg';

import { Buttons, Overlays } from 'styles/theme';
import { LockIcon } from 'styles/loadingButton';

interface LoadingButtonProps
    extends PropsWithChildren<
        PrimaryButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
    > {
    isLoading?: boolean;
    isDisabled?: boolean;
}

function LoadingButton(props: LoadingButtonProps) {
    const { isLoading, isDisabled, children } = props;

    return (
        <Buttons.Primary
            {...props}
            $isLoading={isLoading ?? false}
            $isDisabled={isDisabled ?? false}
        >
            {!(isLoading ?? false) ? (
                <>
                    <Overlays.ButtonHover />
                    {children}
                    {isDisabled === true ? (
                        <LockIcon src={Lock} width={12} />
                    ) : null}
                </>
            ) : (
                <Loader />
            )}
        </Buttons.Primary>
    );
}

export default LoadingButton;
