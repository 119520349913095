import styled, { css } from 'styled-components';

import { breakpoints, colors, Fonts, isMobile } from 'styles/theme';

export const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${colors.flatSkyBlue};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    overflow: hidden;
`;

export const BlueMountainsLandscape = styled.img`
    position: absolute;
    top: 100px;
    width: 80%;
`;

export const RetrosWrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 1000px;
    padding: 76px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    overflow: scroll;
    pointer-events: all;

    @media only screen and (max-width: ${breakpoints.small}) {
        padding: 24px;
        padding-top: 64px;
    }
`;

export const Heading = styled(isMobile ? Fonts.Heading3 : Fonts.Heading2)`
    margin: 14px 0;
`;

export const SubHeading = styled(Fonts.LargeText)`
    max-width: 600px;
`;

export const RetrosListWrapper = styled.div`
    margin-top: 64px;
    border-radius: 10px;
    background-color: ${colors.light};
    padding: 48px 44px;

    @media only screen and (max-width: ${breakpoints.small}) {
        padding: 24px;
    }
`;

export const RetrosList = styled.div`
    height: 100%;
`;

interface RetroWrapperProps {
    $isPastRetro?: boolean;
    $shouldDisablePointerEvents?: boolean;
    $inactive?: boolean;
}

export const RetroWrapper = styled.div<RetroWrapperProps>`
    position: relative;
    margin-top: 16px;
    margin-bottom: 48px;
    border: 1px solid ${colors.borderDark};
    border-radius: 8px;
    background-color: ${colors.white};
    padding: 16px;
    padding-left: 24px;
    padding-right: 40px;
    display: flex;
    align-items: center;
    transition: 0.2s;

    &:last-of-type {
        margin-bottom: 0;
    }

    ${(props) => {
        switch (props.$inactive) {
            case true: {
                return;
            }
            case false:
            default: {
                return css`
                    cursor: pointer;

                    &:hover,
                    &:active {
                        box-shadow: ${colors.boxShadow};
                    }
                `;
            }
        }
    }}

    ${(props) => {
        switch (props.$isPastRetro) {
            case true: {
                return css`
                    flex-direction: column;
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }}

    ${(props) => {
        switch (props.$shouldDisablePointerEvents) {
            case true: {
                return css`
                    pointer-events: none;
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }}

    @media only screen and (max-width: ${breakpoints.small}) {
        flex-direction: column;
        text-align: center;
    }
`;

export const RetroUpperWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    @media only screen and (max-width: ${breakpoints.medium}) {
        flex-direction: column;
        text-align: center;
    }
`;

interface IndicatorIconProps {
    $isPastRetro?: boolean;
}

export const IndicatorIcon = styled.img<IndicatorIconProps>`
    position: absolute;
    left: 16px;
    bottom: 16px;
    width: 20px;
    height: 20px;

    ${(props) => {
        switch (props.$isPastRetro) {
            case true: {
                return css`
                    left: 0;
                    bottom: 0;
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }}

    @media only screen and (max-width: ${breakpoints.medium}) {
        top: 16px;
        right: 16px;
        left: none;
    }
`;

interface RetroTextWrapperProps {
    $shouldReduceMargin?: boolean;
}

export const RetroTextWrapper = styled.div<RetroTextWrapperProps>`
    margin-left: 100px;

    ${(props) => {
        switch (props.$shouldReduceMargin) {
            case true: {
                return css`
                    margin-left: 32px;
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }}

    @media only screen and (max-width: ${breakpoints.medium}) {
        margin: auto;
    }
`;
