import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useState,
} from 'react';

interface NavMenuContext {
    isNavMenuOpen: boolean;
    setIsNavMenuOpen: ((newNavMenuState: boolean) => void) | null;
}

const navMenuDefaultState: NavMenuContext = {
    isNavMenuOpen: false,
    setIsNavMenuOpen: null,
};

const NavMenuContext = createContext(navMenuDefaultState);

export function NavMenuProvider(props: PropsWithChildren) {
    const { children } = props;

    const [isNavMenuOpen, setIsNavMenuOpen] = useState<boolean>(false);

    return (
        <NavMenuContext.Provider value={{ isNavMenuOpen, setIsNavMenuOpen }}>
            {children}
        </NavMenuContext.Provider>
    );
}

export const useNavMenu = () => useContext(NavMenuContext);
