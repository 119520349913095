import { ProfileData } from 'hooks/useSupabase';

import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSupabase } from 'hooks/useSupabase';

import { AuthenticateRoute } from 'components/AppRoutes';
import GroupProfiles from 'components/GroupProfiles';
import Profile from 'components/Profile';
import { BannerList } from 'components/ImageSelector';

import BackgroundClouds from 'assets/background-clouds.png';

import {
    MainWrapper,
    Clouds,
    ProfileWrapper,
    Banner,
} from 'styles/profileContainer';

function ProfilesContainer() {
    const { groupId, profileId } = useParams();

    const { groupProfiles } = useSupabase();

    const [internalGroupProfiles, setInternalGroupProfiles] = useState<
        Array<ProfileData>
    >([]);
    const [profile, setProfile] = useState<ProfileData | null>(null);

    const randomBannerIndex = useMemo(
        () => Math.floor(Math.random() * 4) + 1,
        []
    );

    useEffect(() => {
        setInternalGroupProfiles(
            groupProfiles.filter(
                (groupProfile) =>
                    groupProfile.member_groups?.includes(groupId ?? '') ||
                    groupProfile.coach_groups?.includes(groupId ?? '')
            )
        );
    }, [groupId, groupProfiles]);

    useEffect(() => {
        if (profileId != null) {
            setProfile(
                groupProfiles.find((profile) => profile.id === profileId) ??
                    null
            );
        } else {
            setProfile(null);
        }
    }, [groupProfiles, profileId]);

    return (
        <MainWrapper>
            <Clouds src={BackgroundClouds} />
            <AuthenticateRoute />
            <ProfileWrapper>
                <Banner
                    src={
                        profile == null
                            ? BannerList[randomBannerIndex]
                            : BannerList[
                                  // Cannot type this properly because our Supabase raw_data isn't typed
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  profile.raw_data?.banner ?? 0
                              ]
                    }
                />
                {profile == null ? (
                    <GroupProfiles
                        groupId={groupId ?? ''}
                        groupProfiles={internalGroupProfiles}
                    />
                ) : (
                    <Profile profile={profile} />
                )}
            </ProfileWrapper>
        </MainWrapper>
    );
}

export default ProfilesContainer;
