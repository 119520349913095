import styled from 'styled-components';

import { colors } from 'styles/theme';

export const Banner = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: ${colors.light};
    box-shadow: ${colors.boxShadow};
    padding-left: 20px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LeftContentWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ButtonWrapper = styled.div`
    margin-left: 16px;
    position: relative;
`;

export const CloseIcon = styled.img`
    margin-left: 8px;
    cursor: pointer;
`;
