import styled, { keyframes } from 'styled-components';

import { colors } from 'styles/theme';

export const RING_COUNT = 25;

export const AnimationWrapper = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RingAnimation = keyframes`
    0% {
      opacity: 0;
    }
    50% {
        opacity: 1;
    }
  `;

const individualizedRingStyle = () => {
    let styleString = '';
    for (let i = 1; i <= RING_COUNT; i++) {
        styleString += `
            &:nth-child(${i}n) {
                width: calc(8px * ${i});
                height: calc(8px * ${i});
                animation-delay: calc(0.05s * ${i - 1} - 0.5s);
            }
        `;
    }
    return styleString;
};

export const GrandRing = styled.div`
    position: absolute;
    border-radius: 300px;
    animation: ${RingAnimation} 2s ease infinite;
    opacity: 0;

    &:nth-child(odd) {
        border: 1px solid ${colors.primary2};
        box-shadow: 0 0 4px ${colors.primary2};
    }

    &:nth-child(even) {
        border: 1px solid ${colors.primary2};
        box-shadow: 0 0 4px ${colors.primary2};
    }

    ${individualizedRingStyle()}
`;
