import styled, { css } from 'styled-components';

import { colors } from 'styles/theme';
import { AvatarImageWrapper } from 'styles/profile';

export const AvatarListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const BannerListWrapper = styled.div``;

interface SelectableImageWrapperProps {
    $isSelected: boolean;
}

export const SelectableAvatarImageWrapper = styled(
    AvatarImageWrapper
)<SelectableImageWrapperProps>`
    position: relative;
    margin: 8px;
    cursor: pointer;

    ${(props) => {
        switch (props.$isSelected) {
            case true:
                return css`
                    border: 1px solid ${colors.dark2};
                `;
            case false:
            default: {
                return;
            }
        }
    }}
`;

export const BannerImage = styled.img`
    width: 100%;
    max-width: 540px;
`;

export const SelectableBannerImageWrapper = styled.div<SelectableImageWrapperProps>`
    position: relative;
    margin: 8px;
    cursor: pointer;

    ${(props) => {
        switch (props.$isSelected) {
            case true:
                return css`
                    border: 1px solid ${colors.dark2};
                `;
            case false:
            default: {
                return;
            }
        }
    }}
`;

export const BottomButtonsWrapper = styled.div`
    padding-top: 30px;
    display: flex;
    align-items: center;
`;

export const CloseButtonWrapper = styled.div`
    margin-left: 24px;
`;
