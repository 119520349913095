import React, { useCallback } from 'react';

import { useSupabase } from 'hooks/useSupabase';
import { useAnalytics } from 'hooks/useAnalytics';

import GrandLogoGreenVert from 'assets/grand-logo-green-vert.svg';
import GrandLogoGreenHorz from 'assets/grand-logo-green-horz.svg';
import GrandLogoTanVert from 'assets/grand-logo-tan-vert.svg';
import GrandLogoTanHorz from 'assets/grand-logo-tan-horz.svg';

import {
    FooterWrapper,
    FooterSection,
    FooterDivider,
    FooterLinksWrapper,
    FooterLogo,
} from 'styles/footer';
import { Fonts, isMobile } from 'styles/theme';

interface FooterProps {
    isDark?: boolean;
}

function Footer(props: FooterProps) {
    const { isDark } = props;

    const { isSignedIn, signOut } = useSupabase();
    const { sendEvent } = useAnalytics();

    const onSignOut = useCallback(async () => {
        if (isSignedIn) {
            await signOut?.();
            sendEvent?.('logout');
        }
    }, [isSignedIn, sendEvent, signOut]);

    return (
        <FooterWrapper $isDark={isDark ?? false}>
            <FooterSection>
                <FooterLogo
                    src={
                        isMobile
                            ? isDark
                                ? GrandLogoTanVert
                                : GrandLogoGreenVert
                            : isDark
                            ? GrandLogoTanHorz
                            : GrandLogoGreenHorz
                    }
                    width={120}
                />
                <Fonts.SmallLink
                    onClick={onSignOut}
                    $color={isDark ? 'light' : 'light-green'}
                >
                    {`Log out`}
                </Fonts.SmallLink>
            </FooterSection>
            <FooterDivider />
            <FooterSection>
                <Fonts.SmallLink
                    href="mailto:hi@thegrand.world"
                    $color={isDark ? 'light' : 'light-green'}
                >
                    {`Need help? Contact us`}
                </Fonts.SmallLink>
                <FooterLinksWrapper>
                    <Fonts.SmallLink
                        href="https://www.thegrand.world/termsofservice"
                        target="_blank"
                        rel="noreferrer"
                        $color={isDark ? 'light' : 'light-green'}
                    >
                        {`Terms of Service`}
                    </Fonts.SmallLink>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Fonts.SmallLink
                        href="https://www.thegrand.world/privacypolicy"
                        target="_blank"
                        rel="noreferrer"
                        $color={isDark ? 'light' : 'light-green'}
                    >
                        {`Privacy Policy`}
                    </Fonts.SmallLink>
                </FooterLinksWrapper>
            </FooterSection>
        </FooterWrapper>
    );
}

export default Footer;
