import { ProfileData } from 'hooks/useSupabase';

import React, { useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link, useParams } from 'react-router-dom';

import { useBrowserData } from 'hooks/useBrowserData';
import { useSupabase } from 'hooks/useSupabase';
import { useAnalytics } from 'hooks/useAnalytics';

import { checkIsProfileComplete } from 'libs/utils';
import { PROFILE_QUESTIONS } from 'libs/constants';

import EditProfile from 'components/EditProfile';
import ImageSelector, { AvatarList } from 'components/ImageSelector';
import LoadingButton from 'components/LoadingButton';

import Pencil from 'assets/pencil.svg';
import Picture from 'assets/picture.svg';

import { Buttons, Fonts, Overlays, Spacing } from 'styles/theme';
import {
    AvatarImage,
    DetailsWrapper,
    ResponseWrapper,
    EditButton,
    AvatarButton,
    BannerButton,
    ProfileAvatarImageWrapper,
    GroupIdText,
    HeadlineText,
    LocationText,
} from 'styles/profile';

interface ProfileProps {
    profile: ProfileData;
}

function Profile(props: ProfileProps) {
    const { profile } = props;

    const { groupId } = useParams();
    const { isSafari } = useBrowserData();
    const { userId } = useSupabase();
    const { sendEvent } = useAnalytics();

    const [isEditingProfile, setIsEditingProfile] = useState<boolean>(false);
    const [isEditingAvatar, setIsEditingAvatar] = useState<boolean>(false);
    const [isEditingBanner, setIsEditingBanner] = useState<boolean>(false);

    const parsedRawData = useMemo(
        () => JSON.parse(JSON.stringify(profile.raw_data ?? {})),
        [profile.raw_data]
    );

    const isProfileComplete = useMemo(
        () => checkIsProfileComplete(parsedRawData),
        [parsedRawData]
    );

    const onEditProfile = useCallback(() => {
        setIsEditingProfile(true);
        sendEvent?.('profile_info_edit');
    }, [sendEvent]);

    const onEditAvatar = useCallback(() => {
        setIsEditingAvatar(true);
        sendEvent?.('profile_avatar_edit');
    }, [sendEvent]);

    const onEditBanner = useCallback(() => {
        setIsEditingBanner(true);
        sendEvent?.('profile_banner_edit');
    }, [sendEvent]);

    const onClose = useCallback(() => {
        setIsEditingProfile(false);
        setIsEditingAvatar(false);
        setIsEditingBanner(false);
    }, []);

    return (
        <>
            <ProfileAvatarImageWrapper>
                <AvatarImage
                    src={
                        // Cannot type this properly because our Supabase raw_data isn't typed
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        AvatarList[profile.raw_data?.avatar ?? 0]
                    }
                />
            </ProfileAvatarImageWrapper>
            <DetailsWrapper>
                {userId === profile.id && isProfileComplete ? (
                    <>
                        <EditButton onClick={onEditProfile}>
                            <Buttons.OverlayMedium />
                            <img
                                src={Pencil}
                                width={20}
                                aria-label="edit-icon"
                            />
                        </EditButton>
                        <AvatarButton onClick={onEditAvatar}>
                            <Buttons.OverlayMedium />
                            <img
                                src={Picture}
                                width={20}
                                aria-label="edit-icon"
                            />
                        </AvatarButton>
                        <BannerButton onClick={onEditBanner}>
                            <Buttons.OverlayMedium />
                            <img
                                src={Picture}
                                width={20}
                                aria-label="edit-icon"
                            />
                        </BannerButton>
                    </>
                ) : null}
                <Spacing.Bumper />
                <Fonts.Heading4>{profile.full_name}</Fonts.Heading4>
                {isProfileComplete ? (
                    <>
                        <HeadlineText>{parsedRawData?.headline}</HeadlineText>
                        <LocationText $color="light-green">{`${parsedRawData?.city}, ${parsedRawData?.state}, ${parsedRawData?.country}`}</LocationText>
                        {profile.is_active !== true ? null : (
                            <Link to={`/profiles/${groupId}`}>
                                <Fonts.SmallText $color="light-green">
                                    {`Group: `}
                                    <GroupIdText $color="light-green">{`${groupId}`}</GroupIdText>
                                </Fonts.SmallText>
                            </Link>
                        )}
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <ResponseWrapper>
                            <Fonts.SmallCapsText>
                                {
                                    PROFILE_QUESTIONS[
                                        parsedRawData?.first_question_index ?? 0
                                    ]?.question
                                }
                            </Fonts.SmallCapsText>
                            <Spacing.Bumper />
                            <Fonts.BodyQuote $color="orange">{`“${parsedRawData?.first_question_response}”`}</Fonts.BodyQuote>
                        </ResponseWrapper>
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <ResponseWrapper>
                            <Fonts.SmallCapsText>
                                {
                                    PROFILE_QUESTIONS[
                                        parsedRawData?.second_question_index ??
                                            0
                                    ]?.question
                                }
                            </Fonts.SmallCapsText>
                            <Spacing.Bumper />
                            <Fonts.BodyQuote $color="orange">{`“${parsedRawData?.second_question_response}”`}</Fonts.BodyQuote>
                        </ResponseWrapper>
                    </>
                ) : (
                    <>
                        <Spacing.Bumper />
                        <LoadingButton
                            onClick={onEditProfile}
                        >{`Complete your profile!`}</LoadingButton>
                    </>
                )}
                <Spacing.Bumper />
                <Spacing.Bumper />
                <Spacing.Bumper />
            </DetailsWrapper>

            {userId === profile.id && (!isSafari || isEditingProfile)
                ? createPortal(
                      <Overlays.ModalOuterWrapper $isOpen={isEditingProfile}>
                          <Overlays.BackgroundOverlay
                              onClick={onClose}
                              $isOpen={isEditingProfile}
                          />
                          <Overlays.ModalInnerWrapper>
                              <EditProfile
                                  profile={profile}
                                  onClose={onClose}
                              />
                          </Overlays.ModalInnerWrapper>
                      </Overlays.ModalOuterWrapper>,
                      document.getElementById('app-wrapper') ?? document.body
                  )
                : null}

            {userId === profile.id &&
            (!isSafari || isEditingAvatar || isEditingBanner)
                ? createPortal(
                      <Overlays.ModalOuterWrapper
                          $isOpen={isEditingAvatar || isEditingBanner}
                      >
                          <Overlays.BackgroundOverlay
                              onClick={onClose}
                              $isOpen={isEditingAvatar || isEditingBanner}
                          />
                          <Overlays.ModalInnerWrapper>
                              <ImageSelector
                                  imageType={
                                      isEditingAvatar ? 'avatar' : 'banner'
                                  }
                                  profile={profile}
                                  onClose={onClose}
                              />
                          </Overlays.ModalInnerWrapper>
                      </Overlays.ModalOuterWrapper>,
                      document.getElementById('app-wrapper') ?? document.body
                  )
                : null}
        </>
    );
}

export default Profile;
