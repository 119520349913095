import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile';
import { useNavigate, useParams } from 'react-router-dom';

import { useSupabase } from 'hooks/useSupabase';
import { useAnalytics } from 'hooks/useAnalytics';
import { useToast } from 'hooks/useToast';

import { UnAuthenticateRoute } from 'components/AppRoutes';
import BackgroundWrapper from 'components/BackgroundWrapper';
import TextInput from 'components/TextInput';
import LoadingButton from 'components/LoadingButton';

import GrandLogo from 'assets/grand-logo-green-vert.svg';
import CaretDown from 'assets/caret-down.svg';
import SignUpOneOnOneImage from 'assets/sign-up-one-on-one.png';
import SignUpDashboardImage from 'assets/sign-up-dashboard.png';

import { AuthForm, BottomLink, Logo } from 'styles/auth';
import { generateRandomString } from 'libs/utils';

import { colors, Fonts, isMobile, Spacing } from 'styles/theme';
import {
    MainWrapper,
    ScrollingContentSectionHeader,
    ScrollingContentImage,
    ScrollingContentSection,
    ScrollingContentWrapper,
    ScrollingContentMainHeader,
    ScrollCTA,
    ScrollCTAWrapper,
    AuthFormWrapper,
    ScrollCTACaret,
    ScrollingContentSectionBodyText,
} from 'styles/signUp';
import { CONSUMER_SPONSOR_IDS } from 'libs/constants';

function SignUp() {
    const navigate = useNavigate();
    const { tempGroupSponsorId, sponsorName } = useParams();

    const { isSignedIn, signUp } = useSupabase();
    const { sendEvent } = useAnalytics();
    const { showErrorToast } = useToast();

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSigningUp, setIsSigningUp] = useState<boolean>(false);

    const turnstileRef = useRef<TurnstileInstance>();

    const temporaryPassword = useMemo(generateRandomString, []);

    useEffect(() => {
        if (
            tempGroupSponsorId?.match(
                /^([0-9a-fA-F]{8})-(([0-9a-fA-F]{4}-){3})([0-9a-fA-F]{12})$/i
            ) == null
        ) {
            showErrorToast(
                `Your application URL is incorrectly formatted — please check your sponsor's link again`
            );
        }
    }, [showErrorToast, tempGroupSponsorId]);

    const onFirstNameInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setFirstName(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onLastNameInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setLastName(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onEmailInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setEmail(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSigningUp) {
                return;
            }
            setIsSigningUp(true);

            if (captchaToken != null && !isSignedIn) {
                const error = await signUp?.({
                    email,
                    password: temporaryPassword,
                    captchaToken,
                    fullName: `${firstName} ${lastName}`,
                    tempGroupSponsorId: tempGroupSponsorId ?? '',
                });

                if (error == null) {
                    sendEvent?.('submit_account_email');

                    navigate(
                        `/confirm?email=${encodeURIComponent(email)}&type=email`
                    );
                } else {
                    turnstileRef.current?.reset();
                    setIsError(true);
                    showErrorToast(error.message);
                }
            }

            setIsSigningUp(false);
        },
        [
            captchaToken,
            email,
            firstName,
            isSignedIn,
            isSigningUp,
            lastName,
            navigate,
            sendEvent,
            showErrorToast,
            signUp,
            tempGroupSponsorId,
            temporaryPassword,
        ]
    );

    const renderScrollingContent = useCallback(
        () => (
            <ScrollingContentWrapper>
                <ScrollingContentSection>
                    <ScrollingContentSectionHeader $color="orange">{`Welcome To`}</ScrollingContentSectionHeader>
                    <ScrollingContentMainHeader>{`The Grand World`}</ScrollingContentMainHeader>
                    {sponsorName == null ? null : (
                        <ScrollingContentSectionBodyText>
                            {CONSUMER_SPONSOR_IDS.includes(
                                tempGroupSponsorId ?? ''
                            )
                                ? `Apply to join The Grand and start your coaching journey`
                                : `Apply to join ${sponsorName?.replace(
                                      '-',
                                      ' '
                                  )} on your Grand Journey`}
                        </ScrollingContentSectionBodyText>
                    )}
                    <Spacing.Bumper />
                    {isMobile ? null : (
                        <>
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <ScrollCTA>
                                <Fonts.BodyText>{`Scroll down to learn more`}</Fonts.BodyText>
                                <ScrollCTACaret src={CaretDown} />
                            </ScrollCTA>
                        </>
                    )}
                </ScrollingContentSection>
                <ScrollingContentSection>
                    <ScrollingContentImage src={SignUpOneOnOneImage} />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <ScrollingContentSectionHeader $color="orange">{`Guiding Journeys`}</ScrollingContentSectionHeader>
                    <Fonts.Heading4>{`At The Grand we believe coaching is getting someone from where they are to where they want to go.`}</Fonts.Heading4>
                </ScrollingContentSection>
                <ScrollingContentSection>
                    <ScrollingContentImage src={SignUpDashboardImage} />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <ScrollingContentSectionHeader $color="orange">{`Assess and Achieve Goals`}</ScrollingContentSectionHeader>
                    <Fonts.Heading4>{`Fill out the intake form and take The Grand Diagnostic to get matched with a coaching group that's right for you.`}</Fonts.Heading4>
                </ScrollingContentSection>
            </ScrollingContentWrapper>
        ),
        [sponsorName, tempGroupSponsorId]
    );

    return (
        <>
            <UnAuthenticateRoute />

            <BackgroundWrapper>
                <MainWrapper>
                    <AuthFormWrapper>
                        <Logo src={GrandLogo} alt="grand-logo" />
                        <AuthForm onSubmit={onSubmit}>
                            <TextInput
                                type="text"
                                id="firstName"
                                name="firstName"
                                placeholder="First name"
                                onInput={onFirstNameInput}
                                isError={isError}
                                required
                            />
                            <Spacing.Bumper />
                            <TextInput
                                type="text"
                                id="lastName"
                                name="lastName"
                                placeholder="Last name"
                                onInput={onLastNameInput}
                                isError={isError}
                                required
                            />
                            <Spacing.Bumper />
                            <TextInput
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Company email address"
                                onInput={onEmailInput}
                                isError={isError}
                                required
                            />
                            <Spacing.Bumper />
                            <Turnstile
                                ref={turnstileRef}
                                siteKey="0x4AAAAAAAHdBQdhmUCPmC2X"
                                onSuccess={(token) => {
                                    setCaptchaToken(token);
                                }}
                                options={{
                                    theme: 'light',
                                }}
                                // Need to use inline styling as this is an external component
                                style={{
                                    boxShadow: colors.boxShadow,
                                }}
                            />
                            <Spacing.Bumper />
                            <LoadingButton
                                type="submit"
                                value="Submit"
                                isLoading={isSigningUp}
                                $stretch
                            >
                                {`Next`}
                            </LoadingButton>
                            <BottomLink to="/sign-in">
                                <Fonts.SmallLink>{`Already have an account?`}</Fonts.SmallLink>
                            </BottomLink>
                        </AuthForm>
                    </AuthFormWrapper>
                    {isMobile ? renderScrollingContent() : null}
                </MainWrapper>
            </BackgroundWrapper>

            {isMobile ? null : renderScrollingContent()}

            {isMobile ? (
                <ScrollCTAWrapper>
                    <ScrollCTA>
                        <Fonts.SmallText>{`Scroll down to learn more`}</Fonts.SmallText>
                        <ScrollCTACaret src={CaretDown} />
                    </ScrollCTA>
                </ScrollCTAWrapper>
            ) : null}
        </>
    );
}

export default SignUp;
