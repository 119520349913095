import React, { useMemo } from 'react';

import { AnimationWrapper, GrandRing, RING_COUNT } from 'styles/grandimation';

function Grandimation() {
    const grandRings = useMemo(() => {
        const ringsToDisplay = [];
        for (let i = 1; i <= RING_COUNT; i++) {
            ringsToDisplay.push(<GrandRing key={i} />);
        }
        return ringsToDisplay;
    }, []);

    return <AnimationWrapper>{grandRings}</AnimationWrapper>;
}

export default Grandimation;
