import styled from 'styled-components';

import { Fonts, breakpoints, colors, isMobile } from 'styles/theme';

export const MainWrapper = styled.div`
    padding: 16px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const CategoryWrapper = styled.div`
    margin: 8px 8px;
    background-color: ${colors.white};
    border-radius: 4px;
    width: 160px;
    padding: 16px 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media only screen and (max-width: ${breakpoints.medium}) {
        width: 130px;
        padding: 14px 14px;
    }
`;

export const CategoryTextMobile = styled(Fonts.SmallText)`
    font-family: 'OpenSans-SemiBold';
`;

export const TotalText = styled(
    isMobile ? Fonts.SmallText : Fonts.BodyText
).attrs({ as: 'span' })`
    font-weight: bold;
`;

export const TotalNumber = styled(
    isMobile ? Fonts.SmallText : Fonts.BodyText
).attrs({ as: 'span' })`
    color: ${colors.primary2};
`;
