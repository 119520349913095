import styled from 'styled-components';

import { colors, breakpoints, Fonts, isMobile } from 'styles/theme';

export const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${colors.flatSkyTan};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    overflow: hidden;
`;

export const HillsLandscape = styled.img`
    position: absolute;
    top: 40px;
    width: 100%;
    min-width: 600px;
`;

export const ImaginedFutureWrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 1000px;
    padding: 76px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    overflow: scroll;
    pointer-events: all;

    @media only screen and (max-width: ${breakpoints.small}) {
        padding: 40px;
        padding-top: 76px;
    }
`;

export const Heading = styled(isMobile ? Fonts.Heading3 : Fonts.Heading2)`
    margin: 14px 0;
`;

export const BodyTextWrapper = styled.div`
    margin-top: 64px;
    border-radius: 10px;
    background-color: ${colors.light};
    padding: 40px;
    padding-right: 100px;

    @media only screen and (max-width: ${breakpoints.small}) {
        padding-right: 40px;
    }
`;

export const BodyText = styled(Fonts.BodyText)`
    margin-top: 2px;
`;
