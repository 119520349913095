import styled, { css } from 'styled-components';

import { breakpoints, colors } from 'styles/theme';

const FOOTER_HEIGHT = 164;

interface FooterWrapperProps {
    $isDark: boolean;
}

export const FooterWrapper = styled.div<FooterWrapperProps>`
    background-color: ${colors.flatSkyTan};
    height: ${FOOTER_HEIGHT}px;
    min-height: ${FOOTER_HEIGHT}px;
    width: 100%;
    padding: 0 64px;

    @media only screen and (max-width: ${breakpoints.small}) {
        padding: 0 24px;
        height: auto;
        min-height: auto;
    }

    ${(props) => {
        switch (props.$isDark) {
            case true: {
                return css`
                    background-color: ${colors.dark1};
                `;
            }
            case false:
            default: {
                return null;
            }
        }
    }};
`;

export const FooterSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48%;

    @media only screen and (max-width: ${breakpoints.small}) {
        height: auto;
        padding: 24px 0;
        flex-direction: column;
        justify-content: center;
    }
`;

export const FooterLogo = styled.img`
    padding: 8px 0 24px;
`;

export const FooterDivider = styled.div`
    background-color: ${colors.borderDarkInput};
    height: 1px;
    width: 100%;
`;

export const FooterLinksWrapper = styled.div`
    display: flex;

    @media only screen and (max-width: ${breakpoints.small}) {
        margin-top: 20px;
        flex-direction: column;
        justify-content: center;
    }
`;
