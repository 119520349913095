import React from 'react';

import { ASSESSMENT_CATEGORIES } from 'libs/constants';

import CareerWellbeing from 'assets/retro-career-wellbeing.png';
import EmotionalResilience from 'assets/retro-emotional-resilience.png';
import EnvironmentalWellbeing from 'assets/retro-environmental-wellbeing.png';
import FinancialWellbeing from 'assets/retro-financial-wellbeing.png';
import IntellectualCuriosity from 'assets/retro-intellectual-curiosity.png';
import PhysicalWellbeing from 'assets/retro-physical-wellbeing.png';
import SocialWellbeing from 'assets/retro-social-wellbeing.png';
import SpiritualAwareness from 'assets/retro-spiritual-awareness.png';

import { Fonts, isMobile } from 'styles/theme';
import {
    CategoryTextMobile,
    CategoryWrapper,
    MainWrapper,
    TotalNumber,
    TotalText,
} from 'styles/assessmentSubtotals';

// TODO: type the category parameter properly as keys of ASSESSMENT_CATGEGORIES
export const returnCategoryImageSrc = (category: string) => {
    switch (category) {
        case 'Career Wellbeing': {
            return CareerWellbeing;
        }
        case 'Emotional Resilience': {
            return EmotionalResilience;
        }
        case 'Environmental Wellbeing': {
            return EnvironmentalWellbeing;
        }
        case 'Financial Wellbeing': {
            return FinancialWellbeing;
        }
        case 'Intellectual Curiosity': {
            return IntellectualCuriosity;
        }
        case 'Physical Wellbeing': {
            return PhysicalWellbeing;
        }
        case 'Social Wellbeing': {
            return SocialWellbeing;
        }
        case 'Spiritual Awareness':
        default: {
            return SpiritualAwareness;
        }
    }
};

interface AssessmentSubtotalsProps {
    // TODO: properly type this
    subtotals: object | null;
}

function AssessmentSubtotals(props: AssessmentSubtotalsProps) {
    const { subtotals } = props;

    return (
        <MainWrapper>
            {Object.keys(ASSESSMENT_CATEGORIES).map((category) => (
                <CategoryWrapper key={category} style={{ border: '1px' }}>
                    <img
                        src={returnCategoryImageSrc(category)}
                        width={isMobile ? 32 : 40}
                    />
                    {isMobile ? (
                        <CategoryTextMobile>{category}</CategoryTextMobile>
                    ) : (
                        <Fonts.Heading7>{category}</Fonts.Heading7>
                    )}
                    <p>
                        <TotalText>{`Total: `}</TotalText>
                        <TotalNumber>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            {subtotals?.[category] ?? 0}
                        </TotalNumber>
                    </p>
                </CategoryWrapper>
            ))}
        </MainWrapper>
    );
}

export default AssessmentSubtotals;
