import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors } from 'styles/theme';

export const ProfileListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
`;

interface ProfileLinkProps {
    $isProfileAccessible: boolean;
}

export const ProfileLink = styled(Link)<ProfileLinkProps>`
    position: relative;
    margin: 16px;
    border: 1px solid ${colors.borderDark};
    border-radius: 8px;
    width: calc(50% - 32px);
    min-width: 240px;
    max-width: 360px;
    padding: 8px;
    background-color: ${colors.white};
    display: flex;
    align-items: center;

    ${(props) => {
        switch (props.$isProfileAccessible) {
            case false:
                return css`
                    background-color: ${colors.borderWhiteBold};
                    opacity: 0.6;
                    pointer-events: none;
                `;
            case true:
            default: {
                return;
            }
        }
    }}
`;

export const AvatarImageWrapper = styled.div`
    margin-right: 14px;
    border-radius: 64px;
    background-color: ${colors.white};
    height: 72px;
    width: 72px;
    padding: 4px;
`;

export const AvatarImage = styled.img`
    border-radius: 64px;
    width: 64px;
`;
