import styled, { keyframes } from 'styled-components';

import { colors } from 'styles/theme';

export const AlertWrapper = styled.div`
    position: relative;
    margin-top: -64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const PulseWrapper = styled.div`
    position: relative;
    margin-bottom: 24px;
    width: 80px;
    height: 80px;
`;

const PulseAnimation = keyframes`
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }`;

export const Pulse = styled.div`
    position: absolute;
    border: 4px solid ${colors.primary1};
    opacity: 1;
    border-radius: 50%;
    animation: ${PulseAnimation} 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
`;

export const PulseSecondary = styled(Pulse)`
    animation-delay: -0.75s;
`;
