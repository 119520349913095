import { PropsWithChildren, SelectHTMLAttributes } from 'react';
import { FormSelectProps } from 'styles/theme';

import React from 'react';

import CaretDown from 'assets/caret-down.svg';

import { Forms } from 'styles/theme';

type SelectInputProps = PropsWithChildren<
    SelectHTMLAttributes<HTMLSelectElement>
> &
    FormSelectProps;

function SelectInput(props: SelectInputProps) {
    const { children, value, $size } = props;

    return (
        <Forms.SelectWrapper $size={$size}>
            <Forms.Select
                {...props}
                $color={value != null && value !== '' ? 'orange' : 'grey'}
            >
                {children}
            </Forms.Select>
            <Forms.DropdownArrow src={CaretDown} />
        </Forms.SelectWrapper>
    );
}

export default SelectInput;
