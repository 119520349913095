import { Database } from 'libs/supabaseTypes';

import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useSupabase } from 'hooks/useSupabase';
import { useToast } from 'hooks/useToast';

import {
    COMPANIES_FOUNDED_OPTIONS,
    EMPOYEE_COUNT_OPTIONS,
    FUNDING_STAGE_OPTIONS,
    YEARS_EXPERIENCE_OPTIONS,
    COACHING_EXPERIENCE_OPTIONS,
    CONSUMER_SPONSOR_IDS,
} from 'libs/constants';

import TextInput from 'components/TextInput';
import RequiredAsterisk from 'components/RequiredAsterisk';
import LoadingButton from 'components/LoadingButton';
import SelectInput from 'components/SelectInput';

import { Fonts, Inputs, Forms, Spacing } from 'styles/theme';
import { TimeSlotWrapper } from 'styles/submitIntake';

type SponsorData = Database['public']['Tables']['sponsors']['Row'];

type YearsExperienceOptions = (typeof YEARS_EXPERIENCE_OPTIONS)[number];
type FundingStageOptions = (typeof FUNDING_STAGE_OPTIONS)[number];
type EmployeeCountOptions = (typeof EMPOYEE_COUNT_OPTIONS)[number];
type CompaniesFoundedOptions = (typeof COMPANIES_FOUNDED_OPTIONS)[number];

interface SubmitIntakeProps {
    onSubmit: () => Promise<void>;
}

function SubmitIntake(props: SubmitIntakeProps) {
    const { onSubmit } = props;

    const { supabase, userId, userProfile, userEmail } = useSupabase();
    const { showSuccessToast, showErrorToast } = useToast();

    const parsedRawData = useMemo(
        () => JSON.parse(JSON.stringify(userProfile?.raw_data ?? {})),
        [userProfile?.raw_data]
    );

    const [sponsor, setSponsor] = useState<SponsorData | null>(null);

    const [isSubmittingIntake, setIsSubmittingIntake] =
        useState<boolean>(false);

    // Form state for all applicant types
    const [linkedIn, setLinkedIn] = useState<string>('');
    const [country, setCountry] = useState<string>(
        parsedRawData?.country ?? ''
    );
    const [state, setState] = useState<string>(parsedRawData?.state ?? '');
    const [city, setCity] = useState<string>(parsedRawData?.city ?? '');
    const [title, setTitle] = useState<string>(parsedRawData?.headline ?? '');
    const [yearsExperience, setYearsExperience] =
        useState<YearsExperienceOptions | null>(null);
    const [applicationReason, setApplicationReason] = useState<string>('');
    const [imaginedFuture, setImaginedFuture] = useState<string>(
        userProfile?.imagined_future ?? ''
    );
    const [sponsorTimeSlots, setSponsorTimeSlots] = useState<object>({});
    const [availableTimeSlots, setAvailableTimeSlots] = useState<Array<string>>(
        []
    );
    const [otherSuggestedTime, setOtherSuggestedTime] = useState<string>('');
    const [other, setOther] = useState<string>('');

    // Founder and consumer-related form state
    const [company, setCompany] = useState<string>('');

    // Founder-related form state
    const [fundingStage, setFundingStage] =
        useState<FundingStageOptions | null>(null);
    const [employeeCount, setEmployeeCount] =
        useState<EmployeeCountOptions | null>(null);
    const [companiesFounded, setCompaniesFounded] =
        useState<CompaniesFoundedOptions | null>(null);

    // Consumer form state
    const [coachingExperiences, setCoachingExperiences] = useState<
        Array<string>
    >([]);

    const hasIntakeTimeslots = useMemo(
        () => Object.keys(sponsorTimeSlots).length > 0,
        [sponsorTimeSlots]
    );

    useEffect(() => {
        const getSponsor = async () => {
            const { data, error } = await supabase.from('sponsors').select('*');

            if (error == null) {
                // TODO: convert this to use the full sponsor data state
                setSponsorTimeSlots(
                    JSON.parse(JSON.stringify(data?.[0]?.time_slots ?? {}))
                );
                setSponsor(data?.[0] ?? '');
            } else {
                showErrorToast(error.message);
            }
        };

        if (userId !== '') {
            getSponsor();
        }
    }, [showErrorToast, supabase, userId]);

    const onLinkedInInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setLinkedIn(target.value);
        },
        []
    );

    const onCountryInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setCountry(target.value);
        },
        []
    );

    const onStateInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setState(target.value);
        },
        []
    );

    const onCityInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setCity(target.value);
        },
        []
    );

    const onTitleInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setTitle(target.value);
        },
        []
    );

    const onCompanyInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setCompany(target.value);
        },
        []
    );

    const onChangeYearsExperience = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setYearsExperience(target.value as YearsExperienceOptions);
        },
        []
    );

    const onChangeFundingRound = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setFundingStage(target.value as FundingStageOptions);
        },
        []
    );

    const onChangeEmployeeCount = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setEmployeeCount(target.value as EmployeeCountOptions);
        },
        []
    );

    const onChangeCompaniesFounded = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setCompaniesFounded(target.value as CompaniesFoundedOptions);
        },
        []
    );

    const onSelectCoachingExperiences = useCallback(
        (selectedExperience: string) => () => {
            if (coachingExperiences.includes(selectedExperience)) {
                setCoachingExperiences(
                    coachingExperiences.filter(
                        (value) => value !== selectedExperience
                    )
                );
            } else {
                setCoachingExperiences([
                    ...coachingExperiences,
                    selectedExperience,
                ]);
            }
        },
        [coachingExperiences]
    );

    const onApplicationReasonInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setApplicationReason(target.value);
        },
        []
    );

    const onImaginedFutureInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setImaginedFuture(target.value);
        },
        []
    );

    const onSelectAvailableTimeSlot = useCallback(
        (fullTimeSlotString: string) => () => {
            const newAvailableTimeSlots = [...availableTimeSlots];
            const availableTimeSlotIndex = availableTimeSlots.findIndex(
                (availableTimeSlot) => availableTimeSlot === fullTimeSlotString
            );
            if (availableTimeSlotIndex === -1) {
                newAvailableTimeSlots.push(fullTimeSlotString);
            } else {
                newAvailableTimeSlots.splice(availableTimeSlotIndex, 1);
            }
            setAvailableTimeSlots(newAvailableTimeSlots);
        },
        [availableTimeSlots]
    );

    const onOtherSuggestedTimeInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setOtherSuggestedTime(target.value);
        },
        []
    );

    const onOtherInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setOther(target.value);
        },
        []
    );

    const internalOnSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSubmittingIntake) {
                return;
            }
            setIsSubmittingIntake(true);

            if (
                coachingExperiences.length === 0 &&
                CONSUMER_SPONSOR_IDS.includes(sponsor?.id ?? '')
            ) {
                showErrorToast(
                    'Please select at least one coaching experience'
                );
                setIsSubmittingIntake(false);
                return;
            }

            if (
                hasIntakeTimeslots &&
                Object.keys(availableTimeSlots).length === 0 &&
                otherSuggestedTime === ''
            ) {
                showErrorToast(
                    'Please select a time slot for your ongoing sessions'
                );
                setIsSubmittingIntake(false);
                return;
            }

            const { error: profileError } = await supabase
                .from('profiles')
                .update({
                    linkedin: linkedIn,
                    imagined_future: imaginedFuture,
                    raw_data: {
                        ...(parsedRawData ?? {}),
                        headline: title,
                        country,
                        state,
                        city,
                    },
                })
                .eq('id', userId);

            if (profileError != null) {
                showErrorToast(profileError.message);
            }

            const availableTimeSlotsToUpdate = [...availableTimeSlots];
            if (otherSuggestedTime !== '') {
                availableTimeSlotsToUpdate.push(otherSuggestedTime);
            }

            const { error: intakeError } = await supabase
                .from('intakes')
                .insert({
                    created_by: userId,
                    email: userEmail,
                    years_experience: yearsExperience,
                    application_reason: applicationReason,
                    imagined_future: imaginedFuture,
                    available_time_slots: hasIntakeTimeslots
                        ? availableTimeSlotsToUpdate
                        : null,
                    other,
                    // Founder and consumer-specific fields
                    company: company === '' ? null : company,
                    // Founder-specific fields
                    funding_stage: fundingStage,
                    employee_count: employeeCount,
                    companies_founded: companiesFounded,
                    // Consumer fields
                    coaching_experiences: coachingExperiences,
                });

            if (intakeError == null) {
                showSuccessToast('Successfully submitted intake responses!');
                await onSubmit();
            } else {
                showErrorToast(intakeError.message);
            }

            setIsSubmittingIntake(false);
        },
        [
            isSubmittingIntake,
            coachingExperiences,
            sponsor?.id,
            hasIntakeTimeslots,
            availableTimeSlots,
            otherSuggestedTime,
            supabase,
            linkedIn,
            imaginedFuture,
            parsedRawData,
            title,
            country,
            state,
            city,
            userId,
            userEmail,
            yearsExperience,
            applicationReason,
            other,
            company,
            fundingStage,
            employeeCount,
            companiesFounded,
            showErrorToast,
            showSuccessToast,
            onSubmit,
        ]
    );

    return (
        <>
            <Forms.FullPageOuterWrapper>
                <Forms.Heading>{`Welcome to The Grand World!`}</Forms.Heading>
                <Spacing.Bumper />
                <Fonts.BodyText>
                    {`We're delighted to curate your Grand Council group${
                        CONSUMER_SPONSOR_IDS.includes(sponsor?.id ?? '')
                            ? ''
                            : ` on behalf of ${sponsor?.sponsor_name}`
                    }! This experience will strengthen your clarity and confidence as a leader, all with the support of a great community.`}
                </Fonts.BodyText>
                <Spacing.Bumper />
                <Spacing.Bumper />
                <Forms.Wrapper onSubmit={internalOnSubmit}>
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>{`LinkedIn`}</Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <TextInput
                        type="text"
                        id="linkedin"
                        name="linkedin"
                        value={linkedIn}
                        onInput={onLinkedInInput}
                        isError={false}
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Country`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <TextInput
                        type="text"
                        id="country"
                        name="country"
                        placeholder="eg. México"
                        value={country}
                        onInput={onCountryInput}
                        isError={false}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`State`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <TextInput
                        type="text"
                        id="state"
                        name="state"
                        placeholder="eg. Jalisco"
                        value={state}
                        onInput={onStateInput}
                        isError={false}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`City`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <TextInput
                        type="text"
                        id="city"
                        name="city"
                        placeholder="eg. Guadalajara"
                        value={city}
                        onInput={onCityInput}
                        isError={false}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    {sponsor?.type === 'founder' ||
                    CONSUMER_SPONSOR_IDS.includes(sponsor?.id ?? '') ? (
                        <>
                            <Forms.ExplainerTextWrapper>
                                <Fonts.InputLabel>
                                    {`Company`}
                                    <RequiredAsterisk />
                                </Fonts.InputLabel>
                            </Forms.ExplainerTextWrapper>
                            <TextInput
                                type="text"
                                id="company"
                                name="company"
                                value={company}
                                onInput={onCompanyInput}
                                isError={false}
                                required
                            />
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                        </>
                    ) : null}
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Title`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <TextInput
                        type="text"
                        id="title"
                        name="title"
                        value={title}
                        onInput={onTitleInput}
                        isError={false}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`How many years of professional experience do you have?`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <SelectInput
                        onInput={onChangeYearsExperience}
                        value={yearsExperience ?? ''}
                        required
                    >
                        <option value="" label="Select" hidden />
                        {YEARS_EXPERIENCE_OPTIONS.map(
                            (yearsExperinceOption) => (
                                <option
                                    key={yearsExperinceOption}
                                    value={yearsExperinceOption}
                                >
                                    {yearsExperinceOption}
                                </option>
                            )
                        )}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    {sponsor?.type === 'founder' ? (
                        <>
                            <Forms.ExplainerTextWrapper>
                                <Fonts.InputLabel>
                                    {`What stage of funding is your company at?`}
                                    <RequiredAsterisk />
                                </Fonts.InputLabel>
                            </Forms.ExplainerTextWrapper>
                            <SelectInput
                                onInput={onChangeFundingRound}
                                value={fundingStage ?? ''}
                                required
                            >
                                <option value="" label="Select" hidden />
                                {FUNDING_STAGE_OPTIONS.map(
                                    (fundingStageOption) => (
                                        <option
                                            key={fundingStageOption}
                                            value={fundingStageOption}
                                        >
                                            {fundingStageOption}
                                        </option>
                                    )
                                )}
                            </SelectInput>
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <Forms.ExplainerTextWrapper>
                                <Fonts.InputLabel>
                                    {`How many employees are there at your company?`}
                                    <RequiredAsterisk />
                                </Fonts.InputLabel>
                            </Forms.ExplainerTextWrapper>
                            <SelectInput
                                onInput={onChangeEmployeeCount}
                                value={employeeCount ?? ''}
                                required
                            >
                                <option value="" label="Select" hidden />
                                {EMPOYEE_COUNT_OPTIONS.map(
                                    (employeeCountOption) => (
                                        <option
                                            key={employeeCountOption}
                                            value={employeeCountOption}
                                        >
                                            {employeeCountOption}
                                        </option>
                                    )
                                )}
                            </SelectInput>
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <Forms.ExplainerTextWrapper>
                                <Fonts.InputLabel>
                                    {`How many revenue-generating companies have you founded prior to your current one?`}
                                    <RequiredAsterisk />
                                </Fonts.InputLabel>
                            </Forms.ExplainerTextWrapper>
                            <SelectInput
                                onInput={onChangeCompaniesFounded}
                                value={companiesFounded ?? ''}
                                required
                            >
                                <option value="" label="Select" hidden />
                                {COMPANIES_FOUNDED_OPTIONS.map(
                                    (companiesFoundedOption) => (
                                        <option
                                            key={companiesFoundedOption}
                                            value={companiesFoundedOption}
                                        >
                                            {companiesFoundedOption}
                                        </option>
                                    )
                                )}
                            </SelectInput>
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                        </>
                    ) : null}
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Tell us more about why you’d like to participate in The Grand’s group coaching.`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <Forms.ExplainerTextWrapper>
                        <Fonts.BodyText>{`Write 2-3 sentences describing what you’re hoping to gain from this experience and why you’d be a good fit.`}</Fonts.BodyText>
                    </Forms.ExplainerTextWrapper>
                    <Inputs.TextArea
                        id="application reason"
                        name="application reason"
                        value={applicationReason}
                        onInput={onApplicationReasonInput}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Imagine it is 6 months from now. Where do you hope to be?`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <Inputs.TextArea
                        id="imagined future"
                        name="imagined future"
                        value={imaginedFuture}
                        onInput={onImaginedFutureInput}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />

                    {hasIntakeTimeslots ? (
                        <>
                            <Fonts.InputLabel>
                                {`Please select all of the time slots that work for you.`}
                                <RequiredAsterisk />
                            </Fonts.InputLabel>
                            <Spacing.Bumper />
                            <TimeSlotWrapper>
                                {Object.keys(sponsorTimeSlots).map(
                                    (timeFrameKey) => (
                                        <Fragment key={timeFrameKey}>
                                            <Forms.ExplainerTextWrapper>
                                                <Fonts.BodyQuote>
                                                    {timeFrameKey}
                                                </Fonts.BodyQuote>
                                            </Forms.ExplainerTextWrapper>
                                            {/* TODO: add JSON typing once added to time_slots column in DB */}
                                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                            {/* @ts-ignore */}
                                            {sponsorTimeSlots[timeFrameKey].map(
                                                (timeSlot: string) => {
                                                    const fullTimeSlotString = `${timeFrameKey}, ${timeSlot}`;
                                                    return (
                                                        <Inputs.CheckRadioInputWrapper
                                                            key={timeSlot}
                                                        >
                                                            <Inputs.Checkbox
                                                                onChange={onSelectAvailableTimeSlot(
                                                                    fullTimeSlotString
                                                                )}
                                                                value={timeSlot}
                                                                type="checkbox"
                                                                checked={availableTimeSlots.includes(
                                                                    fullTimeSlotString
                                                                )}
                                                            />
                                                            <Fonts.BodyText>
                                                                {timeSlot}
                                                            </Fonts.BodyText>
                                                        </Inputs.CheckRadioInputWrapper>
                                                    );
                                                }
                                            )}
                                            <Spacing.Bumper />
                                            <Spacing.Bumper />
                                        </Fragment>
                                    )
                                )}
                                <Forms.ExplainerTextWrapper>
                                    <Fonts.BodyQuote>
                                        {`Is there another day and time slot available?`}
                                    </Fonts.BodyQuote>
                                </Forms.ExplainerTextWrapper>
                                <TextInput
                                    type="text"
                                    id="otherSuggestedTime"
                                    name="otherSuggestedTime"
                                    value={otherSuggestedTime}
                                    onInput={onOtherSuggestedTimeInput}
                                    isError={false}
                                />
                            </TimeSlotWrapper>
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                        </>
                    ) : null}

                    {CONSUMER_SPONSOR_IDS.includes(sponsor?.id ?? '') ? (
                        <>
                            <Forms.ExplainerTextWrapper>
                                <Fonts.InputLabel>
                                    {`Which Grand Coaching experiences are you interested in?`}
                                    <RequiredAsterisk />
                                </Fonts.InputLabel>
                            </Forms.ExplainerTextWrapper>
                            {COACHING_EXPERIENCE_OPTIONS.map(
                                (experienceKey) => (
                                    <Forms.CheckboxWrapper key={experienceKey}>
                                        <Inputs.Checkbox
                                            checked={coachingExperiences.includes(
                                                experienceKey
                                            )}
                                            onChange={onSelectCoachingExperiences(
                                                experienceKey
                                            )}
                                            type="checkbox"
                                        />
                                        <Fonts.BodyText>
                                            {experienceKey}
                                        </Fonts.BodyText>
                                    </Forms.CheckboxWrapper>
                                )
                            )}
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                        </>
                    ) : null}

                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Is there anything else you'd like for your coach to consider before your group starts?`}
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <Inputs.TextArea
                        id="otherFeedback"
                        name="otherFeedback"
                        value={other}
                        onInput={onOtherInput}
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <LoadingButton
                        type="submit"
                        value="Submit"
                        isLoading={isSubmittingIntake}
                    >
                        {`Submit`}
                    </LoadingButton>
                </Forms.Wrapper>
            </Forms.FullPageOuterWrapper>
        </>
    );
}

export default SubmitIntake;
