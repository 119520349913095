import { ReportData } from 'components/GroupReports';

import React, { useCallback, useState } from 'react';

import { useAnalytics } from 'hooks/useAnalytics';

import CaretDown from 'assets/caret-down.svg';

import { Fonts, Spacing, isMobile } from 'styles/theme';
// -------------------------------------------------------------------------------------------
// TODO: move imports styled component into theme, rather than reference unrelated style files
import { DropdownCaret } from 'styles/sessionOverview';
import { LargeNumberText } from 'styles/groupReports';
// -------------------------------------------------------------------------------------------
import {
    Heading,
    ItalicText,
    MainWrapper,
    ReportTextResponsesWrapper,
    StatListWrapper,
    StatWrapper,
} from 'styles/reportDropdown';

interface ReportDropdown {
    report: ReportData;
}

function ReportDropdown(props: ReportDropdown) {
    const { report } = props;

    const { sendEvent } = useAnalytics();

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const reportDate = new Date(report.start_date);

    const onToggleExpandDropdown = useCallback(() => {
        if (isExpanded) {
            setIsExpanded(false);
        } else {
            setIsExpanded(true);
            sendEvent?.('monthly_report_opened');
        }
    }, [isExpanded, sendEvent]);

    return (
        <MainWrapper>
            <Heading onClick={onToggleExpandDropdown}>
                {isMobile ? (
                    <Fonts.Heading3 $color="orange">
                        {reportDate.toLocaleString('default', {
                            timeZone: 'UTC',
                            month: 'long',
                            year: 'numeric',
                        })}
                    </Fonts.Heading3>
                ) : (
                    <Fonts.Heading2 $color="orange">
                        {reportDate.toLocaleString('default', {
                            timeZone: 'UTC',
                            month: 'long',
                            year: 'numeric',
                        })}
                    </Fonts.Heading2>
                )}
                <DropdownCaret
                    src={CaretDown}
                    width={16}
                    $isDropdownOpen={isExpanded}
                />
            </Heading>

            {isExpanded ? (
                <>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <StatListWrapper>
                        <StatWrapper>
                            <Fonts.BodyTextBold $color="orange">{`Session score average:`}</Fonts.BodyTextBold>
                            <LargeNumberText>
                                {report.current_session_score_avg?.toFixed(1)}
                            </LargeNumberText>
                            <Fonts.FinePrintText>
                                {`When asked on a on a scale from Strongly Disagree ("1") to Strongly Agree ("5"), whether this session was a great use of time`}
                            </Fonts.FinePrintText>
                        </StatWrapper>
                        <StatWrapper>
                            <Fonts.BodyTextBold $color="orange">{`Attendance average:`}</Fonts.BodyTextBold>
                            <LargeNumberText>
                                {`${(
                                    (report.attendance_avg ?? 0) * 100
                                ).toFixed(0)}%`}
                            </LargeNumberText>
                            <Fonts.FinePrintText>
                                {`Attendance per session ranges from 50% in the bottom 25th percentile to 85% in the 75th percentile. Fluctuations in attendance are expected and monitored daily by our team. We will proactively reach out to you if we need support in increasing attendance for your groups.`}
                            </Fonts.FinePrintText>
                        </StatWrapper>
                        <StatWrapper>
                            <Fonts.BodyTextBold $color="orange">{`Members engaged:`}</Fonts.BodyTextBold>
                            <LargeNumberText>
                                {report.present_members}
                            </LargeNumberText>
                            <Fonts.FinePrintText>
                                {`This number represents the total members who attended a session in this month`}
                            </Fonts.FinePrintText>
                        </StatWrapper>
                    </StatListWrapper>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />

                    <ReportTextResponsesWrapper>
                        <Fonts.BodyTextBold $color="orange">{`Key Takeaways:`}</Fonts.BodyTextBold>
                        {report.takeaways?.map((takeaway) => (
                            <>
                                <Spacing.Bumper />
                                <ItalicText key={takeaway}>
                                    {takeaway}
                                </ItalicText>
                            </>
                        ))}
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Fonts.BodyTextBold $color="orange">{`Closing words:`}</Fonts.BodyTextBold>
                        <Fonts.FinePrintText>{`The sentiment that members shared at the end of the session:
`}</Fonts.FinePrintText>
                        <Spacing.Bumper />
                        <Fonts.BodyText>{report.closing_words}</Fonts.BodyText>
                        <Spacing.Bumper />
                    </ReportTextResponsesWrapper>
                </>
            ) : null}
        </MainWrapper>
    );
}

export default ReportDropdown;
