import { RetroData } from 'components/Retrospectives';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSupabase } from 'hooks/useSupabase';
import { useAnalytics } from 'hooks/useAnalytics';
import { useToast } from 'hooks/useToast';

import { getCurrentAndNextPeriod } from 'libs/utils';

import AssessmentResultDetails from 'components/AssessmentResultDetails';
import LoadingButton from 'components/LoadingButton';

import X from 'assets/x-black.svg';

import {
    Fonts,
    Inputs,
    Overlays,
    Forms,
    isMobile,
    Spacing,
} from 'styles/theme';
import { Form } from 'styles/reflection';
import RequiredAsterisk from './RequiredAsterisk';

interface ReflectionProps {
    selectedRetro: RetroData | null;
    onClose: () => void;
}

function Reflection(props: ReflectionProps) {
    const { selectedRetro, onClose } = props;

    const { supabase } = useSupabase();
    const { sendEvent } = useAnalytics();
    const { showSuccessToast, showErrorToast } = useToast();

    const [reactions, setReactions] = useState<string>(
        selectedRetro?.reactions ?? ''
    );
    const [reflections, setReflections] = useState<string>(
        selectedRetro?.reflections ?? ''
    );
    const [isSubmittingReflection, setIsSubmittingReflection] =
        useState<boolean>(false);

    const { currentPeriod } = useMemo(() => getCurrentAndNextPeriod(), []);

    const isReflectionCurrentPeriod = selectedRetro?.period === currentPeriod;

    const parsedSubtotals = useMemo(
        () => JSON.parse(JSON.stringify(selectedRetro?.subtotals ?? '')),
        [selectedRetro?.subtotals]
    );

    useEffect(() => {
        setReactions(selectedRetro?.reactions ?? '');
        setReflections(selectedRetro?.reflections ?? '');
    }, [selectedRetro?.reactions, selectedRetro?.reflections]);

    const onReactionsInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setReactions(target.value);
        },
        []
    );

    const onReflectionsInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setReflections(target.value);
        },
        []
    );

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSubmittingReflection) {
                return;
            }
            setIsSubmittingReflection(true);

            const { error } = await supabase
                .from('retros')
                .update({
                    reactions,
                    reflections,
                })
                .eq('id', selectedRetro?.id ?? '');

            if (error == null) {
                onClose();
                showSuccessToast('Your retrospective has been updated!');

                sendEvent?.('retro_reflection_submitted', {
                    retro_period: selectedRetro?.period ?? '',
                });
            } else {
                showErrorToast(error.message);
            }

            setIsSubmittingReflection(false);
        },
        [
            isSubmittingReflection,
            onClose,
            reactions,
            reflections,
            selectedRetro?.id,
            selectedRetro?.period,
            sendEvent,
            showErrorToast,
            showSuccessToast,
            supabase,
        ]
    );

    return (
        <>
            <Forms.CloseButtonWrapper onClick={onClose}>
                <img src={X} />
            </Forms.CloseButtonWrapper>
            <Overlays.ModalInnerScroller>
                <Fonts.LargeText>{`The Grand Retro`}</Fonts.LargeText>
                <Forms.Heading>
                    {`${selectedRetro?.period ?? ''} Assessment`}
                </Forms.Heading>
                <Spacing.Bumper />

                <Spacing.Divider />
                <Spacing.Bumper />
                <Fonts.Heading7>{`Here are the totals for each of your dimensions.`}</Fonts.Heading7>
                <Spacing.Bumper />
                <Form onSubmit={onSubmit}>
                    <AssessmentResultDetails
                        selectedRetro={selectedRetro}
                        subtotals={parsedSubtotals}
                    />
                    <Spacing.Bumper />
                    {isMobile ? null : (
                        <>
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                        </>
                    )}
                    <Spacing.Divider />
                    <Spacing.Bumper />

                    <Fonts.BodyText>
                        {`Reflecting on these results will support you in setting goals for this quarter. We’ll save these responses for you to revisit in your sessions. Consider the following reflection questions:`}
                    </Fonts.BodyText>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Fonts.InputLabel>
                        {`1. What are your reactions to your scores? Do you feel balanced? Do you want to be?`}
                        <RequiredAsterisk />
                    </Fonts.InputLabel>
                    <Spacing.Bumper />
                    <Inputs.TextArea
                        id="reactions"
                        name="reactions"
                        required
                        value={reactions}
                        onInput={onReactionsInput}
                        disabled={!isReflectionCurrentPeriod}
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Fonts.InputLabel>
                        {`2. As you reflect on the results of this Assessment, how does it it impact your feelings about your long-term goals?`}
                        <RequiredAsterisk />
                    </Fonts.InputLabel>
                    <Spacing.Bumper />
                    <Inputs.TextArea
                        id="reflections"
                        name="reflections"
                        required
                        value={reflections}
                        onInput={onReflectionsInput}
                        disabled={!isReflectionCurrentPeriod}
                    />

                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />

                    {isReflectionCurrentPeriod ? (
                        <LoadingButton
                            type="submit"
                            value="Submit"
                            isLoading={isSubmittingReflection}
                            $stretch
                        >
                            {`Continue`}
                        </LoadingButton>
                    ) : null}
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                </Form>
            </Overlays.ModalInnerScroller>
        </>
    );
}

export default Reflection;
