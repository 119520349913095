import React, { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { BrowserDataProvider } from 'hooks/useBrowserData';
import { SupabaseProvider } from 'hooks/useSupabase';
import { AnalyticsProvider } from 'hooks/useAnalytics';
import { ImaginedFutureProvider } from 'hooks/useImaginedFuture';
import { NavMenuProvider } from 'hooks/useNavMenu';
import { ToastProvider } from 'hooks/useToast';

import reportWebVitals from './reportWebVitals';

import AppRoutes from 'components/AppRoutes';

// These imports are required in the index files of React apps
import 'fonts/Gelica/Gelica-Light.otf';
import 'fonts/Gelica/Gelica-LightItalic.otf';
import 'fonts/OpenSans/OpenSans-Regular.ttf';
import 'fonts/OpenSans/OpenSans-SemiBold.ttf';
import 'fonts/OpticianSans/OpticianSans.otf';

// This index file wipes all browser-based automatic styling and sets our font faces
import 'index.css';

const { REACT_APP_NODE_ENV } = process.env;

if (REACT_APP_NODE_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://96f976a6b7894cd596080ffba8d387c8@o4505278455087104.ingest.sentry.io/4505846423224320',
        integrations: [
            new Sentry.BrowserTracing({
                // See docs for support of different versions of variation of react router
                // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }),
            new Sentry.Replay(),
        ],

        // Pass node environment to Sentry to avoid confusion with error monitoring
        environment: REACT_APP_NODE_ENV,

        // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            // PROD
            /^https:\/\/uxqjiggdcpcvekpqlelu\.supabase\.co/,
            // STAGING
            /^https:\/\/wtlrmcmquaiqnattshng\.supabase\.co/,
        ],

        // Capture Replay for 0% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
    });
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <StrictMode>
        <BrowserDataProvider>
            <ToastProvider>
                <BrowserRouter>
                    <SupabaseProvider>
                        <AnalyticsProvider>
                            <ImaginedFutureProvider>
                                <NavMenuProvider>
                                    <AppRoutes />
                                </NavMenuProvider>
                            </ImaginedFutureProvider>
                        </AnalyticsProvider>
                    </SupabaseProvider>
                </BrowserRouter>
            </ToastProvider>
        </BrowserDataProvider>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
