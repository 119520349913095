import { PropsWithChildren, useEffect } from 'react';

import React, { useRef } from 'react';

import CloudsBackgroundImage from 'assets/background-sparse-clouds.png';
import GreenMountainsBackgroundImage from 'assets/background-green-mountains.png';

import {
    MainWrapper,
    CloudsBackground,
    GreenMountainsLandscape,
    ContentOuterWrapper,
} from 'styles/backgroundWrapper';
import { Overlays, Spacing } from 'styles/theme';
import Footer from './Footer';

interface BackgroundWrapperProps extends PropsWithChildren {
    shouldIncludeFooter?: boolean;
    shouldIncludeBumper?: boolean;
    currentView?: string;
}

function BackgroundWrapper(props: BackgroundWrapperProps) {
    const { children, currentView, shouldIncludeFooter, shouldIncludeBumper } =
        props;

    const scrollRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    useEffect(() => {
        scrollRef.current?.scrollTo({ top: 0 });

        // Scroll to top of wrapper each time view changes
        // eslint-disable-next-line react-hooks-addons/no-unused-deps
    }, [currentView]);

    return (
        <MainWrapper ref={scrollRef}>
            <ContentOuterWrapper
                $shouldIncludeFooter={shouldIncludeFooter ?? false}
            >
                <Overlays.TextureOverlay />
                <CloudsBackground src={CloudsBackgroundImage} />
                <GreenMountainsLandscape src={GreenMountainsBackgroundImage} />
                {children}
            </ContentOuterWrapper>

            {shouldIncludeFooter ?? false ? <Footer /> : null}

            {shouldIncludeBumper ?? false ? (
                <>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                </>
            ) : null}
        </MainWrapper>
    );
}

export default BackgroundWrapper;
