import { ExerciseFrameworkData } from 'queries/groups';
import { Learnings } from 'components/Sessions';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { createPortal } from 'react-dom';

import { useSupabase } from 'hooks/useSupabase';
import { useAnalytics } from 'hooks/useAnalytics';

import { SESSION_OVERVIEW_TABS } from 'libs/constants';

import MarkdownWrapper from 'components/MarkdownWrapper';
import FeedbackTool from 'components/FeedbackTool';

import X from 'assets/x-black.svg';
import CaretDown from 'assets/caret-down.svg';

import { Forms, Fonts, Overlays, Spacing } from 'styles/theme';
import {
    AlternateDropdownOption,
    BulletList,
    BulletListItem,
    BulletText,
    Dropdown,
    DropdownCaret,
    DropdownOption,
    SessionOverviewWrapper,
    YourLearningBoldText,
} from 'styles/sessionOverview';

type SessionOverviewTab = (typeof SESSION_OVERVIEW_TABS)[number];

interface SessionOverviewProps {
    sessionNumber: number;
    sessionDate: string;
    sessionObjectiveTitle: string;
    sessionDescriptions: Array<string>;
    sessionGroupLearnings: Learnings;
    sessionKudosCounts: Map<string, number>;
    reviewContent: Array<string>;
    applyContent: Array<string>;
    reflectContent: Array<string>;
    exercisesFrameworks: Array<ExerciseFrameworkData>;
    hasTopicTool: boolean;
    onClose: () => void;
    isOnlyLearnings: boolean;
}

function SessionOverview(props: SessionOverviewProps) {
    const {
        sessionNumber,
        sessionDate,
        sessionObjectiveTitle,
        sessionDescriptions,
        sessionGroupLearnings,
        sessionKudosCounts,
        reviewContent,
        applyContent,
        reflectContent,
        exercisesFrameworks,
        hasTopicTool,
        onClose,
        isOnlyLearnings,
    } = props;

    const { sendEvent } = useAnalytics();
    const { userProfile } = useSupabase();

    const [selectedTab, setSelectedTab] =
        useState<SessionOverviewTab>('objectives');
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [isFeedbackToolOpen, setIsFeedbackToolOpen] =
        useState<boolean>(false);

    const sessionDateObject = useMemo(
        () => new Date(sessionDate),
        [sessionDate]
    );

    useEffect(() => {
        if (isOnlyLearnings || sessionGroupLearnings.length > 0) {
            setSelectedTab('learnings');
        } else {
            setSelectedTab('objectives');
        }
    }, [isOnlyLearnings, sessionGroupLearnings.length]);

    const onSelectTab = useCallback(
        (tab: SessionOverviewTab) => () => {
            setSelectedTab(tab);

            if (tab === 'learnings') {
                sendEvent?.('session_learnings_opened', {
                    session_number: sessionNumber,
                    session_objective_title: sessionObjectiveTitle,
                });
            }
        },
        [sendEvent, sessionNumber, sessionObjectiveTitle]
    );

    const internalOnClose = useCallback(() => {
        setIsDropdownOpen(false);
        onClose();

        sendEvent?.('session_overview_closed', {
            session_number: sessionNumber,
            session_objective_title: sessionObjectiveTitle,
        });
    }, [onClose, sendEvent, sessionNumber, sessionObjectiveTitle]);

    const onOpenFeedbackTool = useCallback(() => {
        setIsFeedbackToolOpen(true);
        onClose();
    }, [onClose]);

    const onCloseFeedbackTool = useCallback(() => {
        setIsFeedbackToolOpen(false);
    }, []);

    const kudosCountsMap = useMemo(() => {
        const kudosCountsToRender: Array<React.ReactNode> = [];
        sessionKudosCounts.forEach((kudosCount, kudosText) => {
            kudosCountsToRender.push(
                <>
                    <Fonts.BodyTextBold as="span">
                        {kudosText}
                    </Fonts.BodyTextBold>
                    {` (x${kudosCount.toString()})`}
                    {kudosCountsToRender.length < sessionKudosCounts.size - 1
                        ? ', '
                        : ''}
                </>
            );
        });
        return kudosCountsToRender;
    }, [sessionKudosCounts]);

    const tabsToShow = useMemo(
        () =>
            SESSION_OVERVIEW_TABS.filter((tab) => {
                if (tab === 'review' && reviewContent.length === 0) {
                    return false;
                } else if (tab === 'apply' && applyContent.length === 0) {
                    return false;
                } else if (tab === 'reflect' && reflectContent.length === 0) {
                    return false;
                }
                return true;
            }),
        [applyContent, reflectContent, reviewContent]
    );

    const markdownStrings = useMemo(() => {
        switch (selectedTab) {
            case 'objectives': {
                return sessionDescriptions;
            }
            case 'review': {
                return reviewContent;
            }
            case 'apply': {
                return applyContent;
            }
            case 'reflect':
            default: {
                return reflectContent;
            }
        }
    }, [
        applyContent,
        reflectContent,
        reviewContent,
        selectedTab,
        sessionDescriptions,
    ]);

    return (
        <>
            <Forms.CloseButtonWrapper onClick={internalOnClose}>
                <img src={X} />
            </Forms.CloseButtonWrapper>
            <Spacing.Bumper />
            {isOnlyLearnings ? null : (
                <Dropdown
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    $isDropdownOpen={isDropdownOpen}
                >
                    <DropdownOption $isDropdownOpen={isDropdownOpen}>
                        <Fonts.SmallCapsText $color="orange">
                            {selectedTab}
                        </Fonts.SmallCapsText>
                        <DropdownCaret
                            src={CaretDown}
                            $isDropdownOpen={isDropdownOpen}
                        />
                    </DropdownOption>
                    {tabsToShow.map((tab) => (
                        <AlternateDropdownOption
                            key={tab}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            onClick={onSelectTab(tab)}
                            $isDropdownOpen={isDropdownOpen}
                        >
                            <Fonts.SmallCapsText
                                $color={
                                    tab === selectedTab ? 'orange' : 'primary'
                                }
                            >
                                {tab}
                            </Fonts.SmallCapsText>
                        </AlternateDropdownOption>
                    ))}
                </Dropdown>
            )}

            <SessionOverviewWrapper $removeMargin={isOnlyLearnings}>
                <Spacing.Bumper />
                <Spacing.Bumper />

                {/* TODO: not needed until V3 */}
                {/* eslint-disable-next-line no-constant-condition */}
                {false
                    ? exercisesFrameworks.map((exerciseFramework) => {
                          const exerciseFrameworkLink = exerciseFramework.link;
                          if (exerciseFrameworkLink === 'Feedback Tool') {
                              return (
                                  <>
                                      <Fonts.BodyText
                                          key={exerciseFrameworkLink}
                                          onClick={onOpenFeedbackTool}
                                      >
                                          {exerciseFramework.title}
                                      </Fonts.BodyText>
                                      <Spacing.Bumper />
                                  </>
                              );
                          } else if (exerciseFrameworkLink === 'Topic Tool') {
                              if (!hasTopicTool) {
                                  return null;
                              }

                              return (
                                  <>
                                      <a
                                          key={exerciseFrameworkLink}
                                          href={`https://topics-39ziwba.thegrand.world/?groupSession=${userProfile?.member_groups?.[0]}-${sessionNumber}`}
                                          target="_blank"
                                          rel="noreferrer"
                                      >
                                          <Fonts.BodyText>
                                              {exerciseFramework.title}
                                          </Fonts.BodyText>
                                          <Spacing.Bumper />
                                      </a>
                                  </>
                              );
                          }

                          return (
                              <>
                                  <Link to={exerciseFrameworkLink ?? ''}>
                                      <Fonts.BodyText>
                                          {exerciseFramework.title}
                                      </Fonts.BodyText>
                                  </Link>
                                  <Spacing.Bumper />
                              </>
                          );
                      })
                    : null}

                <Overlays.ModalInnerScroller>
                    {isOnlyLearnings ? (
                        <Fonts.LargeText>
                            {`${sessionDateObject.toLocaleString('en-us', {
                                month: 'long',
                            })}
                            ${sessionDateObject.getDate()}, ${sessionDateObject.getFullYear()}`}
                        </Fonts.LargeText>
                    ) : (
                        <Fonts.SmallText>
                            {`Session ${sessionNumber}`}
                        </Fonts.SmallText>
                    )}

                    {selectedTab === 'learnings' ? (
                        <>
                            {isOnlyLearnings ? (
                                <Fonts.Heading3>{`Your group learnings`}</Fonts.Heading3>
                            ) : (
                                <Fonts.Heading4>
                                    {sessionGroupLearnings.length === 0
                                        ? `Your group learnings will appear after session ends`
                                        : `Your group learnings`}
                                </Fonts.Heading4>
                            )}
                            {sessionGroupLearnings.length === 0 ? (
                                <>
                                    <Spacing.Bumper />
                                    <Fonts.BodyText>{`Please come back after your session is finished!`}</Fonts.BodyText>
                                </>
                            ) : (
                                <>
                                    <BulletList>
                                        {sessionGroupLearnings.map?.(
                                            (groupLearning) => (
                                                <BulletListItem
                                                    key={groupLearning.learning}
                                                >
                                                    {groupLearning.isUserLearning ? (
                                                        <YourLearningBoldText>{`Your learning: `}</YourLearningBoldText>
                                                    ) : null}
                                                    <BulletText>{`“${groupLearning.learning}”`}</BulletText>
                                                </BulletListItem>
                                            )
                                        )}
                                    </BulletList>
                                    {sessionKudosCounts.size > 0 ? (
                                        <>
                                            <Spacing.Bumper />
                                            <Spacing.Bumper />
                                            <Fonts.Heading6>{`Members in your group gave you the following kudos:`}</Fonts.Heading6>
                                            <Fonts.BodyText>
                                                {`You were recognized for being `}
                                                {kudosCountsMap}
                                            </Fonts.BodyText>
                                        </>
                                    ) : null}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Fonts.Heading4>
                                {sessionObjectiveTitle}
                            </Fonts.Heading4>
                            <Spacing.Bumper />
                            {markdownStrings.map((markdownString, index) => (
                                <>
                                    {selectedTab === 'objectives' &&
                                    index !== 0 ? (
                                        // TODO: remove temporary inline styling
                                        <div
                                            style={{
                                                marginTop: '-72px',
                                            }}
                                        />
                                    ) : null}
                                    <MarkdownWrapper
                                        key={index}
                                        markdownString={markdownString}
                                    />
                                </>
                            ))}
                        </>
                    )}
                </Overlays.ModalInnerScroller>
            </SessionOverviewWrapper>

            {createPortal(
                <Overlays.ModalOuterWrapper $isOpen={isFeedbackToolOpen}>
                    <Overlays.BackgroundOverlay
                        onClick={onCloseFeedbackTool}
                        $isOpen={isFeedbackToolOpen}
                    />
                    <Overlays.ModalInnerWrapper $shouldFillScreen>
                        <FeedbackTool onClose={onCloseFeedbackTool} />
                    </Overlays.ModalInnerWrapper>
                </Overlays.ModalOuterWrapper>,
                document.getElementById('app-wrapper') ?? document.body
            )}
        </>
    );
}

export default SessionOverview;
