import { Database } from 'libs/supabaseTypes';

import React, { useCallback, useEffect, useState } from 'react';

import { useSupabase } from 'hooks/useSupabase';
import { useToast } from 'hooks/useToast';

import { AuthenticateOnboardingRoute } from 'components/AppRoutes';
import WaitlistRedirect from 'components/WaitlistRedirect';
import SubmitIntake from 'components/SubmitIntake';
import SubmitDiagnostic from 'components/SubmitDiagnostic';
import BackgroundWrapper from 'components/BackgroundWrapper';
import Grandimation from 'components/Grandimation';

import { Fonts, Spacing } from 'styles/theme';
import { OnboardingSplashWrapper } from 'styles/onboardingContainer';

type IntakeData = Database['public']['Tables']['closings']['Row'];
type DiagnosticData = Database['public']['Tables']['diagnostics']['Row'];
type View = 'loading' | 'waitlist' | 'intake' | 'diagnostic' | 'complete';

function OnboardingContainer() {
    const { supabase, userId, userProfile } = useSupabase();
    const { showErrorToast } = useToast();

    const [intakes, setIntakes] = useState<Array<IntakeData>>([]);
    const [diagnostics, setDiagnostics] = useState<Array<DiagnosticData>>([]);
    const [currentView, setCurrentView] = useState<View>('loading');

    const getIntakesAndDiagnostics = useCallback(async () => {
        const { data: intakesData, error: intakesError } = await supabase
            .from('intakes')
            .select('*')
            .eq('created_by', userId);

        if (intakesError == null) {
            setIntakes(intakesData);
        } else {
            showErrorToast(intakesError.message);
        }

        const { data: diagnosticsData, error: diagnosticsError } =
            await supabase
                .from('diagnostics')
                .select('*')
                .eq('created_by', userId);

        if (diagnosticsError == null) {
            setDiagnostics(diagnosticsData);
        } else {
            showErrorToast(diagnosticsError.message);
        }
    }, [showErrorToast, supabase, userId]);

    useEffect(() => {
        if (userId !== '') {
            getIntakesAndDiagnostics();
        }
    }, [getIntakesAndDiagnostics, userId]);

    useEffect(() => {
        if (userProfile == null) {
            setCurrentView('loading');
        } else if (userProfile?.temp_group_sponsor_id == null) {
            setCurrentView('waitlist');
        } else if (intakes.length === 0) {
            setCurrentView('intake');
        } else if (diagnostics.length === 0) {
            setCurrentView('diagnostic');
        } else {
            setCurrentView('complete');
        }
    }, [diagnostics.length, intakes.length, userProfile]);

    const renderOnboardingComponent = useCallback(() => {
        switch (currentView) {
            case 'waitlist': {
                return <WaitlistRedirect />;
            }
            case 'intake': {
                return <SubmitIntake onSubmit={getIntakesAndDiagnostics} />;
            }
            case 'diagnostic': {
                return <SubmitDiagnostic onSubmit={getIntakesAndDiagnostics} />;
            }
            case 'complete': {
                return (
                    <OnboardingSplashWrapper>
                        <Fonts.Heading3>{`Thanks for your response! Our team will reach out soon about next steps.`}</Fonts.Heading3>
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Fonts.Heading6>{`In the meantime, check out the MLL and our other tools:`}</Fonts.Heading6>
                        <Spacing.Bumper />
                        <a
                            href="https://tools.thegrand.world/library"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Fonts.BodyTextLink>
                                {`Member Learning Library`}
                            </Fonts.BodyTextLink>
                        </a>
                        <Spacing.Bumper />
                        <a
                            href="https://tools.thegrand.world/tools/sbio"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Fonts.BodyTextLink>
                                {`Feedback Tool`}
                            </Fonts.BodyTextLink>
                        </a>
                        <Spacing.Bumper />
                        <a
                            href="https://compass.thegrand.world/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Fonts.BodyTextLink>
                                {`The Grand Compass`}
                            </Fonts.BodyTextLink>
                        </a>
                        <Spacing.Bumper />
                        <a
                            href="https://cat.thegrand.world/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Fonts.BodyTextLink>
                                {`The Grand Cat`}
                            </Fonts.BodyTextLink>
                        </a>
                    </OnboardingSplashWrapper>
                );
            }
            case 'loading':
            default: {
                return (
                    <div>
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Grandimation />
                    </div>
                );
            }
        }
    }, [currentView, getIntakesAndDiagnostics]);

    return (
        <>
            <AuthenticateOnboardingRoute />

            <BackgroundWrapper
                shouldIncludeFooter={true}
                currentView={currentView}
            >
                {renderOnboardingComponent()}
            </BackgroundWrapper>
        </>
    );
}

export default OnboardingContainer;
