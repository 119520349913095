import styled from 'styled-components';

import { colors, breakpoints, Fonts, isMobile } from 'styles/theme';

export const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${colors.flatSkyGreen};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    overflow: hidden;
`;

export const OrangeMountainsLandscape = styled.img`
    position: absolute;
    top: 40px;
    width: 100%;
`;

export const ClosingsWrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 1000px;
    padding: 76px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    overflow: scroll;
    pointer-events: all;

    @media only screen and (max-width: ${breakpoints.small}) {
        padding: 24px;
        padding-top: 64px;
    }
`;

export const Heading = styled(isMobile ? Fonts.Heading3 : Fonts.Heading2)`
    margin: 14px 0;
`;

export const ClosingsListWrapper = styled.div`
    margin-top: 64px;
    border-radius: 10px;
    background-color: ${colors.light};
    padding: 48px;
    padding-left: 44px;
    padding-right: 100px;

    @media only screen and (max-width: ${breakpoints.small}) {
        padding-right: 40px;
    }
`;

export const ClosingsList = styled.div`
    height: 100%;
`;

export const ClosingWrapper = styled.div`
    margin-bottom: 40px;
    display: flex;

    &:last-of-type {
        margin-bottom: 0;
    }

    @media only screen and (max-width: ${breakpoints.small}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const ClosingSessionNumberWrapper = styled.div`
    margin-top: 14px;
    margin-right: 18px;
    min-width: 72px;
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: ${breakpoints.small}) {
        margin-bottom: 12px;
        min-width: 0;
    }
`;

export const ClosingSessionNumber = styled.h1`
    font-family: 'OpenSans-Regular';
    font-size: 66px;
    color: ${colors.primary2};

    @media only screen and (max-width: ${breakpoints.small}) {
        font-size: 40px;
    }
`;

export const ClosingTextWrapper = styled.div`
    margin-bottom: 40px;
`;

export const LearningsText = styled(Fonts.BodyText)`
    margin-top: 2px;
    margin-bottom: 32px;
`;

export const FeedbackWrapper = styled.div`
    margin-top: 12px;
`;

export const FeedbackPrompt = styled(Fonts.BodyText).attrs({ as: 'span' })``;

export const FeedbackResponse = styled(Fonts.BodyQuote).attrs({ as: 'span' })`
    color: ${colors.primary2};
`;
