import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react';

interface BrowserDataContext {
    isSafari: boolean;
}

const BrowserDataDefaultState: BrowserDataContext = {
    isSafari: false,
};

const BrowserDataContext = createContext(BrowserDataDefaultState);

export function BrowserDataProvider(props: PropsWithChildren) {
    const { children } = props;

    const [isSafari, setIsSafari] = useState<boolean>(false);

    useEffect(() => {
        // This is messy and makes TypeScript angry, but it works to detect
        // the browser as Safari to account for an edge-case scrolling bug
        setIsSafari(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            /constructor/i.test(window.HTMLElement) ||
                (function (p) {
                    return p.toString() === '[object SafariRemoteNotification]';
                })(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    !window['safari'] ||
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        (typeof safari !== 'undefined' &&
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            window['safari'].pushNotification)
                )
        );
    }, []);

    return (
        <BrowserDataContext.Provider value={{ isSafari }}>
            {children}
        </BrowserDataContext.Provider>
    );
}

export const useBrowserData = () => useContext(BrowserDataContext);
