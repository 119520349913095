import styled from 'styled-components';

import { Fonts } from 'styles/theme';

export const List = styled.div`
    list-style: inside;
`;

export const ItalicText = styled(Fonts.BodyText)`
    font-style: italic;
`;
