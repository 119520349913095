import styled from 'styled-components';

import { Fonts, colors } from 'styles/theme';

export const AvatarImageWrapper = styled.div`
    border-radius: 64px;
    background-color: ${colors.white};
    // This is hacky, the only place that needs it is the "current" avatar
    // at the top of the selection modal
    max-width: 128px;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ProfileAvatarImageWrapper = styled(AvatarImageWrapper)`
    position: relative;
    top: -40px;
    left: 16px;
`;

export const AvatarImage = styled.img`
    width: 96px;
`;

export const DetailsWrapper = styled.div`
    position: relative;
    margin-top: -28px;
    width: 100%;
`;

const IconButton = styled.button`
    border: 1px solid ${colors.borderDark};
    border-radius: 60px;
    background-color: ${colors.white};
    padding: 8px 10px;
`;

export const EditButton = styled(IconButton)`
    position: absolute;
    right: 14px;
    cursor: pointer;
`;

export const AvatarButton = styled(IconButton)`
    position: absolute;
    left: 100px;
    top: -50px;
    padding: 10px 10px 6px;
    cursor: pointer;
`;
export const BannerButton = styled(IconButton)`
    position: absolute;
    right: 14px;
    top: -154px;
    padding: 10px 10px 6px;
    cursor: pointer;
`;

export const HeadlineText = styled(Fonts.BodyText)`
    margin: 10px 0;
`;

export const LocationText = styled(Fonts.SmallText)`
    margin-bottom: 10px;
`;

export const GroupIdText = styled(Fonts.SmallLink).attrs({ as: 'span' })``;

export const ResponseWrapper = styled.div`
    border: 1px solid ${colors.borderDark};
    background-color: ${colors.white};
    border-radius: 8px;
    width: 100%;
    padding: 20px 24px;
`;
