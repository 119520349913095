import { Database } from 'libs/supabaseTypes';
import { ProfileData } from 'hooks/useSupabase';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { useBrowserData } from 'hooks/useBrowserData';
import { useSupabase } from 'hooks/useSupabase';
import { useToast } from 'hooks/useToast';

import { AuthenticateCoachRoute } from 'components/AppRoutes';
import SubmitRecap from 'components/SubmitRecap';
import SelectInput from 'components/SelectInput';

import { Fonts, isMobile, Overlays, Spacing } from 'styles/theme';
import { GroupReportCard } from 'styles/groupReports';
import {
    MainWrapper,
    ScrollerWrapper,
    ListWrapper,
    RecapsWrapper,
    CoachSelectWrapper,
} from 'styles/coach';

export type IncompleteRecapData =
    Database['public']['Views']['incomplete_recaps']['Row'];
export type GroupMember = {
    profile: ProfileData;
    attended: boolean;
    closingWords: string;
};

function Coach() {
    const { isSafari } = useBrowserData();
    const { supabase, userId, userProfile } = useSupabase();
    const { showErrorToast } = useToast();

    const [incompleteRecaps, setIncompleteRecaps] = useState<
        Array<IncompleteRecapData>
    >([]);
    const [coachProfiles, setCoachProfiles] = useState<Array<ProfileData>>([]);
    const [selectedCoach, setSelectedCoach] = useState<ProfileData | null>(
        null
    );
    const [selectedIncompleteRecap, setSelectedIncompleteRecap] =
        useState<IncompleteRecapData | null>(null);
    const [groupMembers, setGroupMembers] = useState<{
        [name: string]: GroupMember;
    }>({});

    useEffect(() => {
        const getIncompleteRecaps = async () => {
            const { data, error } = await supabase
                .from('incomplete_recaps')
                .select('*')
                .order('session_end');

            if (error == null) {
                data.sort((a) =>
                    userProfile?.coach_groups?.includes(a.group_id) ? -1 : 0
                );
                setIncompleteRecaps(data);
            } else {
                showErrorToast(error.message);
            }
        };

        const getCoachProfiles = async () => {
            const { data, error } = await supabase
                .from('profiles')
                .select('*')
                .eq('is_coach', true);

            if (error == null) {
                setCoachProfiles(data);
            } else {
                showErrorToast(error.message);
            }
        };

        if (userId !== '') {
            getIncompleteRecaps();
            getCoachProfiles();
        }
        // Adding selectedIncompleteRecap to our dependencies to refetch after submitting a recap
        // eslint-disable-next-line react-hooks-addons/no-unused-deps
    }, [
        showErrorToast,
        supabase,
        userId,
        selectedIncompleteRecap,
        userProfile?.coach_groups,
    ]);

    const onSelectIncompleteRecap = useCallback(
        (incompleteRecap: IncompleteRecapData) => () => {
            setSelectedIncompleteRecap(incompleteRecap);
        },
        []
    );

    useEffect(() => {
        const getGroupMembers = async () => {
            const { data, error } = await supabase
                .from('profiles')
                .select('*')
                .eq('is_active', true)
                .contains('member_groups', [
                    selectedIncompleteRecap?.group_id ?? '',
                ]);

            if (error == null) {
                const newGroupMembers: {
                    [name: string]: GroupMember;
                } = {};
                data.forEach((memberProfile) => {
                    newGroupMembers[memberProfile.full_name] = {
                        profile: memberProfile,
                        attended: false,
                        closingWords: '',
                    };
                });
                setGroupMembers(newGroupMembers);
            } else {
                showErrorToast(error.message);
            }
        };

        if (selectedIncompleteRecap == null) {
            setGroupMembers({});
        } else {
            getGroupMembers();
        }
    }, [selectedIncompleteRecap, showErrorToast, supabase]);

    const onChangeSelectedCoach = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            const newSelectedCoachProfile = coachProfiles.find(
                (coachProfile) => coachProfile.full_name === target.value
            );
            if (newSelectedCoachProfile == null) {
                setSelectedCoach(null);
            } else {
                setSelectedCoach(newSelectedCoachProfile);
            }
        },
        [coachProfiles]
    );

    const selectedIncompleteRecaps = useMemo(
        () =>
            selectedCoach == null
                ? incompleteRecaps
                : incompleteRecaps.filter((incompleteRecap) =>
                      selectedCoach.coach_groups?.includes(
                          incompleteRecap.group_id ?? ''
                      )
                  ),
        [incompleteRecaps, selectedCoach]
    );

    const onClose = useCallback(() => {
        setSelectedIncompleteRecap(null);
        setGroupMembers({});
    }, []);

    return (
        <MainWrapper>
            <AuthenticateCoachRoute />
            <ListWrapper>
                <Spacing.Bumper />
                <Spacing.Bumper />
                <Spacing.Bumper />
                <ScrollerWrapper>
                    <Spacing.Bumper />
                    <Fonts.Heading2>{`Welcome, ${
                        userProfile?.full_name?.split(' ')[0] ??
                        'Grand Explorer'
                    }!`}</Fonts.Heading2>
                    <Spacing.Bumper />
                    <Fonts.LargeText>
                        {selectedIncompleteRecaps.length === 0
                            ? selectedCoach == null
                                ? `You're all set! No recent sessions have incomplete recaps.`
                                : `This coach's sessions have no incomplete recaps. If you would like to filter groups by their coach's name, select one from the dropdown.`
                            : `Please select a recent session to submit a recap. If you would like to filter groups by their coach's name, select one from the dropdown.`}
                    </Fonts.LargeText>
                    <Spacing.Bumper />
                    <CoachSelectWrapper>
                        <SelectInput
                            onInput={onChangeSelectedCoach}
                            value={selectedCoach?.full_name ?? ''}
                            $size="small"
                            $fontFamily="alt"
                        >
                            <option
                                value=""
                                label={
                                    selectedCoach == null
                                        ? 'Filter groups by coach?'
                                        : 'All Coaches'
                                }
                            />
                            {coachProfiles.map((coachProfile, index) => (
                                <option
                                    key={index}
                                    value={coachProfile.full_name ?? ''}
                                >
                                    {coachProfile.full_name}
                                </option>
                            ))}
                        </SelectInput>
                    </CoachSelectWrapper>
                    <Spacing.Bumper />
                    <RecapsWrapper>
                        {selectedIncompleteRecaps.map((incompleteRecap) => {
                            const sessionDate = new Date(
                                incompleteRecap.session_end ?? ''
                            );

                            return (
                                <GroupReportCard
                                    key={incompleteRecap.event_id}
                                    onClick={onSelectIncompleteRecap(
                                        incompleteRecap
                                    )}
                                >
                                    {isMobile ? (
                                        <Fonts.Heading4 $color="orange">
                                            {incompleteRecap.group_id}
                                        </Fonts.Heading4>
                                    ) : (
                                        <Fonts.Heading3 $color="orange">
                                            {incompleteRecap.group_id}
                                        </Fonts.Heading3>
                                    )}
                                    <Fonts.BodyText $color="orange">
                                        {
                                            coachProfiles.find((coachProfile) =>
                                                coachProfile.coach_groups?.includes(
                                                    incompleteRecap.group_id ??
                                                        ''
                                                )
                                            )?.full_name
                                        }
                                    </Fonts.BodyText>
                                    <Spacing.Bumper />
                                    <Fonts.BodyText>
                                        {new Date(sessionDate).toLocaleString(
                                            'default',
                                            {
                                                timeZone: 'UTC',
                                                weekday: 'long',
                                                day: 'numeric',
                                                month: 'long',
                                                year: 'numeric',
                                            }
                                        )}
                                    </Fonts.BodyText>
                                </GroupReportCard>
                            );
                        })}
                    </RecapsWrapper>
                </ScrollerWrapper>
            </ListWrapper>

            {!isSafari || selectedIncompleteRecap != null
                ? createPortal(
                      <Overlays.ModalOuterWrapper
                          $isOpen={selectedIncompleteRecap != null}
                      >
                          <Overlays.BackgroundOverlay
                              onClick={onClose}
                              $isOpen={selectedIncompleteRecap != null}
                          />
                          <Overlays.ModalInnerWrapper $shouldFillScreen>
                              {selectedIncompleteRecap != null ? (
                                  <SubmitRecap
                                      selectedIncompleteRecap={
                                          selectedIncompleteRecap
                                      }
                                      groupMembers={groupMembers}
                                      onClose={onClose}
                                  />
                              ) : null}
                          </Overlays.ModalInnerWrapper>
                      </Overlays.ModalOuterWrapper>,
                      document.getElementById('app-wrapper') ?? document.body
                  )
                : null}
        </MainWrapper>
    );
}

export default Coach;
