import React, { useCallback, useState } from 'react';

import { useSupabase } from 'hooks/useSupabase';
import { useToast } from 'hooks/useToast';

import { DIAGNOSTIC_RATING } from 'libs/constants';

import { AuthenticateRoute } from 'components/AppRoutes';
import RequiredAsterisk from 'components/RequiredAsterisk';
import LoadingButton from 'components/LoadingButton';
import SelectInput from 'components/SelectInput';

import { Fonts, Forms, Spacing } from 'styles/theme';

interface SubmitDiagnosticProps {
    onSubmit: () => Promise<void>;
}

function SubmitDiagnostic(props: SubmitDiagnosticProps) {
    const { onSubmit } = props;

    const { supabase, userId } = useSupabase();
    const { showSuccessToast, showErrorToast } = useToast();

    const [growthMindset, setGrowthMindset] = useState<string>('');
    const [balanceMeaning, setBalanceMeaning] = useState<string>('');
    const [emotionalIntelligence, setEmotionalIntelligence] =
        useState<string>('');
    const [effectiveCommunication, setEffectiveCommunication] =
        useState<string>('');
    const [selfKnowledge, setSelfKnowledge] = useState<string>('');
    const [feedback, setFeedback] = useState<string>('');
    const [conflictResolution, setConflictResolution] = useState<string>('');
    const [delegation, setDelegation] = useState<string>('');
    const [leadershipConfidence, setLeadershipConfidence] =
        useState<string>('');
    const [coaching, setCoaching] = useState<string>('');
    const [isSubmittingDiagnostic, setIsSubmittingDiagnostic] =
        useState<boolean>(false);

    const onChangeGrowthMindset = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setGrowthMindset(target.value);
        },
        []
    );

    const onChangeBalanceMeaning = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setBalanceMeaning(target.value);
        },
        []
    );

    const onChangeEmotionalIntelligence = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setEmotionalIntelligence(target.value);
        },
        []
    );

    const onChangeEffectiveCommunication = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setEffectiveCommunication(target.value);
        },
        []
    );

    const onChangeSelfKnowledge = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setSelfKnowledge(target.value);
        },
        []
    );

    const onChangeFeedback = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setFeedback(target.value);
        },
        []
    );

    const onChangeConflictResolution = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setConflictResolution(target.value);
        },
        []
    );

    const onChangeDelegation = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setDelegation(target.value);
        },
        []
    );

    const onChangeLeadershipConfidence = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setLeadershipConfidence(target.value);
        },
        []
    );

    const onChangeCoaching = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setCoaching(target.value);
        },
        []
    );

    const internalOnSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSubmittingDiagnostic) {
                return;
            }
            setIsSubmittingDiagnostic(true);

            const { error } = await supabase.from('diagnostics').insert({
                created_by: userId,
                growth_mindset: growthMindset,
                balance_meaning: balanceMeaning,
                emotional_intelligence: emotionalIntelligence,
                effective_communication: effectiveCommunication,
                self_knowledge: selfKnowledge,
                feedback,
                conflict_resolution: conflictResolution,
                delegation,
                leadership_confidence: leadershipConfidence,
                coaching,
            });

            if (error == null) {
                showSuccessToast('Successfully submitted diagnostic!');
                onSubmit();
            } else {
                showErrorToast(error.message);
            }

            setIsSubmittingDiagnostic(false);
        },
        [
            isSubmittingDiagnostic,
            supabase,
            userId,
            growthMindset,
            balanceMeaning,
            emotionalIntelligence,
            effectiveCommunication,
            selfKnowledge,
            feedback,
            conflictResolution,
            delegation,
            leadershipConfidence,
            coaching,
            showSuccessToast,
            onSubmit,
            showErrorToast,
        ]
    );

    return (
        <>
            <AuthenticateRoute />

            <Forms.FullPageOuterWrapper>
                <Forms.Heading>{`Diagnostic Survey`}</Forms.Heading>
                <Spacing.Bumper />
                <Fonts.BodyText>
                    {`Each of the competencies in the leadership diagnostic relate to topics and conversations designed into The Grand experience.`}
                </Fonts.BodyText>
                <Spacing.Bumper />
                <Fonts.BodyText>
                    {`Toward the end of your journey, you'll revisit this diagnostic and see what areas you made progress in and what areas you might want to focus on in coaching next.`}
                </Fonts.BodyText>
                <Spacing.Bumper />
                <Spacing.Bumper />
                <Forms.Wrapper onSubmit={internalOnSubmit}>
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Growth Mindset: I approach challenges as opportunities for personal growth and learning.`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <SelectInput
                        onInput={onChangeGrowthMindset}
                        value={growthMindset}
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Balance / Meaning: I have a clear sense of what’s important in my life and how that influences the decisions I make.`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <SelectInput
                        onInput={onChangeBalanceMeaning}
                        value={balanceMeaning}
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Emotional Intelligence: I have the capacity to express my feelings and understand others’ emotions at work.`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <SelectInput
                        onInput={onChangeEmotionalIntelligence}
                        value={emotionalIntelligence}
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Effective Communication: I feel equipped to articulate my ideas and express needs with my team.`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <SelectInput
                        onInput={onChangeEffectiveCommunication}
                        value={effectiveCommunication}
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Self Knowledge / Awareness: I feel I have a strong understanding of myself.`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <SelectInput
                        onInput={onChangeSelfKnowledge}
                        value={selfKnowledge}
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Feedback: I am comfortable giving and receiving feedback.`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <SelectInput
                        onInput={onChangeFeedback}
                        value={feedback}
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Conflict Resolution: I am comfortable resolving conflicts with my team.`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <SelectInput
                        onInput={onChangeConflictResolution}
                        value={conflictResolution}
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Delegation: I am comfortable delegating responsibilities.`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <SelectInput
                        onInput={onChangeDelegation}
                        value={delegation}
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Leadership Confidence: I am confident in my ability to effectively lead and inspire a team.`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <SelectInput
                        onInput={onChangeLeadershipConfidence}
                        value={leadershipConfidence}
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Forms.ExplainerTextWrapper>
                        <Fonts.InputLabel>
                            {`Coaching: I have the coaching skills and tools to do my work well.`}
                            <RequiredAsterisk />
                        </Fonts.InputLabel>
                    </Forms.ExplainerTextWrapper>
                    <SelectInput
                        onInput={onChangeCoaching}
                        value={coaching}
                        required
                    >
                        <option value="" label="Select" hidden />
                        {DIAGNOSTIC_RATING.map((diagnosticRating) => (
                            <option
                                key={diagnosticRating}
                                value={diagnosticRating}
                            >
                                {diagnosticRating}
                            </option>
                        ))}
                    </SelectInput>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <LoadingButton
                        type="submit"
                        value="Submit"
                        isLoading={isSubmittingDiagnostic}
                    >
                        {`Submit`}
                    </LoadingButton>
                </Forms.Wrapper>
            </Forms.FullPageOuterWrapper>
        </>
    );
}

export default SubmitDiagnostic;
