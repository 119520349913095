import React from 'react';

import { Fonts, Spacing } from 'styles/theme';
import { MainWrapper } from 'styles/waitlistRedirect';

function WaitlistRedirect() {
    return (
        <>
            <MainWrapper>
                <Fonts.Heading4>{`We aren't accepting any applications to The Grand Council until Fall 2024.`}</Fonts.Heading4>
                <Spacing.Bumper />
                <Spacing.Bumper />
                <Fonts.LargeText>
                    {`If you'd like, you can join our waitlist `}
                    <Fonts.LargeTextLink
                        href="https://share.hsforms.com/1EMthQoaWTAWgEH4rxvBzBQqy9kk"
                        target="_blank"
                        rel="noreferrer"
                    >{`here`}</Fonts.LargeTextLink>
                    {`.`}
                </Fonts.LargeText>
            </MainWrapper>
        </>
    );
}

export default WaitlistRedirect;
