import styled from 'styled-components';

import SessionBackgroundImageDesktop from 'assets/session-background-desktop.png';
import SessionBackgroundImageMobile from 'assets/session-background-mobile.png';

import { breakpoints } from 'styles/theme';

export const MainWrapper = styled.div`
    background-image: url(${SessionBackgroundImageDesktop});
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 16px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    @media only screen and (max-width: ${breakpoints.small}) {
        background-image: url(${SessionBackgroundImageMobile});
    }
`;

export const ListWrapper = styled.div`
    overflow: hidden;
    width: 100%;
    max-width: 850px;

    @media only screen and (max-width: ${breakpoints.small}) {
        width: 100vw;
        padding: 0 16px;
    }
`;

export const ScrollerWrapper = styled.div`
    max-height: calc(100%);
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
`;

export const CoachSelectWrapper = styled.div`
    width: 100%;
    display: flex;
    padding-left: 12px;
`;

export const RecapsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
`;

export const SignOutWrapper = styled.div`
    position: absolute;
    bottom: 32px;
`;

export const SessionExercisesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
