import { Database } from 'libs/supabaseTypes';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';

import { useBrowserData } from 'hooks/useBrowserData';
import { useSupabase } from 'hooks/useSupabase';
import { useToast } from 'hooks/useToast';

import { AuthenticateCoachRoute } from 'components/AppRoutes';

import X from 'assets/x-black.svg';

import { Buttons, Fonts, Forms, Overlays, Spacing } from 'styles/theme';
import { MainWrapper, ScrollerWrapper, ListWrapper } from 'styles/coach';
import { ItalicText, List } from 'styles/recapHistory';

export type SubmittedRecap = Database['public']['Tables']['recaps']['Row'];

function RecapHistory() {
    const { isSafari } = useBrowserData();
    const { supabase, userId } = useSupabase();
    const { showErrorToast } = useToast();

    const [mySubmittedRecaps, setMySubmittedRecaps] = useState<
        Array<SubmittedRecap>
    >([]);
    const [selectedSubmittedRecap, setSelectedSubmittedRecap] =
        useState<SubmittedRecap | null>(null);

    const parsedSubmittedRecapData = useMemo(
        () =>
            JSON.parse(JSON.stringify(selectedSubmittedRecap?.raw_data ?? {})),
        [selectedSubmittedRecap?.raw_data]
    );

    useEffect(() => {
        const getMySubmittedRecaps = async () => {
            const { data, error } = await supabase.from('recaps').select('*');

            if (error == null) {
                setMySubmittedRecaps(data);
            } else {
                showErrorToast(error.message);
            }
        };

        if (userId !== '') {
            getMySubmittedRecaps();
        }
    }, [showErrorToast, supabase, userId]);

    const onSelectSubmittedRecap = useCallback(
        (submittedRecap: SubmittedRecap) => () => {
            setSelectedSubmittedRecap(submittedRecap);
        },
        []
    );

    const onClose = useCallback(() => {
        setSelectedSubmittedRecap(null);
    }, []);

    return (
        <MainWrapper>
            <AuthenticateCoachRoute />
            <ListWrapper>
                <Spacing.Bumper />
                <Spacing.Bumper />
                <Spacing.Bumper />
                <ScrollerWrapper>
                    <Spacing.Bumper />
                    {mySubmittedRecaps.length === 0 ? (
                        <>
                            <Fonts.LargeText>{`Once you've submitted your first recap, it will appear here along with any future session recaps!`}</Fonts.LargeText>
                        </>
                    ) : (
                        <>
                            <Fonts.LargeText>{`Your submitted recaps:`}</Fonts.LargeText>
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            {mySubmittedRecaps.map((submittedRecap) => {
                                const sessionDate = new Date(
                                    submittedRecap.session_date ?? ''
                                );

                                return (
                                    <>
                                        <Buttons.Primary
                                            key={submittedRecap.id}
                                            onClick={onSelectSubmittedRecap(
                                                submittedRecap
                                            )}
                                            $color="dark2"
                                            $width={300}
                                        >
                                            <Buttons.OverlayDark />
                                            {`${
                                                submittedRecap.group_id
                                            } (${sessionDate.toLocaleString(
                                                'en-us',
                                                {
                                                    month: 'long',
                                                }
                                            )} ${sessionDate.getDate()}, ${sessionDate.getFullYear()})`}
                                        </Buttons.Primary>
                                        <Spacing.Bumper />
                                    </>
                                );
                            })}
                        </>
                    )}
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Link to="/coach">
                        <Fonts.SmallLink>{` < Back to Home`}</Fonts.SmallLink>
                    </Link>
                </ScrollerWrapper>
            </ListWrapper>

            {!isSafari || selectedSubmittedRecap != null
                ? createPortal(
                      <Overlays.ModalOuterWrapper
                          $isOpen={selectedSubmittedRecap != null}
                      >
                          <Overlays.BackgroundOverlay
                              onClick={onClose}
                              $isOpen={selectedSubmittedRecap != null}
                          />
                          <Overlays.ModalInnerWrapper>
                              <Forms.CloseButtonWrapper onClick={onClose}>
                                  <img src={X} />
                              </Forms.CloseButtonWrapper>
                              <Overlays.ModalInnerScroller>
                                  <Fonts.LargeText>{`${
                                      selectedSubmittedRecap?.group_id
                                  } (${new Date(
                                      selectedSubmittedRecap?.session_date ?? ''
                                  ).toLocaleString('en-us', {
                                      month: 'long',
                                  })} ${new Date(
                                      selectedSubmittedRecap?.session_date ?? ''
                                  ).getDate()}, ${new Date(
                                      selectedSubmittedRecap?.session_date ?? ''
                                  ).getFullYear()})`}</Fonts.LargeText>
                                  <Forms.Heading>{`Submitted Session Recap`}</Forms.Heading>
                                  <Spacing.Bumper />
                                  <Fonts.Heading6>
                                      {`Attendance and Closing Words`}
                                  </Fonts.Heading6>
                                  <Spacing.Bumper />
                                  <Fonts.BodyText>
                                      {'Expected attendees:'}
                                  </Fonts.BodyText>
                                  <List>
                                      {parsedSubmittedRecapData.expected_attendees?.map(
                                          (expectedAttendee: string) => (
                                              <li key={expectedAttendee}>
                                                  {expectedAttendee}
                                              </li>
                                          )
                                      )}
                                  </List>
                                  <Spacing.Bumper />
                                  <Spacing.Bumper />
                                  <Fonts.BodyText>
                                      {'Attendees:'}
                                  </Fonts.BodyText>
                                  <List>
                                      {parsedSubmittedRecapData.attributed_closing_words?.map(
                                          (attributedClosingWord: string) => (
                                              <li key={attributedClosingWord}>
                                                  {attributedClosingWord.replace(
                                                      '~~',
                                                      ': '
                                                  )}
                                              </li>
                                          )
                                      )}
                                  </List>
                                  <Spacing.Bumper />
                                  <Spacing.Bumper />
                                  <Spacing.Divider />

                                  <Spacing.Bumper />
                                  <Fonts.Heading6>
                                      {`Content and learnings`}
                                  </Fonts.Heading6>
                                  <Spacing.Bumper />
                                  <Fonts.BodyText>{'Topics:'}</Fonts.BodyText>
                                  <List>
                                      <li>{parsedSubmittedRecapData.topic1}</li>
                                      <li>{parsedSubmittedRecapData.topic2}</li>
                                      <li>{parsedSubmittedRecapData.topic3}</li>
                                  </List>
                                  <Spacing.Bumper />
                                  <Spacing.Bumper />
                                  <Fonts.BodyText>
                                      {'Exercises:'}
                                  </Fonts.BodyText>
                                  <List>
                                      {parsedSubmittedRecapData.selected_exercises?.map(
                                          (selectedExercise: string) => (
                                              <li key={selectedExercise}>
                                                  {selectedExercise}
                                              </li>
                                          )
                                      )}
                                  </List>
                                  <Spacing.Bumper />
                                  <Spacing.Bumper />
                                  <Fonts.BodyText>
                                      {'Learnings:'}
                                  </Fonts.BodyText>
                                  <ItalicText>
                                      {`"${parsedSubmittedRecapData.learnings}"`}
                                  </ItalicText>
                                  <Spacing.Bumper />
                                  <Spacing.Bumper />
                                  <Spacing.Divider />

                                  <Spacing.Bumper />
                                  <Fonts.Heading6>
                                      {`Session and Product Feedback`}
                                  </Fonts.Heading6>
                                  <Spacing.Bumper />
                                  <Fonts.BodyText>
                                      {`"The Grand’s training and support helped me feel prepared for this session."`}
                                  </Fonts.BodyText>
                                  <ItalicText>
                                      {parsedSubmittedRecapData.exit}
                                  </ItalicText>
                                  <Spacing.Bumper />
                                  <Spacing.Bumper />
                                  <Fonts.BodyText>
                                      {`Admin time:`}
                                  </Fonts.BodyText>
                                  <ItalicText>
                                      {parsedSubmittedRecapData.admin_time}
                                  </ItalicText>
                                  <Spacing.Bumper />
                                  <Spacing.Bumper />
                                  {parsedSubmittedRecapData.admin_type !=
                                      null &&
                                  parsedSubmittedRecapData.admin_type !== '' ? (
                                      <>
                                          <Fonts.BodyText>
                                              {`Admin type:`}
                                          </Fonts.BodyText>
                                          <ItalicText>
                                              {
                                                  parsedSubmittedRecapData.admin_type
                                              }
                                          </ItalicText>
                                          <Spacing.Bumper />
                                          <Spacing.Bumper />
                                      </>
                                  ) : null}
                                  {parsedSubmittedRecapData.other != null &&
                                  parsedSubmittedRecapData.admin_type !== '' ? (
                                      <>
                                          <Fonts.BodyText>
                                              {`Other feedback:`}
                                          </Fonts.BodyText>
                                          <ItalicText>
                                              {parsedSubmittedRecapData.other}
                                          </ItalicText>
                                          <Spacing.Bumper />
                                          <Spacing.Bumper />
                                      </>
                                  ) : null}
                              </Overlays.ModalInnerScroller>
                          </Overlays.ModalInnerWrapper>
                      </Overlays.ModalOuterWrapper>,
                      document.getElementById('app-wrapper') ?? document.body
                  )
                : null}
        </MainWrapper>
    );
}

export default RecapHistory;
