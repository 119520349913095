import { RetroData } from 'components/Retrospectives';

import React, { Fragment, useCallback, useMemo, useState } from 'react';

import { useSupabase } from 'hooks/useSupabase';
import { useAnalytics } from 'hooks/useAnalytics';
import { useToast } from 'hooks/useToast';

import { getCurrentAndNextPeriod, mapAssessmentQuestion } from 'libs/utils';
import { ASSESSMENT_CATEGORIES } from 'libs/constants';

import AssessmentSubtotals, {
    returnCategoryImageSrc,
} from 'components/AssessmentSubtotals';
import SelectInput from 'components/SelectInput';
import LoadingButton from 'components/LoadingButton';
import RequiredAsterisk from 'components/RequiredAsterisk';

import X from 'assets/x-black.svg';

import { Fonts, Inputs, Overlays, Forms, Spacing } from 'styles/theme';
import { CategoryHeader } from 'styles/submitAssessment';

interface SubmitAssessmentProps {
    onCompleteAssessment?: (retro: RetroData) => () => void;
    onClose?: () => void;
}

function SubmitAssessment(props: SubmitAssessmentProps) {
    const { onCompleteAssessment, onClose } = props;

    const { supabase, userEmail, userId, userProfile } = useSupabase();
    const { sendEvent } = useAnalytics();
    const { showSuccessToast, showErrorToast } = useToast();

    const { currentPeriod } = useMemo(() => getCurrentAndNextPeriod(), []);

    // TODO: this should be refactored with useReducer and typed properly
    const [submissionPayload, setSubmissionPayload] = useState({
        reactions: '',
        reflections: '',
        share: true,
        period: currentPeriod,
        subtotals: {},
    });
    const [isSubmittingAssessment, setIsSubmittingAssessment] =
        useState<boolean>(false);

    const calculateSubtotal = useCallback(
        (
            databaseQuestionParam: string,
            newSubmissionPayload: typeof submissionPayload
        ): number => {
            let subtotal = 0;
            const prefix = databaseQuestionParam.slice(0, 2);
            for (let i = 1; i <= 3; i++) {
                // See TODO above
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const questionValue = newSubmissionPayload[`${prefix}${i}`];
                subtotal += questionValue == null ? 0 : questionValue;
            }
            return subtotal;
        },
        []
    );

    const onQuestionInput = useCallback(
        (databaseQuestionParam: string, category: string) =>
            (event: React.FormEvent<HTMLSelectElement>) => {
                const newSubmissionPayload = { ...submissionPayload };
                const target = event.target as HTMLSelectElement;
                // See TODO above
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newSubmissionPayload[databaseQuestionParam] = parseInt(
                    target.value,
                    10
                );
                // See TODO above
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newSubmissionPayload.subtotals[category] = calculateSubtotal(
                    databaseQuestionParam,
                    newSubmissionPayload
                );
                setSubmissionPayload(newSubmissionPayload);
            },
        [calculateSubtotal, submissionPayload]
    );

    const onShareInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const newSubmissionPayload = { ...submissionPayload };
            const target = event.target as HTMLInputElement;
            newSubmissionPayload.share = target.checked;
            setSubmissionPayload(newSubmissionPayload);
        },
        [submissionPayload]
    );

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (userProfile?.is_test) {
                showErrorToast('Test accounts cannot submit retros');
                return;
            }

            if (isSubmittingAssessment) {
                return;
            }
            setIsSubmittingAssessment(true);

            const { data, error } = await supabase
                .from('retros')
                .insert({
                    ...submissionPayload,
                    created_by: userId ?? '',
                    email: userEmail,
                })
                .select();

            if (error == null) {
                onCompleteAssessment?.(data[0])();
                showSuccessToast('Your assessment has been submitted!');

                sendEvent?.('retro_assessment_submitted', {
                    retro_period: currentPeriod,
                });
            } else {
                showErrorToast(error.message);
            }

            setIsSubmittingAssessment(false);
        },
        [
            currentPeriod,
            isSubmittingAssessment,
            onCompleteAssessment,
            sendEvent,
            showErrorToast,
            showSuccessToast,
            submissionPayload,
            supabase,
            userEmail,
            userId,
            userProfile?.is_test,
        ]
    );

    return (
        <>
            {onClose == null ? null : (
                <Forms.CloseButtonWrapper onClick={onClose}>
                    <img src={X} />
                </Forms.CloseButtonWrapper>
            )}
            <Overlays.ModalInnerScroller>
                <Fonts.LargeText>{`The Grand Retro`}</Fonts.LargeText>
                <Forms.Heading>{`${currentPeriod} Assessment`}</Forms.Heading>
                <Spacing.Bumper />
                <Spacing.Divider />
                <Spacing.Bumper />
                <Fonts.BodyText>{`Hey, ${userProfile?.full_name}! This is your place to track your personal growth,
            stay accountable to your goals, and track your session learnings. Reflecting
            is your way to track progress.`}</Fonts.BodyText>
                <Spacing.Bumper />
                <Fonts.BodyText>{`Based on scientific research, we’ve developed an assessment to help you
            measure your wellbeing.`}</Fonts.BodyText>
                <Spacing.Bumper />
                <Fonts.BodyText>{`Reflection is how you will track your growth and progress throughout your
            time at The Grand. Aligning your values with how you are doing today is the best
            way to understand if you’re getting closer to your goals.`}</Fonts.BodyText>
                <Spacing.Bumper />
                <Fonts.BodyText>{`As part of this Grand Assessment Quarterly Retrospective, you have the
            opportunity to assess where you are today and how that might evolve in the
            quarters to come.`}</Fonts.BodyText>
                <Spacing.Bumper />
                <Fonts.InputLabel>{`Rank each of the statements below from:`}</Fonts.InputLabel>
                <Fonts.BodyTextBold>
                    {`1 (Highly Disagree) to 10 (Highly Agree)`}
                    <RequiredAsterisk />
                </Fonts.BodyTextBold>
                <Spacing.Bumper />
                <Spacing.Divider />

                <form onSubmit={onSubmit}>
                    {Object.keys(ASSESSMENT_CATEGORIES).map((category) => (
                        <Fragment key={category}>
                            <Spacing.Bumper />
                            <CategoryHeader>
                                <Fonts.Heading5>{category}</Fonts.Heading5>
                                <img
                                    src={returnCategoryImageSrc(category)}
                                    width={40}
                                />
                            </CategoryHeader>
                            <Spacing.Bumper />
                            {/* A very annoying TypeScript error that requires you to type the object, its keys,
                            and its values even though you're iterating through that same object's keys */}
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            {ASSESSMENT_CATEGORIES[category].map(
                                (question: string, index: number) => {
                                    const databaseQuestionParam =
                                        mapAssessmentQuestion(category, index);
                                    return (
                                        <Fragment key={question}>
                                            <Fonts.BodyText>
                                                {question}
                                            </Fonts.BodyText>
                                            <Spacing.Bumper />
                                            <SelectInput
                                                name={`${question} select`}
                                                onInput={onQuestionInput(
                                                    databaseQuestionParam,
                                                    category
                                                )}
                                                value={
                                                    // See TODO above
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-ignore
                                                    submissionPayload[
                                                        databaseQuestionParam
                                                    ]
                                                }
                                                required
                                            >
                                                <option
                                                    value=""
                                                    label="Select"
                                                    hidden
                                                />
                                                {(() => {
                                                    const selectOptions = [];
                                                    for (
                                                        let i = 1;
                                                        i <= 10;
                                                        i++
                                                    ) {
                                                        selectOptions.push(
                                                            <option value={i}>
                                                                {i}
                                                            </option>
                                                        );
                                                    }
                                                    return selectOptions;
                                                })()}
                                            </SelectInput>
                                            <Spacing.Bumper />
                                            <Spacing.Bumper />
                                        </Fragment>
                                    );
                                }
                            )}
                        </Fragment>
                    ))}
                    <Spacing.Divider />
                    <Spacing.Bumper />
                    <Fonts.Heading7>{`Here are the totals for each of your dimensions.`}</Fonts.Heading7>
                    <AssessmentSubtotals
                        subtotals={submissionPayload.subtotals}
                    />
                    <Spacing.Divider />
                    <Spacing.Bumper />
                    <Fonts.BodyText>{`Sharing your assessment results with your coach will enable them to help you work toward your goals and make the most of the live experience.`}</Fonts.BodyText>
                    <Spacing.Bumper />
                    <Forms.CheckboxWrapper>
                        <Inputs.Checkbox
                            type="checkbox"
                            name="share"
                            onChange={onShareInput}
                            checked={submissionPayload.share}
                        />
                        <Fonts.SmallText>
                            {`I would like to share a copy of my assessment results with
                    my coach.`}
                        </Fonts.SmallText>
                    </Forms.CheckboxWrapper>
                    <Spacing.Bumper />{' '}
                    <LoadingButton
                        type="submit"
                        value="Submit"
                        isLoading={isSubmittingAssessment}
                        $stretch
                    >
                        {`Continue`}
                    </LoadingButton>
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                </form>
            </Overlays.ModalInnerScroller>
        </>
    );
}

export default SubmitAssessment;
