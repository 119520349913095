import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react';

import { useSupabase } from './useSupabase';
import { useToast } from 'hooks/useToast';

interface ImaginedFutureContext {
    imaginedFuture: string;
}

const imaginedFutureDefaultState: ImaginedFutureContext = {
    imaginedFuture: '',
};

const ImaginedFutureContext = createContext(imaginedFutureDefaultState);

export function ImaginedFutureProvider(props: PropsWithChildren) {
    const { children } = props;

    const { supabase, userId } = useSupabase();
    const { showErrorToast } = useToast();

    const [imaginedFuture, setImaginedFuture] = useState<string>('');

    useEffect(() => {
        const getImaginedFuture = async () => {
            const { data, error } = await supabase
                .from('profiles')
                .select('*')
                .eq('id', userId);

            if (error == null) {
                setImaginedFuture(data?.[0]?.imagined_future ?? '');
            } else {
                showErrorToast(error.message);
            }
        };

        if (userId !== '') {
            getImaginedFuture();
        } else {
            setImaginedFuture('');
        }
    }, [showErrorToast, supabase, userId]);

    return (
        <ImaginedFutureContext.Provider value={{ imaginedFuture }}>
            {children}
        </ImaginedFutureContext.Provider>
    );
}

export const useImaginedFuture = () => useContext(ImaginedFutureContext);
