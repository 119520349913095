import styled, { css } from 'styled-components';

import SessionBackgroundImageDesktop from 'assets/session-background-desktop.png';
import SessionBackgroundImageMobile from 'assets/session-background-mobile.png';

import { Fonts, breakpoints, colors } from 'styles/theme';

const DESKTOP_SCREEN_PADDING = '64px';

export const Background = styled.div`
    height: 100%;
    width: 100%;
    background-image: url(${SessionBackgroundImageDesktop});
    background-position: center;
    background-size: cover;

    @media only screen and (max-width: ${breakpoints.small}) {
        background-image: url(${SessionBackgroundImageMobile});
    }
`;

export const SessionsWrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: ${DESKTOP_SCREEN_PADDING};
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    @media only screen and (max-width: ${breakpoints.small}) {
        padding: 32px;
        padding-top: 48px;
    }
`;

export const SessionListWrapper = styled.div`
    margin-top: 32px;
    width: calc(100% - (${DESKTOP_SCREEN_PADDING} * 2));
    overflow: scroll;

    @media only screen and (max-width: ${breakpoints.small}) {
        width: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const SessionList = styled.div`
    min-height: 100px;
`;

export const SessionButton = styled.button`
    position: relative;
    margin: 16px;
    width: 80px;
    height: 80px;
    background-color: ${colors.light};
    border-radius: 50px;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const CurrentSessionButton = styled(SessionButton)`
    background-color: ${colors.primary2};
`;

export const FutureSessionButton = styled(SessionButton)`
    background-color: ${colors.borderWhiteBold};
    opacity: 0.8;
`;

export const ImaginedFutureBodyText = styled(Fonts.BodyText)`
    margin-top: 16px;
    max-width: 500px;
`;

interface NotificationProps {
    $isNotificationMinimized: boolean;
}

export const CompleteClosingNotification = styled.div<NotificationProps>`
    position: absolute;
    bottom: 24px;
    right: 24px;
    border: 1px solid ${colors.borderDark};
    border-radius: 4px;
    background-color: ${colors.light};
    box-shadow: ${colors.boxShadow};
    transition: 0.4s;

    @media only screen and (max-width: ${breakpoints.small}) {
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0;
        box-shadow: none;

        ${(props) => {
            switch (props.$isNotificationMinimized) {
                case true: {
                    return css`
                        bottom: -224px;
                    `;
                }
                case false:
                default: {
                    return;
                }
            }
        }};
    }
`;

export const CompleteClosingNotificationSection = styled.div`
    padding: 24px;
    border-bottom: 1px solid ${colors.borderDark};
    display: flex;
    justify-content: center;

    &:first-of-type {
        padding: 16px;
    }

    &:last-of-type {
        border-bottom: none;
    }

    @media only screen and (max-width: ${breakpoints.small}) {
        padding: 20px;

        &:first-of-type {
            display: flex;
            justify-content: space-between;
            padding: 16px;
            cursor: pointer;
        }
    }
`;

export const DropdownCaret = styled.img<NotificationProps>`
    transition: 0.2s;
    width: 16px;

    ${(props) => {
        switch (props.$isNotificationMinimized) {
            case true: {
                return css`
                    transform: scaleY(-1);
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }};
`;

export const SummaryWrapper = styled.div`
    background-color: ${colors.borderDark};
    padding: 24px;
    border-radius: 4px;
`;
