import { RetroData } from 'components/Retrospectives';

import React, { useCallback, useState } from 'react';

import { mapAssessmentQuestion } from 'libs/utils';
import { ASSESSMENT_CATEGORIES } from 'libs/constants';

import CaretDown from 'assets/caret-down.svg';

import { Fonts, Spacing, isMobile } from 'styles/theme';
import {
    CategoryWrapper,
    ResponsesWrapper,
    ResponseCategoryWrapper,
    DropdownCaret,
    ResponseQuestionWrapper,
    QuestionText,
    ResponseNumber,
} from 'styles/assessmentResultDetails';

interface AssessmentResultDetailsProps {
    // TODO: properly type this
    subtotals: object | null;
    selectedRetro: RetroData | null;
}

function AssessmentResultDetails(props: AssessmentResultDetailsProps) {
    const { subtotals, selectedRetro } = props;

    const [expandedCategory, setExpandedCategory] = useState<string | null>('');
    const onExpandCategory = useCallback(
        (category: string) => () => {
            if (category === expandedCategory) {
                setExpandedCategory('');
            } else {
                setExpandedCategory(category);
            }
        },
        [expandedCategory]
    );

    return (
        <>
            {Object.keys(ASSESSMENT_CATEGORIES).map((category) => (
                <CategoryWrapper key={category}>
                    <ResponsesWrapper onClick={onExpandCategory(category)}>
                        <ResponseCategoryWrapper>
                            <ResponseNumber>
                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                {/* @ts-ignore */}
                                {subtotals?.[category]}
                            </ResponseNumber>
                            {isMobile ? (
                                <Fonts.BodyText>{category}</Fonts.BodyText>
                            ) : (
                                <Fonts.Heading5>{category}</Fonts.Heading5>
                            )}
                            <DropdownCaret
                                src={CaretDown}
                                $isDropdownOpen={expandedCategory === category}
                            />
                        </ResponseCategoryWrapper>
                        {expandedCategory === category ? (
                            <>
                                <Spacing.Divider />
                                {/* A very annoying TypeScript error that requires you to type the object, its keys */}
                                {/* and its values even though you're iterating through that same object's keys */}
                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                {/* @ts-ignore */}
                                {ASSESSMENT_CATEGORIES[category].map(
                                    (question: string, index: number) => {
                                        const databaseQuestionParam =
                                            mapAssessmentQuestion(
                                                category,
                                                index
                                            );
                                        const questionResponse =
                                            // Another annoying TypeScript error, we could force the type above
                                            // by setting databaseQuestionParam as a selectedRetro key,
                                            // but that doesn't ensure any type safety
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            selectedRetro?.[
                                                databaseQuestionParam
                                            ];

                                        return (
                                            <ResponseQuestionWrapper
                                                key={question}
                                            >
                                                <ResponseNumber>
                                                    {questionResponse}
                                                </ResponseNumber>
                                                <QuestionText>
                                                    {question}
                                                </QuestionText>
                                                <Spacing.Bumper />
                                            </ResponseQuestionWrapper>
                                        );
                                    }
                                )}
                            </>
                        ) : null}
                    </ResponsesWrapper>
                </CategoryWrapper>
            ))}
        </>
    );
}

export default AssessmentResultDetails;
