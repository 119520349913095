import styled, { css } from 'styled-components';

import { breakpoints, colors } from 'styles/theme';

const FOOTER_HEIGHT = 164;

export const MainWrapper = styled.div`
    min-height: 100%;
    width: 100%;
    overflow: scroll;

    @media only screen and (max-width: ${breakpoints.medium}) {
        overflow-x: hidden;
    }
`;

interface ContentOuterWrapperProps {
    $shouldIncludeFooter: boolean;
}

export const ContentOuterWrapper = styled.div<ContentOuterWrapperProps>`
    position: relative;
    background: linear-gradient(
        ${colors.skyGradientTop},
        ${colors.skyGradientMiddle},
        ${colors.skyGradientBottom}
    );
    min-height: 100%;
    width: 100%;
    padding: 64px;
    display: flex;
    justify-content: center;

    ${(props) => {
        switch (props.$shouldIncludeFooter) {
            case true: {
                return css`
                    min-height: calc(100% - ${FOOTER_HEIGHT}px);
                `;
            }
            case false:
            default: {
                return null;
            }
        }
    }};
`;

export const CloudsBackground = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 600px;
`;

export const GreenMountainsLandscape = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-width: 600px;
`;

export const ContentInnerWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
