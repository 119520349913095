import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
} from 'react';
import Ga4 from 'react-ga4';
import { useLocation } from 'react-router-dom';

import { useSupabase } from 'hooks/useSupabase';

const { REACT_APP_GOOGLE_ANALYTICS_ID } = process.env;

interface AnalyticsContext {
    sendEvent: ((eventName: string, params?: object) => void) | null;
}

const analyticsDefaultState: AnalyticsContext = {
    sendEvent: null,
};

const AnalyticsContext = createContext(analyticsDefaultState);

export function AnalyticsProvider(props: PropsWithChildren) {
    const { children } = props;

    const { userProfile, userCreatedAt } = useSupabase();
    const location = useLocation();

    useEffect(() => {
        Ga4.initialize(REACT_APP_GOOGLE_ANALYTICS_ID ?? '');
    }, []);

    useEffect(() => {
        Ga4.set({ userId: userProfile?.id });
    }, [userProfile?.id]);

    useEffect(() => {
        const path = location.pathname + location.search;
        Ga4.send({ hitType: 'pageview', page: path });
    }, [location]);

    const sendEvent = useCallback(
        (eventName: string, params?: object) => {
            Ga4.event(eventName, {
                ...params,
                user_id: userProfile?.id,
                user_created_at: userCreatedAt,
                is_coach: userProfile?.is_coach,
                is_hq: userProfile?.is_hq,
                referred_by: userProfile?.referred_by,
            });
        },
        [
            userCreatedAt,
            userProfile?.id,
            userProfile?.is_coach,
            userProfile?.is_hq,
            userProfile?.referred_by,
        ]
    );

    return (
        <AnalyticsContext.Provider value={{ sendEvent }}>
            {children}
        </AnalyticsContext.Provider>
    );
}

export const useAnalytics = () => useContext(AnalyticsContext);
