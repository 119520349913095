import { OverlayProps, breakpoints } from 'styles/theme';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors } from 'styles/theme';

export const NavHeaderWrapper = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: ${breakpoints.small}) {
        padding: 16px;
    }
`;

export const OuterLogo = styled.img`
    margin-left: 8px;
    width: 112px;
`;

export const MenuButton = styled.button`
    position: relative;
    border: none;
    border-radius: 4px;
    background-color: ${colors.white};
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: ${colors.boxShadow};
    cursor: pointer;
`;

export const NavMenu = styled.div<OverlayProps>`
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    height: 100vh;
    min-width: 340px;
    background-color: ${colors.primary1};
    padding: 40px;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${colors.white};
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;

    ${(props) => {
        switch (props.$isOpen) {
            case true: {
                return css`
                    opacity: 1;
                    pointer-events: auto;
                `;
            }
            case false:
            default: {
                return null;
            }
        }
    }}

    @media only screen and (max-width: ${breakpoints.small}) {
        min-width: 100%;
    }
`;

export const InnerHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const CloseButtonWrapper = styled.button`
    margin-top: -12px;
    margin-right: -28px;
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

export const NavMenuInnerWrapper = styled.div`
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: start;
    overflow: scroll;
`;

export const ProfileDetailsWrapper = styled.div`
    margin-bottom: -4px;
    display: flex;
    align-items: center;
    transition: 0.2s;

    &:hover {
        opacity: 0.7;
    }
`;

export const AvatarImageWrapper = styled.div`
    margin-right: 14px;
    border-radius: 64px;
    background-color: ${colors.white};
    height: 72px;
    width: 72px;
    padding: 4px;
`;

export const AvatarImage = styled.img`
    border-radius: 64px;
    width: 64px;
`;

export const NavSection = styled.div`
    margin-bottom: 32px;
`;

export const LinkWrapper = styled.div`
    margin-top: 12px;
`;

export const StyledInternalLink = styled(Link)`
    cursor: pointer;
    transition: 0.2s;

    &:hover,
    &:active {
        opacity: 0.7;
    }
`;

export const StyledExternalLink = styled.a`
    cursor: pointer;
    transition: 0.2s;

    &:hover,
    &:active {
        opacity: 0.7;
    }
`;

export const Divider = styled.div`
    width: 100%;
    min-height: 1px;
    background-color: ${colors.light};
`;

export const SignOutWrapper = styled.div`
    margin-top: 32px;
    cursor: pointer;
    transition: 0.2s;

    &:hover,
    &:active {
        opacity: 0.7;
    }
`;

export const Footer = styled.div`
    margin-top: 64px;
`;

export const FooterLinkWrapper = styled.div`
    margin-bottom: 4px;
`;
