import { JoinedGroupProgramHistory } from 'queries/groups';
import { Database } from 'libs/supabaseTypes';
import { GroupData } from 'components/Sessions';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import {
    groupProgramHistoryQuery,
    sortSessionDetails,
    sortModules,
} from 'queries/groups';

import { useSupabase } from 'hooks/useSupabase';
import { useToast } from 'hooks/useToast';
import { useBrowserData } from 'hooks/useBrowserData';
import { useAnalytics } from 'hooks/useAnalytics';

import { MERGED_PRECURSOR_GROUPS } from 'libs/constants';

import { AuthenticateSponsorRoute } from 'components/AppRoutes';
import BackgroundWrapper from 'components/BackgroundWrapper';
import Grandimation from 'components/Grandimation';
import ReportDropdown from 'components/ReportDropdown';
import MarkdownWrapper from 'components/MarkdownWrapper';
import Footer from 'components/Footer';

import GrandLogoGreen from 'assets/grand-logo-green-vert.svg';
import GrandLogoTan from 'assets/grand-logo-tan-horz.svg';
import Like from 'assets/like.svg';
import Dislike from 'assets/dislike.svg';
import X from 'assets/x-black.svg';

import { Fonts, Forms, Overlays, Spacing, isMobile } from 'styles/theme';
import {
    CumulativeDataContent,
    CumulativeDataSection,
    CumulativeDataWrapper,
    Heading,
    LargeNumberText,
    MainWrapper,
    BulletList,
    ReportDetailsWrapper,
    TopBarText,
    TopBarWrapper,
    GroupIndexWrapper,
    GroupReportListWrapper,
    GroupReportCard,
    RatingOuterWrapper,
    RatingImageWrapper,
} from 'styles/groupReports';

export type SponsorData = Database['public']['Tables']['sponsors']['Row'];
export type ReportData = Database['public']['Tables']['reports']['Row'];

function GroupReports() {
    const { supabase, userProfile } = useSupabase();
    const { showSuccessToast, showErrorToast } = useToast();
    const { isSafari } = useBrowserData();
    const { sendEvent } = useAnalytics();

    const [isLoadingSponsorData, setIsLoadingSponsorData] =
        useState<boolean>(true);
    const [sponsor, setSponsor] = useState<SponsorData | null>(null);
    const [sponsorGroupsWithReports, setSponsorGroupsWithReports] = useState<
        Array<GroupData>
    >([]);
    const [selectedGroup, setSelectedGroup] = useState<GroupData | null>(null);
    const [selectedGroupProgramHistory, setSelectedGroupProgramHistory] =
        useState<JoinedGroupProgramHistory | null>(null);
    const [allReports, setAllReports] = useState<Array<ReportData>>([]);
    const [selectedGroupReports, setSelectedGroupReports] = useState<
        Array<ReportData>
    >([]);
    const [areSessionObjectivesOpen, setAreSessionObjectivesOpen] =
        useState<boolean>(false);

    const getAllSponsorData = useCallback(async () => {
        const { data: sponsorData, error: sponsorError } = await supabase
            .from('sponsors')
            .select('*');

        if (sponsorError == null && sponsorData.length > 0) {
            setSponsor(sponsorData[0]);
        } else {
            showErrorToast(
                sponsorError?.message ??
                    'There was an error fetching sponsor data'
            );
            return;
        }

        const { data: groupData, error: groupError } = await supabase
            .from('groups')
            .select('*');
        if (groupError != null || groupData.length === 0) {
            showErrorToast(
                groupError?.message ?? 'There was an error fetching group data'
            );
            return;
        }

        const { data: reportData, error: reportError } = await supabase
            .from('reports')
            .select('*')
            .eq('is_published', true)
            .order('start_date', { ascending: false });
        if (reportError == null && reportData.length > 0) {
            setAllReports(reportData);
        } else {
            showErrorToast(
                reportError?.message ??
                    'There was an error fetching report data'
            );
            return;
        }

        const sponsorGroupsWithReportsData = reportData.map((reportDatum) =>
            groupData.find(
                (groupDatum) => groupDatum.gid === reportDatum.group_id
            )
        );
        setSponsorGroupsWithReports(sponsorGroupsWithReportsData);
        if (groupData.length === 1) {
            setSelectedGroup(groupData[0]);
        }

        setIsLoadingSponsorData(false);
    }, [showErrorToast, supabase]);

    useEffect(() => {
        if (userProfile?.sponsor_id != null) {
            getAllSponsorData();
        }
    }, [getAllSponsorData, userProfile?.sponsor_id]);

    useEffect(() => {
        setSelectedGroupReports(
            structuredClone(
                allReports.filter(
                    (report) => report.group_id === selectedGroup?.gid
                )
            )
        );
    }, [allReports, selectedGroup?.gid]);

    useEffect(() => {
        const getSelectedGroupProgramHistory = async () => {
            const { data, error } = await groupProgramHistoryQuery.eq(
                'id',
                selectedGroup?.group_program_id ?? ''
            );

            if (error == null) {
                const joinedGroupProgramHistory: JoinedGroupProgramHistory =
                    data;
                setSelectedGroupProgramHistory(joinedGroupProgramHistory);
            } else {
                showErrorToast(error.message);
            }
        };

        if (selectedGroup == null || selectedGroup.group_program_id == null) {
            setSelectedGroupProgramHistory(null);
        } else {
            getSelectedGroupProgramHistory();
        }
    }, [selectedGroup, selectedGroup?.group_program_id, showErrorToast]);

    const sortedSessionDetails = useMemo(
        () => sortSessionDetails(selectedGroupProgramHistory),
        [selectedGroupProgramHistory]
    );

    const dedupedOtherGroups = useMemo(
        () =>
            [
                ...new Set(
                    structuredClone(sponsorGroupsWithReports).filter(
                        (group: GroupData) => group.gid !== selectedGroup?.gid
                    )
                ),
            ] as Array<GroupData>,
        [selectedGroup?.gid, sponsorGroupsWithReports]
    );

    const onSelectGroupLink = useCallback(
        (group: GroupData) => () => {
            setSelectedGroup(group);
            sendEvent?.('report_group_link_selected');
        },
        [sendEvent]
    );

    const onSelectGroupViaReport = useCallback(
        (groupId: string) => () => {
            const groupToSelect = sponsorGroupsWithReports.find(
                (group) => group.gid === groupId
            );
            if (groupToSelect != null) {
                setSelectedGroup(groupToSelect);
                sendEvent?.('report_group_card_selected');
            }
        },
        [sendEvent, sponsorGroupsWithReports]
    );

    const mostRecentReport = selectedGroupReports[0] ?? null;

    const rateMostRecentReport = useCallback(
        (isPositive: boolean) => async () => {
            const { error } = await supabase
                .from('reports')
                .update({
                    sponsor_rating: isPositive ? 5 : 1,
                })
                .eq('id', mostRecentReport?.id);

            if (error == null) {
                getAllSponsorData();
                showSuccessToast('Thanks for your feedback!');
            } else {
                showErrorToast(
                    error?.message ??
                        'There was an error when rating this report'
                );
            }
        },
        [
            getAllSponsorData,
            mostRecentReport?.id,
            showErrorToast,
            showSuccessToast,
            supabase,
        ]
    );

    const mostRecentReportPerGroup: Array<ReportData> = useMemo(() => {
        const reportsToReturn: Array<ReportData> = [];
        allReports.forEach((report) => {
            let doesGroupReportExist = false;
            for (let i = 0; i < reportsToReturn.length; i++) {
                if (reportsToReturn[i]?.group_id === report.group_id) {
                    doesGroupReportExist = true;
                }
            }
            if (!doesGroupReportExist) {
                reportsToReturn.push(report);
            }
        });
        return reportsToReturn;
    }, [allReports]);

    const onOpenSessionObjectives = useCallback(() => {
        setAreSessionObjectivesOpen(true);
        sendEvent?.('report_objectives_opened');
    }, [sendEvent]);

    return (
        <>
            <AuthenticateSponsorRoute />

            {selectedGroup == null ? (
                <BackgroundWrapper shouldIncludeFooter>
                    <GroupIndexWrapper>
                        <img src={GrandLogoGreen} width={120} />
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        {isLoadingSponsorData ? (
                            <>
                                <Spacing.Bumper />
                                <Spacing.Bumper />
                                <Spacing.Bumper />
                                <Spacing.Bumper />
                                <Spacing.Bumper />
                                <Spacing.Bumper />
                                <Spacing.Bumper />
                                <Spacing.Bumper />
                                <Grandimation />
                            </>
                        ) : (
                            <>
                                {isMobile ? (
                                    <Fonts.Heading2>{`Welcome, ${sponsor?.sponsor_name}`}</Fonts.Heading2>
                                ) : (
                                    <Fonts.Heading1>{`Welcome, ${sponsor?.sponsor_name}`}</Fonts.Heading1>
                                )}
                                <Spacing.Bumper />
                                <Fonts.BodyText>{`Here are the reports for groups that you sponsor for The Grand:`}</Fonts.BodyText>
                                <Spacing.Bumper />
                                <Spacing.Bumper />
                                <GroupReportListWrapper>
                                    {mostRecentReportPerGroup.map((report) => (
                                        <GroupReportCard
                                            key={report.id}
                                            onClick={onSelectGroupViaReport(
                                                report.group_id
                                            )}
                                        >
                                            {isMobile ? (
                                                <Fonts.Heading4 $color="orange">
                                                    {report.group_id}
                                                </Fonts.Heading4>
                                            ) : (
                                                <Fonts.Heading3 $color="orange">
                                                    {report.group_id}
                                                </Fonts.Heading3>
                                            )}
                                            <Spacing.Bumper />
                                            <div>
                                                <Fonts.FinePrintText $color="light-green">{`Members active during report period:`}</Fonts.FinePrintText>
                                                <Fonts.BodyText>
                                                    {report.active_members?.join(
                                                        ', '
                                                    )}
                                                </Fonts.BodyText>
                                            </div>
                                            <Spacing.Bumper />
                                            <Fonts.SmallLink>{`View ${new Date(
                                                report.start_date
                                            ).toLocaleString('default', {
                                                timeZone: 'UTC',
                                                month: 'long',
                                                year: 'numeric',
                                            })} report >`}</Fonts.SmallLink>
                                        </GroupReportCard>
                                    ))}
                                </GroupReportListWrapper>
                            </>
                        )}
                    </GroupIndexWrapper>
                </BackgroundWrapper>
            ) : (
                <>
                    <MainWrapper>
                        <Heading>
                            <TopBarWrapper>
                                <img src={GrandLogoTan} width={160} />
                                <TopBarText>
                                    <Fonts.FinePrintText $color="light">
                                        {`This data was aggregated on `}
                                        <Fonts.FinePrintText
                                            as="span"
                                            $color="light-orange"
                                        >
                                            {new Date(
                                                mostRecentReport?.created_at ??
                                                    ''
                                            ).toLocaleString('default', {
                                                timeZone: 'UTC',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </Fonts.FinePrintText>
                                        {mostRecentReport?.sessions_remaining ==
                                        null ? (
                                            `.`
                                        ) : (
                                            <>
                                                {` while the group had `}
                                                <Fonts.FinePrintText
                                                    as="span"
                                                    $color="light-orange"
                                                >
                                                    {`${mostRecentReport?.sessions_remaining} sessions remaining`}
                                                </Fonts.FinePrintText>
                                                {`.`}
                                            </>
                                        )}
                                    </Fonts.FinePrintText>
                                </TopBarText>
                            </TopBarWrapper>

                            <Spacing.Divider $color="borderDarkInput" />

                            <CumulativeDataWrapper>
                                <Fonts.SmallCapsText $color="light">
                                    {`Sponsor: `}
                                    <Fonts.SmallCapsText
                                        as="span"
                                        $color="light-orange"
                                    >
                                        {sponsor?.sponsor_name}
                                    </Fonts.SmallCapsText>
                                </Fonts.SmallCapsText>
                                <Spacing.Bumper />

                                <Fonts.SmallCapsText $color="light">
                                    {`Cumulative stats for group: `}
                                </Fonts.SmallCapsText>
                                <Spacing.Bumper />
                                <Fonts.Heading1 $color="light">
                                    {mostRecentReport?.group_id}
                                </Fonts.Heading1>
                                {MERGED_PRECURSOR_GROUPS.includes(
                                    mostRecentReport?.group_id ?? ''
                                ) ? (
                                    <>
                                        <Spacing.Bumper />
                                        <Fonts.SmallText $color="white">{`To maximize founders’ goals to build relationships and connection with each other, The Grand merged this group with another Precursor group that had a larger group size.`}</Fonts.SmallText>
                                    </>
                                ) : null}
                                <Spacing.Bumper />
                                <Spacing.Bumper />

                                <CumulativeDataContent>
                                    <CumulativeDataSection>
                                        <Fonts.BodyTextBold $color="light-orange">{`Members active during report period:`}</Fonts.BodyTextBold>
                                        <BulletList>
                                            {mostRecentReport?.active_members?.map(
                                                (activeMember) => (
                                                    <li key={activeMember}>
                                                        <Fonts.SmallText
                                                            as="li"
                                                            $color="light"
                                                        >
                                                            {activeMember}
                                                        </Fonts.SmallText>
                                                    </li>
                                                )
                                            )}
                                        </BulletList>
                                        {dedupedOtherGroups.length > 0 ? (
                                            <>
                                                <Spacing.Bumper />
                                                <Spacing.Bumper />
                                                <Fonts.BodyTextBold $color="light-orange">{`Links to other group reports:`}</Fonts.BodyTextBold>
                                                <Spacing.Bumper />
                                                {dedupedOtherGroups.map(
                                                    (group: GroupData) => (
                                                        <Fonts.BodyLink
                                                            key={group.gid}
                                                            onClick={onSelectGroupLink(
                                                                group
                                                            )}
                                                            $color="light"
                                                        >
                                                            {group.gid}
                                                        </Fonts.BodyLink>
                                                    )
                                                )}
                                            </>
                                        ) : null}
                                    </CumulativeDataSection>

                                    <CumulativeDataSection>
                                        <LargeNumberText $color="light">
                                            {mostRecentReport?.cumulative_session_score_avg?.toFixed(
                                                1
                                            )}
                                        </LargeNumberText>
                                        <Fonts.BodyTextBold $color="light-orange">{`Session Score Average`}</Fonts.BodyTextBold>
                                        <Spacing.Bumper />
                                        <LargeNumberText $color="light">
                                            {mostRecentReport?.cumulative_csat_avg?.toFixed(
                                                1
                                            ) ?? 'Coming soon!'}
                                        </LargeNumberText>
                                        <Fonts.BodyTextBold $color="light-orange">{`CSAT Average`}</Fonts.BodyTextBold>
                                        <Fonts.FinePrintText $color="grey">{`Customer satisfaction is averaged from survey responses at sessions 4, 8, and 12.`}</Fonts.FinePrintText>
                                    </CumulativeDataSection>
                                </CumulativeDataContent>
                            </CumulativeDataWrapper>
                        </Heading>
                        <ReportDetailsWrapper>
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            {isMobile ? null : (
                                <>
                                    <Spacing.Bumper />
                                </>
                            )}
                            {isMobile ? (
                                <Fonts.Heading3>{`Monthly Stats`}</Fonts.Heading3>
                            ) : (
                                <Fonts.Heading2>{`Monthly Stats`}</Fonts.Heading2>
                            )}
                            <Spacing.Bumper />
                            <Fonts.BodyTextLink
                                onClick={onOpenSessionObjectives}
                            >{`Check out the Session Objectives here >`}</Fonts.BodyTextLink>
                            <Spacing.Bumper />
                            <Spacing.Bumper />

                            {selectedGroupReports.map((report) => (
                                <ReportDropdown
                                    key={report.id}
                                    report={report}
                                />
                            ))}

                            <Spacing.Bumper />
                            {mostRecentReport?.sponsor_rating != null ? null : (
                                <RatingOuterWrapper>
                                    {isMobile ? (
                                        <Fonts.Heading7 $color="light-green">{`Was this report helpful?`}</Fonts.Heading7>
                                    ) : (
                                        <Fonts.Heading4 $color="light-green">{`Was this report helpful?`}</Fonts.Heading4>
                                    )}
                                    <RatingImageWrapper
                                        onClick={rateMostRecentReport(true)}
                                    >
                                        <img
                                            src={Like}
                                            width={isMobile ? 30 : 40}
                                        />
                                    </RatingImageWrapper>
                                    <RatingImageWrapper
                                        onClick={rateMostRecentReport(false)}
                                    >
                                        <img
                                            src={Dislike}
                                            width={isMobile ? 30 : 40}
                                        />
                                    </RatingImageWrapper>
                                </RatingOuterWrapper>
                            )}
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                            <Spacing.Bumper />
                        </ReportDetailsWrapper>
                        <Footer isDark />
                    </MainWrapper>

                    {!isSafari || areSessionObjectivesOpen
                        ? createPortal(
                              <Overlays.ModalOuterWrapper
                                  $isOpen={areSessionObjectivesOpen}
                              >
                                  <Overlays.BackgroundOverlay
                                      onClick={() =>
                                          setAreSessionObjectivesOpen(false)
                                      }
                                      $isOpen={areSessionObjectivesOpen}
                                  />
                                  <Overlays.ModalInnerWrapper>
                                      <Forms.CloseButtonWrapper
                                          onClick={() =>
                                              setAreSessionObjectivesOpen(false)
                                          }
                                      >
                                          <img src={X} />
                                      </Forms.CloseButtonWrapper>
                                      <Overlays.ModalInnerScroller>
                                          <Fonts.Heading2>{`Session Objectives`}</Fonts.Heading2>
                                          <Spacing.Bumper />
                                          {sortedSessionDetails.map(
                                              // Type check is working in development, but compiler flags on build
                                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                              // @ts-ignore
                                              (sessionDetailsDatum, index) => {
                                                  const sortedModules =
                                                      sortModules(
                                                          sessionDetailsDatum?.session_details_modules ??
                                                              [],
                                                          sessionDetailsDatum?.modules ??
                                                              []
                                                      );
                                                  return (
                                                      <>
                                                          <Spacing.Bumper />
                                                          <Fonts.SmallCapsText $color="orange">{`Session ${
                                                              index + 1
                                                          }:`}</Fonts.SmallCapsText>
                                                          <Fonts.Heading3>
                                                              {
                                                                  sessionDetailsDatum?.title
                                                              }
                                                          </Fonts.Heading3>
                                                          {(sessionDetailsDatum
                                                              ?.description
                                                              ?.length ?? 0) >
                                                          0 ? (
                                                              <MarkdownWrapper
                                                                  markdownString={
                                                                      sessionDetailsDatum?.description ??
                                                                      ''
                                                                  }
                                                              />
                                                          ) : (
                                                              sortedModules.map(
                                                                  (
                                                                      module,
                                                                      moduleIndex
                                                                  ) => (
                                                                      <>
                                                                          {moduleIndex !==
                                                                          0 ? (
                                                                              // TODO: remove temporary inline styling
                                                                              <div
                                                                                  style={{
                                                                                      marginTop:
                                                                                          '-72px',
                                                                                  }}
                                                                              />
                                                                          ) : null}
                                                                          <MarkdownWrapper
                                                                              key={`${index}--${moduleIndex}`}
                                                                              markdownString={
                                                                                  module?.description ??
                                                                                  ''
                                                                              }
                                                                          />
                                                                      </>
                                                                  )
                                                              )
                                                          )}
                                                          {/* TODO: remove temporary inline styling */}
                                                          <div
                                                              style={{
                                                                  marginBottom:
                                                                      '-24px',
                                                              }}
                                                          />
                                                      </>
                                                  );
                                              }
                                          )}
                                      </Overlays.ModalInnerScroller>
                                  </Overlays.ModalInnerWrapper>
                              </Overlays.ModalOuterWrapper>,
                              document.getElementById('app-wrapper') ??
                                  document.body
                          )
                        : null}
                </>
            )}
        </>
    );
}

export default GroupReports;
