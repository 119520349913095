import { ProfileData } from 'hooks/useSupabase';

import React, { useEffect, useMemo, useState } from 'react';

import { useSupabase } from 'hooks/useSupabase';
import { useToast } from 'hooks/useToast';

import { checkIsProfileComplete } from 'libs/utils';

import { AvatarList } from 'components/ImageSelector';

import { Buttons, Fonts, Spacing } from 'styles/theme';
import {
    AvatarImage,
    AvatarImageWrapper,
    ProfileLink,
    ProfileListWrapper,
} from 'styles/groupProfiles';

interface GroupProfilesProps {
    groupId: string;
    groupProfiles: Array<ProfileData>;
}

function GroupProfiles(props: GroupProfilesProps) {
    const { groupId, groupProfiles } = props;

    const { supabase, userId, userProfile } = useSupabase();
    const { showErrorToast } = useToast();

    const [isTopicToolAvailable, setIsTopicToolAvailable] =
        useState<boolean>(false);
    const [groupSessionCounter, setGroupSessionCounter] = useState<number>(0);

    useEffect(() => {
        const getGroup = async () => {
            const { data, error } = await supabase
                .from('groups')
                .select('*')
                .eq('gid', groupId);

            if (error == null) {
                setIsTopicToolAvailable(data?.[0]?.has_topic_tool ?? false);
                setGroupSessionCounter(data?.[0]?.session_counter ?? 0);
            } else {
                showErrorToast(error.message);
            }
        };

        if (userProfile != null && userProfile.is_coach) {
            getGroup();
        }
    }, [groupId, showErrorToast, supabase, userProfile]);

    const membersToRawData = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const memberDataMapping = {} as any;
        groupProfiles.forEach((profile) => {
            const parsedRawData = JSON.parse(
                JSON.stringify(profile.raw_data ?? {})
            );
            memberDataMapping[profile.id] = {
                parsedRawData,
                isProfileComplete: checkIsProfileComplete(parsedRawData),
            };
        });
        return memberDataMapping;
    }, [groupProfiles]);

    return (
        <>
            <Spacing.Bumper />
            <Spacing.Bumper />
            <Fonts.Heading3>{`My Group (${groupId})`}</Fonts.Heading3>
            <Spacing.Bumper />
            {isTopicToolAvailable ? (
                <>
                    <Fonts.BodyTextLink
                        href={`https://topics-39ziwba.thegrand.world/?groupSession=${groupId}-${groupSessionCounter}`}
                        target="_blank"
                        rel="noreferrer"
                    >{`Topic Tool`}</Fonts.BodyTextLink>
                    <Spacing.Bumper />
                </>
            ) : null}
            <ProfileListWrapper>
                {groupProfiles.map((profile) => {
                    if (profile.is_active !== true) {
                        return null;
                    }

                    const isProfileComplete =
                        membersToRawData[profile.id]?.isProfileComplete ??
                        false;
                    return (
                        <ProfileLink
                            key={profile.id}
                            to={`/profiles/${groupId}/${profile.id}`}
                            $isProfileAccessible={
                                isProfileComplete || userId === profile.id
                            }
                        >
                            <Buttons.OverlayMedium />
                            <AvatarImageWrapper>
                                <AvatarImage
                                    src={
                                        AvatarList[
                                            membersToRawData[profile.id]
                                                ?.parsedRawData?.avatar ?? 0
                                        ]
                                    }
                                />
                            </AvatarImageWrapper>
                            <div>
                                <Fonts.Heading5>
                                    {profile.full_name}
                                </Fonts.Heading5>
                                <Fonts.SmallText>
                                    {isProfileComplete
                                        ? membersToRawData[profile.id]
                                              ?.parsedRawData?.headline
                                        : userId === profile.id
                                        ? 'Complete your profile!'
                                        : '(Profile not complete)'}
                                </Fonts.SmallText>
                            </div>
                        </ProfileLink>
                    );
                })}
            </ProfileListWrapper>
            <Spacing.Bumper />
            <Spacing.Bumper />
            <Spacing.Bumper />
        </>
    );
}

export default GroupProfiles;
