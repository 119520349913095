import React from 'react';

import { useImaginedFuture } from 'hooks/useImaginedFuture';

import { AuthenticateCouncilMemberRoute } from 'components/AppRoutes';

import HillsBackgroundImage from 'assets/background-hills.png';

import { Fonts, Overlays } from 'styles/theme';
import {
    BodyText,
    BodyTextWrapper,
    Heading,
    HillsLandscape,
    ImaginedFutureWrapper,
    MainWrapper,
} from 'styles/imaginedFuture';

function ImaginedFuture() {
    const { imaginedFuture } = useImaginedFuture();

    return (
        <MainWrapper>
            <AuthenticateCouncilMemberRoute />
            <Overlays.TextureOverlay />
            <HillsLandscape src={HillsBackgroundImage} />
            <ImaginedFutureWrapper>
                <Heading>Imagined Future</Heading>
                <Fonts.LargeText>
                    {`We asked you to imagine it's 6 months from now...`}
                </Fonts.LargeText>
                <BodyTextWrapper>
                    <Fonts.SmallCapsText>{`Here's where you hope to be:`}</Fonts.SmallCapsText>
                    <BodyText>{imaginedFuture}</BodyText>
                </BodyTextWrapper>
            </ImaginedFutureWrapper>
        </MainWrapper>
    );
}

export default ImaginedFuture;
