import { JoinedGroupProgramHistory } from 'queries/groups';
import { Database } from 'libs/supabaseTypes';
import { FontProps } from 'styles/theme';

import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';

import {
    groupProgramHistoryQuery,
    sortSessionDetails,
    sortModules,
} from 'queries/groups';

import { useBrowserData } from 'hooks/useBrowserData';
import { useSupabase } from 'hooks/useSupabase';
import { useAnalytics } from 'hooks/useAnalytics';
import { useImaginedFuture } from 'hooks/useImaginedFuture';
import { useToast } from 'hooks/useToast';

import { AuthenticateCouncilMemberRoute } from 'components/AppRoutes';
import SessionOverview from 'components/SessionOverview';
import SubmitClosing from 'components/SubmitClosing';

import CaretDown from 'assets/caret-down.svg';

import { Buttons, Fonts, Overlays, isMobile, Spacing } from 'styles/theme';
import {
    Background,
    CompleteClosingNotification,
    CompleteClosingNotificationSection,
    CurrentSessionButton,
    DropdownCaret,
    FutureSessionButton,
    ImaginedFutureBodyText,
    SessionButton,
    SessionList,
    SessionListWrapper,
    SessionsWrapper,
    SummaryWrapper,
} from 'styles/sessions';

export type Learnings = Array<{
    isUserLearning: boolean;
    learning: string;
}>;
export type ClosingData = Database['public']['Tables']['closings']['Row'];
export type GroupData = Database['public']['Tables']['groups']['Row'];

function Sessions() {
    const { isSafari } = useBrowserData();
    const { supabase, userId, userProfile } = useSupabase();
    const { sendEvent } = useAnalytics();
    const { imaginedFuture } = useImaginedFuture();
    const { showErrorToast } = useToast();

    const [group, setGroup] = useState<GroupData | null>(null);
    const [closings, setClosings] = useState<Array<ClosingData>>([]);
    const [groupProgramHistory, setGroupProgramHistory] =
        useState<JoinedGroupProgramHistory | null>(null);
    const [sessionDates, setSessionDates] = useState<Array<string>>([]);
    const [selectedSessionDate, setSelectedSessionDate] = useState<
        string | null
    >(null);
    const [selectedSessionNumber, setSelectedSessionNumber] = useState<
        number | null
    >(null);
    const [selectedSessionLearnings, setSelectedSessionLearnings] =
        useState<Learnings>([]);
    const [selectedSessionKudosCounts, setSelectedSessionKudosCounts] =
        useState<Map<string, number>>(new Map());
    const [isCompletingClosing, setIsCompletingClosing] =
        useState<boolean>(false);
    const [isNotificationMinimized, setIsNotificationMinimized] =
        useState<boolean>(false);

    const sessionButtonScrollRef =
        useRef() as React.MutableRefObject<HTMLButtonElement>;

    useEffect(() => {
        sessionButtonScrollRef.current?.scrollIntoView({ behavior: 'smooth' });
        // Adding group to our dependencies to scroll to the session button
        // after the group data has been retrieved
        // eslint-disable-next-line react-hooks-addons/no-unused-deps
    }, [group]);

    useEffect(() => {
        const getGroup = async () => {
            const { data, error } = await supabase.from('groups').select('*');

            if (error == null) {
                setGroup(data?.[0] ?? null);
            } else {
                showErrorToast(error.message);
            }
        };

        const getClosings = async () => {
            if (userId === '') {
                return;
            }

            const { data, error } = await supabase
                .from('closings')
                .select('*')
                .order('session_date', { ascending: false });

            if (error == null) {
                setClosings(data);
            } else {
                showErrorToast(error.message);
            }
        };

        if (userId !== '') {
            getGroup();
            getClosings();
        }

        // Adding isCompletingClosing to our dependencies to refetch after closing submission
        // eslint-disable-next-line react-hooks-addons/no-unused-deps
    }, [supabase, userId, isCompletingClosing, showErrorToast]);

    useEffect(() => {
        const getGroupProgramHistory = async () => {
            const { data, error } = await groupProgramHistoryQuery.eq(
                'id',
                group?.group_program_id ?? ''
            );

            if (error == null) {
                const joinedGroupProgramHistory: JoinedGroupProgramHistory =
                    data;
                setGroupProgramHistory(joinedGroupProgramHistory);
            } else {
                showErrorToast(error.message);
            }
        };

        if (group != null && group?.group_program_id != null) {
            getGroupProgramHistory();
        }
    }, [group, showErrorToast]);

    useEffect(() => {
        const unfilteredClosingDates = closings.map(
            (closing) => closing.session_date ?? ''
        );
        setSessionDates(
            unfilteredClosingDates.filter(
                (item, index) => unfilteredClosingDates.indexOf(item) === index
            )
        );
    }, [closings]);

    const sortedSessionDetails = useMemo(
        () => sortSessionDetails(groupProgramHistory),
        [groupProgramHistory]
    );

    const isGroupOngoing = useMemo(
        () => groupProgramHistory?.[0]?.session_details?.length === 0,
        [groupProgramHistory]
    );

    const hasCompletedPreviousClosing = useMemo(() => {
        if (group == null || group.previous_session_date == null) {
            return true;
        }

        return closings.some(
            (closing) =>
                closing.created_by === userId &&
                closing.session_date === group?.previous_session_date
        );
    }, [closings, group, userId]);

    const hasCompletedTodaysClosing = useMemo(() => {
        if (!(group?.has_session_today ?? false)) {
            return true;
        }

        return closings.some(
            (closing) =>
                closing.created_by === userId &&
                closing.session_number === group?.session_counter &&
                // Check session date as a backup in case the counter is off
                // and is repeating session numbers that already occurred
                closing.session_date === group?.next_session_date
        );
    }, [
        closings,
        group?.has_session_today,
        group?.next_session_date,
        group?.session_counter,
        userId,
    ]);

    // TODO: refactor this function to share logic with onSelectSessionNumber
    const onSelectSessionDate = useCallback(
        (sessionDate: string) => () => {
            setSelectedSessionDate(sessionDate);

            const sessionClosings = closings.filter(
                (closing) => closing.session_date === sessionDate
            );
            setSelectedSessionLearnings(
                sessionClosings.map((closing) => {
                    const parsedRawData = JSON.parse(
                        JSON.stringify(closing.raw_data ?? {})
                    );
                    return {
                        isUserLearning: userId === closing.created_by,
                        learning: parsedRawData?.learnings ?? '',
                    };
                })
            );

            const myKudosCounts = new Map();
            sessionClosings.forEach((closing) => {
                if (userId === closing.created_by) {
                    return;
                }
                const parsedRawData = JSON.parse(
                    JSON.stringify(closing.raw_data ?? {})
                );
                Object.keys(parsedRawData?.kudos ?? {})?.forEach(
                    (kudosText) => {
                        if (parsedRawData?.kudos?.[kudosText] === userId) {
                            const currentKudosCount =
                                myKudosCounts.get(kudosText);
                            if (currentKudosCount == null) {
                                myKudosCounts.set(kudosText, 1);
                            } else {
                                myKudosCounts.set(
                                    kudosText,
                                    currentKudosCount + 1
                                );
                            }
                        }
                    }
                );
            });
            setSelectedSessionKudosCounts(myKudosCounts);

            sendEvent?.('session_selected', {
                session_number: null,
                session_date: sessionDate,
                group_id: userProfile?.member_groups?.[0],
            });
        },
        [closings, sendEvent, userId, userProfile?.member_groups]
    );

    const onSelectSessionNumber = useCallback(
        (sessionNumber: number) => () => {
            setSelectedSessionNumber(sessionNumber);

            let sessionDate = null;
            const sessionClosings = closings.filter(
                (closing) => (closing.session_number ?? 0) === sessionNumber
            );
            setSelectedSessionLearnings(
                sessionClosings
                    .filter(
                        (closing) =>
                            (closing.session_number ?? 0) === sessionNumber
                    )
                    .map((closing) => {
                        sessionDate = closing.session_date;
                        const closingRawDataString = JSON.stringify(
                            closing.raw_data
                        );
                        const parsedRawData = JSON.parse(closingRawDataString);
                        return {
                            isUserLearning: userId === closing.created_by,
                            learning: parsedRawData?.learnings ?? '',
                        };
                    })
            );

            const myKudosCounts = new Map();
            sessionClosings.forEach((closing) => {
                if (userId === closing.created_by) {
                    return;
                }
                const parsedRawData = JSON.parse(
                    JSON.stringify(closing.raw_data ?? {})
                );
                Object.keys(parsedRawData?.kudos ?? {})?.forEach(
                    (kudosText) => {
                        if (parsedRawData?.kudos?.[kudosText] === userId) {
                            const currentKudosCount =
                                myKudosCounts.get(kudosText);
                            if (currentKudosCount == null) {
                                myKudosCounts.set(kudosText, 1);
                            } else {
                                myKudosCounts.set(
                                    kudosText,
                                    currentKudosCount + 1
                                );
                            }
                        }
                    }
                );
            });
            setSelectedSessionKudosCounts(myKudosCounts);

            sendEvent?.('session_selected', {
                session_number: sessionNumber,
                session_date: sessionDate,
                group_id: userProfile?.member_groups?.[0],
            });
        },
        [closings, sendEvent, userId, userProfile?.member_groups]
    );

    useEffect(() => {
        if (closings?.length > 0 && group != null) {
            if (
                (group?.has_session_today && hasCompletedTodaysClosing) ||
                (!group?.has_session_today && hasCompletedPreviousClosing)
            ) {
                if (isGroupOngoing) {
                    onSelectSessionDate(closings[0]?.session_date ?? '')();
                } else {
                    onSelectSessionNumber(
                        group?.has_session_today
                            ? group?.session_counter ?? 0
                            : (group?.session_counter ?? 1) - 1
                    )();
                }
            }
        }
    }, [
        closings,
        group,
        group?.has_session_today,
        group?.session_counter,
        hasCompletedPreviousClosing,
        hasCompletedTodaysClosing,
        isGroupOngoing,
        onSelectSessionDate,
        onSelectSessionNumber,
    ]);

    const onCompleteClosing = useCallback(() => {
        setIsCompletingClosing(true);

        sendEvent?.('session_closing_start', {
            session_number: group?.session_counter,
            group_id: userProfile?.member_groups?.[0],
        });
    }, [group?.session_counter, sendEvent, userProfile?.member_groups]);

    const onClose = useCallback(() => {
        setSelectedSessionDate(null);
        setSelectedSessionNumber(null);
        setSelectedSessionLearnings([]);
        setSelectedSessionKudosCounts(new Map());
        setIsCompletingClosing(false);
    }, []);

    const renderClosingButtonContent = useCallback(
        (sessionDateString: string) => {
            const sessionDate = new Date(sessionDateString);
            return (
                <>
                    <Buttons.OverlayMedium />
                    <Fonts.SmallText $color="orange">
                        {sessionDate.toLocaleString('en-us', {
                            month: 'short',
                        })}
                    </Fonts.SmallText>
                    <Fonts.SmallCapsText $color="orange">
                        {sessionDate.getDate()}
                    </Fonts.SmallCapsText>
                </>
            );
        },
        []
    );

    const renderSessionButtonContent = useCallback(
        (sessionNumber: number, color?: FontProps['$color']) => (
            <>
                <Buttons.OverlayMedium />
                <Fonts.SmallText
                    $color={color == null ? 'primary' : color}
                >{`Session`}</Fonts.SmallText>
                <Fonts.SmallCapsText $color={color == null ? 'primary' : color}>
                    {sessionNumber}
                </Fonts.SmallCapsText>
            </>
        ),
        []
    );

    const sessionButtons = useMemo(
        () =>
            // If there are no objectives, map over closings and display their dates
            // for users in ongoing groups to have session pins
            sortedSessionDetails.length === 0
                ? sessionDates.map((sessionDate) => (
                      <SessionButton
                          key={sessionDate}
                          onClick={onSelectSessionDate(sessionDate)}
                      >
                          {renderClosingButtonContent(sessionDate)}
                      </SessionButton>
                  ))
                : // Type check is working in development, but compiler flags on build
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  sortedSessionDetails.map((_, index: number) => {
                      if (
                          (group?.has_session_today ?? false) &&
                          index + 1 === group?.session_counter
                      ) {
                          return (
                              <CurrentSessionButton
                                  key={index + 1}
                                  onClick={onSelectSessionNumber(index + 1)}
                                  ref={sessionButtonScrollRef}
                              >
                                  {renderSessionButtonContent(
                                      index + 1,
                                      'white'
                                  )}
                              </CurrentSessionButton>
                          );
                      } else if (index + 1 >= (group?.session_counter ?? 0)) {
                          return (
                              <FutureSessionButton
                                  key={index + 1}
                                  onClick={onSelectSessionNumber(index + 1)}
                                  ref={
                                      !(group?.has_session_today ?? false) &&
                                      index + 1 === group?.session_counter
                                          ? sessionButtonScrollRef
                                          : null
                                  }
                              >
                                  {renderSessionButtonContent(index + 1)}
                              </FutureSessionButton>
                          );
                      }

                      return (
                          <SessionButton
                              key={index + 1}
                              onClick={onSelectSessionNumber(index + 1)}
                          >
                              {renderSessionButtonContent(index + 1, 'orange')}
                          </SessionButton>
                      );
                  }),
        [
            group?.has_session_today,
            group?.session_counter,
            onSelectSessionDate,
            onSelectSessionNumber,
            renderClosingButtonContent,
            renderSessionButtonContent,
            sessionDates,
            sortedSessionDetails,
        ]
    );

    const onToggleDropdown = useCallback(() => {
        if (isMobile) {
            setIsNotificationMinimized(!isNotificationMinimized);
        }
    }, [isNotificationMinimized]);

    const selectedSessionDetails = useMemo(
        () => sortedSessionDetails?.[(selectedSessionNumber ?? 1) - 1],
        [selectedSessionNumber, sortedSessionDetails]
    );

    const sortedModules = useMemo(() => {
        if (selectedSessionNumber == null && selectedSessionDate == null) {
            return [];
        }

        return sortModules(
            selectedSessionDetails?.session_details_modules ?? [],
            selectedSessionDetails?.modules ?? []
        );
    }, [
        selectedSessionDate,
        selectedSessionDetails?.modules,
        selectedSessionDetails?.session_details_modules,
        selectedSessionNumber,
    ]);

    return (
        <Background>
            <AuthenticateCouncilMemberRoute />
            <SessionsWrapper>
                {userProfile == null || imaginedFuture === '' ? (
                    <ImaginedFutureBodyText>
                        {`“Imagination is the beginning of creation. You imagine what you desire, you will what you imagine, and at last, you create what you will.” – George Bernard Shaw`}
                    </ImaginedFutureBodyText>
                ) : (
                    <>
                        <Spacing.Bumper />
                        <Fonts.Heading3>{`Imagined Future`}</Fonts.Heading3>
                        <ImaginedFutureBodyText>
                            {`“${imaginedFuture.slice(0, 180)}${
                                imaginedFuture.length < 180 ? '' : '...'
                            }”`}
                        </ImaginedFutureBodyText>
                        <Link to="/imagined-future">
                            <Fonts.BodyTextLink>{`View more`}</Fonts.BodyTextLink>
                        </Link>
                    </>
                )}
                <SessionListWrapper>
                    <SessionList>{sessionButtons}</SessionList>
                </SessionListWrapper>
            </SessionsWrapper>

            {!isSafari ||
            selectedSessionNumber != null ||
            selectedSessionDate != null
                ? createPortal(
                      <Overlays.ModalOuterWrapper
                          $isOpen={
                              selectedSessionNumber != null ||
                              selectedSessionDate != null
                          }
                      >
                          <Overlays.BackgroundOverlay
                              onClick={onClose}
                              $isOpen={
                                  selectedSessionNumber != null ||
                                  selectedSessionDate != null
                              }
                          />
                          <Overlays.ModalInnerWrapper>
                              <SessionOverview
                                  sessionNumber={selectedSessionNumber ?? 1}
                                  sessionDate={selectedSessionDate ?? ''}
                                  sessionObjectiveTitle={
                                      selectedSessionDetails?.title ?? ''
                                  }
                                  sessionDescriptions={
                                      (selectedSessionDetails?.description
                                          ?.length ?? 0) > 0
                                          ? [
                                                selectedSessionDetails?.description ??
                                                    '',
                                            ]
                                          : sortedModules?.map(
                                                (module) =>
                                                    module?.description ?? ''
                                            ) ?? []
                                  }
                                  sessionGroupLearnings={
                                      selectedSessionLearnings
                                  }
                                  sessionKudosCounts={
                                      selectedSessionKudosCounts
                                  }
                                  reviewContent={
                                      sortedModules
                                          ?.filter(
                                              (module) =>
                                                  module?.review_content != null
                                          )
                                          .map(
                                              (module) =>
                                                  module?.review_content ?? ''
                                          ) ?? []
                                  }
                                  applyContent={
                                      sortedModules
                                          ?.filter(
                                              (module) =>
                                                  module?.apply_content != null
                                          )
                                          .map(
                                              (module) =>
                                                  module?.apply_content ?? ''
                                          ) ?? []
                                  }
                                  reflectContent={
                                      sortedModules
                                          ?.filter(
                                              (module) =>
                                                  module?.reflect_content !=
                                                  null
                                          )
                                          .map(
                                              (module) =>
                                                  module?.reflect_content ?? ''
                                          ) ?? []
                                  }
                                  // Type check is working in development, but compiler flags on build
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  exercisesFrameworks={
                                      sortedModules
                                          ?.filter(
                                              (module) =>
                                                  module?.reflect_content !=
                                                  null
                                          )
                                          .map(
                                              (module) =>
                                                  module?.exercises_frameworks
                                          )
                                          .flat() ?? []
                                  }
                                  hasTopicTool={group?.has_topic_tool ?? false}
                                  onClose={onClose}
                                  isOnlyLearnings={selectedSessionDate != null}
                              />
                          </Overlays.ModalInnerWrapper>
                      </Overlays.ModalOuterWrapper>,
                      document.getElementById('app-wrapper') ?? document.body
                  )
                : null}

            {group != null &&
            !isCompletingClosing &&
            ((group?.has_session_today && !hasCompletedTodaysClosing) ||
                (!group?.has_session_today && !hasCompletedPreviousClosing)) ? (
                <CompleteClosingNotification
                    $isNotificationMinimized={isNotificationMinimized}
                >
                    <CompleteClosingNotificationSection
                        onClick={onToggleDropdown}
                    >
                        <Fonts.Heading5>
                            {!group?.has_session_today &&
                            !hasCompletedPreviousClosing
                                ? `Your previous session wrapped!`
                                : `Your session is today!`}
                        </Fonts.Heading5>
                        {isMobile ? (
                            <DropdownCaret
                                src={CaretDown}
                                $isNotificationMinimized={
                                    isNotificationMinimized
                                }
                            />
                        ) : null}
                    </CompleteClosingNotificationSection>
                    <CompleteClosingNotificationSection>
                        <SummaryWrapper>
                            <Fonts.SmallText>
                                {!group?.has_session_today &&
                                !hasCompletedPreviousClosing
                                    ? `Jot down reflections in your last session space.`
                                    : `Jot down reflections as you go in your session space.`}
                            </Fonts.SmallText>
                        </SummaryWrapper>
                    </CompleteClosingNotificationSection>
                    <CompleteClosingNotificationSection>
                        <Buttons.Primary
                            onClick={onCompleteClosing}
                            $stretch={false}
                        >
                            <Buttons.OverlayDark />
                            <Overlays.PulseIcon />
                            {`Get started`}
                        </Buttons.Primary>
                    </CompleteClosingNotificationSection>
                </CompleteClosingNotification>
            ) : null}

            {(group != null && !isSafari) || isCompletingClosing
                ? createPortal(
                      <Overlays.ModalOuterWrapper $isOpen={isCompletingClosing}>
                          <Overlays.BackgroundOverlay
                              onClick={onClose}
                              $isOpen={isCompletingClosing}
                          />
                          <Overlays.ModalInnerWrapper>
                              <SubmitClosing
                                  sessionNumber={
                                      !group?.has_session_today &&
                                      !hasCompletedPreviousClosing
                                          ? (group?.session_counter ?? 1) - 1
                                          : group?.session_counter ?? 0
                                  }
                                  sessionDate={
                                      !group?.has_session_today &&
                                      !hasCompletedPreviousClosing
                                          ? group?.previous_session_date ?? ''
                                          : group?.next_session_date ?? ''
                                  }
                                  isPreviousSessionClosing={
                                      !group?.has_session_today &&
                                      !hasCompletedPreviousClosing
                                  }
                                  isOngoing={isGroupOngoing}
                                  onClose={onClose}
                                  isVisible={isCompletingClosing}
                              />
                          </Overlays.ModalInnerWrapper>
                      </Overlays.ModalOuterWrapper>,
                      document.getElementById('app-wrapper') ?? document.body
                  )
                : null}
        </Background>
    );
}

export default Sessions;
