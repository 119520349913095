import {
    FeedbackTypeEnum,
    OPPORTUNITY_QUESTIONS,
    RESULT_QUESTIONS,
} from 'libs/constants';
import { FormStep } from 'components/SteppedForm';

import React, { useCallback, useMemo, useState } from 'react';

import { useSupabase } from 'hooks/useSupabase';
import { useToast } from 'hooks/useToast';

import { FEEDBACK_TYPES } from 'libs/constants';

import SteppedForm from 'components/SteppedForm';
import TextInput from 'components/TextInput';
import SelectInput from 'components/SelectInput';

import { Fonts, Inputs, Spacing } from 'styles/theme';

interface FeedbackToolProps {
    onClose: () => void;
}

function FeedbackTool(props: FeedbackToolProps) {
    const { onClose } = props;

    const { userId, supabase } = useSupabase();
    const { showSuccessToast, showErrorToast } = useToast();

    const [recipientName, setRecipientName] = useState<string>('');
    const [event, setEvent] = useState<string>('');
    const [eventDate, setEventDate] = useState<string>('');
    const [eventObservation, setEventObservation] = useState<string>('');
    const [type, setType] = useState<FeedbackTypeEnum | null>(null);
    const [result, setResult] = useState<string>('');
    const [resultQuestion, setResultQuestion] = useState<string>('');
    const [opportunity, setOpportunity] = useState<string>('');
    const [opportunityQuestion, setOpportunityQuestion] = useState<string>('');

    const onRecipientNameInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setRecipientName(target.value);
        },
        []
    );

    const onEventInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setEvent(target.value);
        },
        []
    );

    const onEventDateInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setEventDate(target.value);
        },
        []
    );

    const onEventObservationInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setEventObservation(target.value);
        },
        []
    );

    const onChangeType = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setType(target.value as FeedbackTypeEnum);
        },
        []
    );

    const onResultInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setResult(target.value);
        },
        []
    );

    const onChangeResultQuestion = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setResultQuestion(target.value);
        },
        []
    );

    const onOpportunityInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const target = event.target as HTMLTextAreaElement;
            setOpportunity(target.value);
        },
        []
    );

    const onChangeOpportunityQuestion = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement;
            setOpportunityQuestion(target.value);
        },
        []
    );

    const fullScript = useMemo(
        () =>
            `${
                recipientName === '' ? '_______' : recipientName
            }, I have some feedback about the ${event} on ${eventDate}.

I noticed that ${eventObservation}

As a result, ${result} ${resultQuestion}

There's an opportunity here to continue ${opportunity} ${opportunityQuestion}

Thanks for being so open to my feedback.`,
        [
            event,
            eventDate,
            eventObservation,
            opportunity,
            opportunityQuestion,
            recipientName,
            result,
            resultQuestion,
        ]
    );

    const submitFeedbackScript = useCallback(async () => {
        const { error: databaseError } = await supabase
            .from('feedback_scripts')
            .insert({
                created_by: userId,
                recipient_name: recipientName,
                event,
                event_date: eventDate,
                event_observation: eventObservation,
                type,
                result,
                result_question: resultQuestion,
                opportunity,
                opportunity_question: opportunityQuestion,
                full_script: fullScript,
            });

        if (databaseError != null) {
            showErrorToast(databaseError.message);
        }

        try {
            await navigator.clipboard.writeText(fullScript);
            showSuccessToast('Feedback script copied to clipboard!');
        } catch (error) {
            if (typeof error === 'string') {
                showErrorToast(error.toUpperCase());
            } else if (error instanceof Error) {
                showErrorToast(error.message);
            }
        }
    }, [
        event,
        eventDate,
        eventObservation,
        fullScript,
        opportunity,
        opportunityQuestion,
        recipientName,
        result,
        resultQuestion,
        showErrorToast,
        showSuccessToast,
        supabase,
        type,
        userId,
    ]);

    const formSteps: Array<FormStep> = useMemo(
        () => [
            {
                isComplete: true,
                shouldHideProgress: true,
                submitText: `Let's get started!`,
                formNode: (
                    <>
                        <Fonts.Heading1>{`The Grand’s Feedback Method`}</Fonts.Heading1>
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Fonts.BodyText>{`Giving feedback is an act of trust and confidence. When done well it can deepen your relationship with your colleague and enable your team to realize their potential.`}</Fonts.BodyText>
                        <Spacing.Bumper />
                        <Fonts.BodyText>
                            {`First, we’ll help you figure out exactly what to say by creating a script using the SBIO framework (`}
                            <Fonts.BodyTextBold as="span">{`Situation, Behavior, Impact, Opportunity`}</Fonts.BodyTextBold>
                            {`). From there we’ll help you deliver feedback in the right context.`}
                        </Fonts.BodyText>
                    </>
                ),
            },
            {
                isComplete: event !== '' && eventDate !== '',
                formNode: (
                    <>
                        <Fonts.Heading3>{`Situation`}</Fonts.Heading3>
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <TextInput
                            type="text"
                            placeholder="Recipient name (optional)"
                            value={recipientName}
                            onInput={onRecipientNameInput}
                            isError={false}
                        />
                        <Spacing.Bumper />
                        <Fonts.BodyText>
                            {`I have some feedback about the`}
                        </Fonts.BodyText>
                        <Spacing.Bumper />
                        <TextInput
                            type="text"
                            placeholder="Meeting or event name"
                            value={event}
                            onInput={onEventInput}
                            isError={false}
                        />
                        <Spacing.Bumper />
                        <Fonts.BodyText>{`that took place on`}</Fonts.BodyText>
                        <Spacing.Bumper />
                        <TextInput
                            type="text"
                            placeholder="Date"
                            value={eventDate}
                            onInput={onEventDateInput}
                            isError={false}
                        />
                    </>
                ),
            },
            {
                isComplete: eventObservation !== '' && type !== null,
                formNode: (
                    <>
                        <Fonts.Heading3>{`Behavior`}</Fonts.Heading3>
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Fonts.BodyText>{`I noticed that`}</Fonts.BodyText>
                        <Spacing.Bumper />
                        <Inputs.TextArea
                            id="eventObservation"
                            name="eventObservation"
                            placeholder="Describe a specific behavior you observed. Be objective."
                            value={eventObservation}
                            onInput={onEventObservationInput}
                        />
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Fonts.BodyText>
                            {`What type of feedback do you want to give on their behavior?`}
                        </Fonts.BodyText>
                        <Spacing.Bumper />
                        <SelectInput onInput={onChangeType} value={type ?? ''}>
                            <option value="" hidden>{`Select`}</option>
                            {FEEDBACK_TYPES.map((feedbackType) => (
                                <option key={feedbackType} value={feedbackType}>
                                    {`${feedbackType
                                        .charAt(0)
                                        .toLocaleUpperCase()}${feedbackType.slice(
                                        1
                                    )}`}
                                </option>
                            ))}
                        </SelectInput>
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Fonts.BodyQuote>{`What's the difference?`}</Fonts.BodyQuote>
                        <Spacing.Bumper />
                        <Fonts.SmallText>
                            {`Instead of "positive" or "negative," we suggest using words that better suit feedback's purpose and intention.`}
                        </Fonts.SmallText>
                        <Spacing.Bumper />
                        <Fonts.SmallText>
                            {`"Reinforcing" feedback helps someone become more aware of a productive behavior.`}
                        </Fonts.SmallText>
                        <Fonts.SmallText>
                            {`"Developmental" feedback helps someone notice an opportunity to improve a specific behavior.`}
                        </Fonts.SmallText>
                    </>
                ),
            },
            {
                isComplete: result !== '' && resultQuestion !== '',
                formNode: (
                    <>
                        <Fonts.Heading3>{`Impact`}</Fonts.Heading3>
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Fonts.BodyText>{`As a result,`}</Fonts.BodyText>
                        <Spacing.Bumper />
                        <Inputs.TextArea
                            id="result"
                            name="result"
                            placeholder="Describe result"
                            value={result}
                            onInput={onResultInput}
                        />
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Fonts.BodyText>
                            {`Select a question to include in your script:`}
                        </Fonts.BodyText>
                        <Spacing.Bumper />
                        <SelectInput
                            onInput={onChangeResultQuestion}
                            value={resultQuestion ?? ''}
                        >
                            <option value="" hidden>{`Select`}</option>
                            {RESULT_QUESTIONS.map((resultQuestionOption) => (
                                <option
                                    key={resultQuestionOption}
                                    value={resultQuestionOption}
                                >
                                    {resultQuestionOption}
                                </option>
                            ))}
                        </SelectInput>
                    </>
                ),
            },
            {
                isComplete: opportunity !== '' && opportunityQuestion !== '',
                formNode: (
                    <>
                        <Fonts.Heading3>{`Opportunity`}</Fonts.Heading3>
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Fonts.BodyText>{`There's an opportunity here to practice`}</Fonts.BodyText>
                        <Spacing.Bumper />
                        <Inputs.TextArea
                            id="opportunity"
                            name="opportunity"
                            placeholder="Suggest an opportunity"
                            value={opportunity}
                            onInput={onOpportunityInput}
                        />
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Fonts.BodyText>
                            {`Select a question to include in your script:`}
                        </Fonts.BodyText>
                        <Spacing.Bumper />
                        <SelectInput
                            onInput={onChangeOpportunityQuestion}
                            value={opportunityQuestion ?? ''}
                        >
                            <option value="" hidden>{`Select`}</option>
                            {OPPORTUNITY_QUESTIONS.map(
                                (opportunityQuestionOption) => (
                                    <option
                                        key={opportunityQuestionOption}
                                        value={opportunityQuestionOption}
                                    >
                                        {opportunityQuestionOption}
                                    </option>
                                )
                            )}
                        </SelectInput>
                    </>
                ),
            },
            {
                isComplete: true,
                submitText: 'Copy script and finish',
                formNode: (
                    <>
                        <Fonts.Heading3>{`Here's what you can say, give it a try:`}</Fonts.Heading3>
                        <Spacing.Bumper />
                        <Spacing.Bumper />
                        <Fonts.BodyText>
                            {`"${
                                recipientName === '' ? '_______' : recipientName
                            }, I have some feedback about the ${event} on ${eventDate}.`}
                        </Fonts.BodyText>
                        <Spacing.Bumper />
                        <Fonts.BodyText>{`I noticed that ${eventObservation}`}</Fonts.BodyText>
                        <Spacing.Bumper />
                        <Fonts.BodyText>
                            {`As a result, ${result} ${resultQuestion}`}
                        </Fonts.BodyText>
                        <Spacing.Bumper />
                        <Fonts.BodyText>
                            {`There's an opportunity here to continue ${opportunity} ${opportunityQuestion}`}
                        </Fonts.BodyText>
                        <Spacing.Bumper />
                        <Fonts.BodyText>
                            {`Thanks for being so open to my feedback."`}
                        </Fonts.BodyText>
                    </>
                ),
            },
        ],
        [
            event,
            eventDate,
            eventObservation,
            onChangeOpportunityQuestion,
            onChangeResultQuestion,
            onChangeType,
            onEventDateInput,
            onEventInput,
            onEventObservationInput,
            onOpportunityInput,
            onRecipientNameInput,
            onResultInput,
            opportunity,
            opportunityQuestion,
            recipientName,
            result,
            resultQuestion,
            type,
        ]
    );

    return (
        <SteppedForm
            formSteps={formSteps}
            onFinalSubmit={submitFeedbackScript}
            onClose={onClose}
        />
    );
}

export default FeedbackTool;
