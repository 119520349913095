import styled, { css, keyframes } from 'styled-components';

import OverlayTextureImage from 'assets/overlay-texture.png';

const SMALL_BREAKPOINT = 520;
const MEDIUM_BREAKPOINT = 1032;

export const breakpoints = {
    small: `${SMALL_BREAKPOINT}px`,
    medium: `${MEDIUM_BREAKPOINT}px`,
} as const;

// TODO: implement a more robust way of handling this since
// this method will not take into account window resizing
export const isMobile = window.innerWidth <= MEDIUM_BREAKPOINT;

export const colors = {
    primary1: '#264C51',
    primary1alt: '#76ACB3',
    primary2: '#E0A246',
    primary3: '#6A8385',
    primary3SemiTransparent: '#6A8385CC',
    dark1: '#08262A',
    dark2: '#AD6800',
    light: '#F5F3F0',
    black: '#000000',
    white: '#FFFFFF',
    borderDark: '#E3E6E6',
    borderDarkInput: '#A5B1B3',
    borderWhite: '#F9F9F9',
    borderWhiteBold: '#FCFCFC',
    // Transparency versions, may be used down the line
    // borderDark: '#6A838526',
    // borderDarkInput: '#6A838599',
    // borderWhite: '#FFFFFF1A',
    // borderWhiteBold: '#FFFFFF80',
    alertSuccess: '#3F9E4E',
    alertFocus: '#1577D1',
    alertRed: '#D10000',
    boxShadow: '0 2px 10px #00000033',
    flatSkyBlue: '#D8E7F0',
    flatSkyGreen: '#D7ECEF',
    flatSkyTan: '#F5EEE6',
    skyGradientTop: '#D3F0EB',
    skyGradientMiddle: '#D3E5F0',
    skyGradientBottom: '#FFFBEB',
} as const;

const PulseAnimationDark = keyframes`
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 16px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`;

const PulseAnimationLight = keyframes`
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(224, 162, 70, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 14px rgba(224, 162, 70, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(224, 162, 70, 0);
	}
`;

export interface FontProps {
    $color?:
        | 'primary'
        | 'primary-alt'
        | 'grey'
        | 'light'
        | 'white'
        | 'orange'
        | 'light-orange'
        | 'light-green'
        | 'red';
    $textAlign?: 'center' | 'left' | 'right';
}

const setFontColor = (props: FontProps) => {
    switch (props.$color) {
        case 'primary-alt':
            return css`
                color: ${colors.primary1alt};
            `;
        case 'grey':
            return css`
                color: ${colors.borderDarkInput};
            `;
        case 'light':
            return css`
                color: ${colors.light};
            `;
        case 'white':
            return css`
                color: ${colors.white};
            `;
        case 'orange':
            return css`
                color: ${colors.dark2};
            `;
        case 'light-orange':
            return css`
                color: ${colors.primary2};
            `;
        case 'light-green':
            return css`
                color: ${colors.primary3};
            `;
        case 'red':
            return css`
                color: ${colors.alertRed};
            `;
        case 'primary':
        default:
            return css`
                color: ${colors.primary1};
            `;
    }
};

const setTextAlign = (props: FontProps) =>
    css`
        text-align: ${props.$textAlign == null ? 'inherit' : props.$textAlign};
    `;

export const Fonts = {
    DisplayHeading: styled.h1<FontProps>`
        font-family: 'Gelica-Light';
        font-size: 72px;
        line-height: 72px;
        ${setFontColor};
        ${setTextAlign};
    `,
    Heading1: styled.h1<FontProps>`
        font-family: 'Gelica-Light';
        font-size: 54px;
        line-height: 60px;
        ${setFontColor};
        ${setTextAlign};
    `,
    Heading2: styled.h2<FontProps>`
        font-family: 'Gelica-Light';
        font-size: 40px;
        line-height: 48px;
        ${setFontColor};
        ${setTextAlign};
    `,
    Heading3: styled.h3<FontProps>`
        font-family: 'Gelica-Light';
        font-size: 32px;
        line-height: 40px;
        ${setFontColor};
        ${setTextAlign};
    `,
    Heading4: styled.h4<FontProps>`
        font-family: 'Gelica-Light';
        font-size: 28px;
        line-height: 32px;
        ${setFontColor};
        ${setTextAlign};
    `,
    Heading5: styled.h5<FontProps>`
        font-family: 'Gelica-Light';
        font-size: 20px;
        line-height: 26px;
        ${setFontColor};
        ${setTextAlign};
    `,
    Heading6: styled.h6<FontProps>`
        font-family: 'OpenSans-SemiBold';
        font-size: 18px;
        line-height: 30px;
        ${setFontColor};
        ${setTextAlign};
    `,
    Heading7: styled.h6<FontProps>`
        font-family: 'OpenSans-SemiBold';
        font-size: 16px;
        line-height: 24px;
        ${setFontColor};
        ${setTextAlign};
    `,
    LargeText: styled.p<FontProps>`
        font-family: 'OpenSans-Regular';
        font-size: 18px;
        line-height: 26px;
        ${setFontColor};
        ${setTextAlign};
    `,
    LargeTextLink: styled.a<FontProps>`
        font-family: 'OpenSans-Regular';
        font-size: 18px;
        line-height: 26px;
        text-decoration: underline;
        cursor: pointer;
        ${setFontColor};
        ${setTextAlign};
    `,
    BodyText: styled.p<FontProps>`
        font-family: 'OpenSans-Regular';
        font-size: 16px;
        line-height: 24px;
        ${setFontColor};
        ${setTextAlign};
    `,
    BodyTextLink: styled.a<FontProps>`
        font-family: 'OpenSans-Regular';
        font-size: 16px;
        line-height: 24px;
        text-decoration: underline;
        cursor: pointer;
        ${setFontColor};
        ${setTextAlign};
        transition: 0.2s;

        &:hover {
            opacity: 0.7;
        }
    `,
    BodyTextBold: styled.p<FontProps>`
        font-family: 'OpenSans-SemiBold';
        font-size: 16px;
        line-height: 24px;
        ${setFontColor};
        ${setTextAlign};
    `,
    BodyLink: styled.p<FontProps>`
        font-family: 'OpenSans-SemiBold';
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        ${setFontColor};
        ${setTextAlign};
        transition: 0.2s;

        &:hover {
            opacity: 0.7;
        }
    `,
    BodyQuote: styled.p<FontProps>`
        font-family: 'Gelica-LightItalic';
        font-size: 16px;
        line-height: 24px;
        ${setFontColor};
        ${setTextAlign};
    `,
    InputLabel: styled.p<FontProps>`
        font-family: 'OpenSans-Bold';
        font-size: 16px;
        line-height: 24px;
        ${setFontColor};
        ${setTextAlign};
    `,
    SmallText: styled.p<FontProps>`
        font-family: 'OpenSans-Regular';
        font-size: 14px;
        line-height: 22px;
        ${setFontColor};
        ${setTextAlign};
    `,
    SmallLink: styled.a<FontProps>`
        font-family: 'OpenSans-Regular';
        font-size: 14px;
        line-height: 22px;
        text-decoration: underline;
        cursor: pointer;
        ${setFontColor};
        ${setTextAlign};
    `,
    FinePrintText: styled.p<FontProps>`
        font-family: 'OpenSans-Regular';
        font-size: 12px;
        line-height: 20px;
        ${setFontColor};
        ${setTextAlign};
    `,
    FinePrintLink: styled.p<FontProps>`
        font-family: 'OpenSans-Regular';
        font-size: 12px;
        line-height: 20px;
        text-decoration: underline;
        cursor: pointer;
        ${setFontColor};
        ${setTextAlign};
    `,
    SmallCapsText: styled.p<FontProps>`
        font-family: 'OpticianSans';
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        ${setFontColor};
        ${setTextAlign};
    `,
} as const;

export interface OverlayProps {
    $isOpen: boolean;
}

interface ModalInnerWrapperProps {
    $shouldFillScreen?: boolean;
}

interface ModalInnerScrollerProps {
    $shouldCenterChildren?: boolean;
}

interface PulseIconProps {
    $color?: 'primary1' | 'primary2';
    $position?: 'top-right' | 'top-left-inner' | 'center-right';
    $infinite?: boolean;
}

export const Overlays = {
    ButtonHover: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;
        height: 100%;
        width: 100%;
        background-color: ${colors.black};
        opacity: 0;
        transition: 0.2s;

        &:hover,
        &:active {
            opacity: 0.2;
        }
    `,
    BackgroundOverlay: styled.div<OverlayProps>`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: transparent;
        pointer-events: none;
        transition: 0.4s;

        ${(props) => {
            switch (props.$isOpen) {
                case true:
                    return css`
                        background-color: ${colors.primary3SemiTransparent};
                        pointer-events: auto;
                    `;
                case false:
                default: {
                    return;
                }
            }
        }}
    `,
    ModalOuterWrapper: styled.div<OverlayProps>`
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        opacity: 0;
        transition: 0.4s;
        pointer-events: none;

        ${(props) => {
            switch (props.$isOpen) {
                case true: {
                    return css`
                        opacity: 1;
                        pointer-events: all;
                    `;
                }
                case false:
                default: {
                    return;
                }
            }
        }}
    `,
    TextureOverlay: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url(${OverlayTextureImage});
        background-repeat: repeat;
        background-size: 360px 360px;
        opacity: 0.015;
        pointer-events: none;
    `,
    ModalInnerWrapper: styled.div<ModalInnerWrapperProps>`
        position: absolute;
        top: 0;
        right: 0;
        background-color: ${colors.light};
        width: 50vw;
        min-width: 340px;
        max-width: 640px;
        height: 100vh;
        padding: 54px 32px 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media only screen and (max-width: ${breakpoints.small}) {
            width: 100vw;
        }

        ${(props) => {
            switch (props.$shouldFillScreen) {
                case true: {
                    return css`
                        width: 100vw;
                        max-width: none;
                    `;
                }
                case false:
                default: {
                    return;
                }
            }
        }}
    `,
    ModalInnerScroller: styled.div<ModalInnerScrollerProps>`
        height: 100%;
        width: 100%;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;

        ${(props) => {
            switch (props.$shouldCenterChildren) {
                case true: {
                    return css`
                        align-items: center;
                    `;
                }
                case false:
                default: {
                    return;
                }
            }
        }}
    `,
    PulseIcon: styled.div<PulseIconProps>`
        position: absolute;
        top: -6px;
        right: -6px;
        border-radius: 100px;
        background-color: ${colors.primary2};
        height: 12px;
        width: 12px;
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
        transform: scale(1);
        animation: ${PulseAnimationLight} 1s infinite;

        ${(props) => {
            switch (props.$color) {
                case 'primary1': {
                    return css`
                        background-color: ${colors.primary1};
                        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
                        animation: ${PulseAnimationDark} 1s infinite;
                    `;
                }
                case 'primary2':
                default: {
                    return null;
                }
            }
        }};

        ${(props) => {
            switch (props.$position) {
                case 'center-right': {
                    return css`
                        top: 12.5px;
                        right: 1px;
                    `;
                }
                case 'top-left-inner': {
                    return css`
                        top: 14px;
                        left: 14px;
                        right: default;
                    `;
                }
                case 'top-right':
                default: {
                    return null;
                }
            }
        }};

        ${(props) => {
            switch (props.$infinite) {
                case false: {
                    return css`
                        animation-iteration-count: 4;
                    `;
                }
                case true:
                default: {
                    return null;
                }
            }
        }};
    `,
} as const;

export interface PrimaryButtonProps {
    $size?: 'small' | 'standard' | 'large';
    $color?: 'primary1' | 'dark2' | 'primary3' | 'white';
    $borderColor?: 'primary1' | 'primary2' | 'primary3' | 'white';
    $variations?: 'filled' | 'outline';
    $width?: number;
    $stretch?: boolean;
    $isLoading?: boolean;
    $isDisabled?: boolean;
}

export interface PillButtonProps {
    $isSelected: boolean;
}

interface ToggleButtonProps {
    $isSelected: boolean;
}

export const Buttons = {
    OverlayDark: styled(Overlays.ButtonHover)`
        background-color: ${colors.black};

        &:hover,
        &:active {
            opacity: 0.3;
        }
    `,
    OverlayMedium: styled(Overlays.ButtonHover)`
        background-color: ${colors.black};

        &:hover,
        &:active {
            opacity: 0.1;
        }
    `,
    Primary: styled.button<PrimaryButtonProps>`
        position: relative;
        border-radius: 4px;
        border-width: 1px;
        border-style: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'OpenSans-Regular';
        color: ${colors.white};
        cursor: pointer;

        ${(props) => {
            switch (props.$size) {
                case 'small': {
                    return css`
                        font-size: 14px;
                        padding: 10px 24px;
                    `;
                }
                case 'large': {
                    return css`
                        font-size: 18px;
                        padding: 16px 36px;
                    `;
                }
                case 'standard':
                default: {
                    return css`
                        font-size: 16px;
                        padding: 14px 26px;
                    `;
                }
            }
        }};

        ${(props) => {
            switch (props.$color) {
                case 'white': {
                    return css`
                        background-color: ${colors.white};
                        color: ${colors.primary1};
                    `;
                }
                case 'dark2': {
                    return css`
                        background-color: ${colors.dark2};
                        border-color: ${colors.dark2};
                    `;
                }
                case 'primary3': {
                    return css`
                        background-color: ${colors.primary3};
                        border-color: ${colors.primary3};
                    `;
                }
                case 'primary1':
                default: {
                    return css`
                        background-color: ${colors.primary1};
                        border-color: ${colors.primary1};
                    `;
                }
            }
        }};

        ${(props) => {
            switch (props.$borderColor) {
                case 'white': {
                    return css`
                        border-color: ${colors.white};
                        border-style: solid;
                    `;
                }
                case 'primary2': {
                    return css`
                        border-color: ${colors.primary2};
                        border-style: solid;
                    `;
                }
                case 'primary3': {
                    return css`
                        border-color: ${colors.primary3};
                        border-style: solid;
                    `;
                }
                case 'primary1': {
                    return css`
                        border-color: ${colors.primary1};
                        border-style: solid;
                    `;
                }
                default: {
                    return null;
                }
            }
        }};

        ${(props) => {
            switch (props.$variations) {
                case 'outline': {
                    return css`
                        background-color: transparent;
                        color: ${colors.primary1};
                    `;
                }
                case 'filled':
                default: {
                    return;
                }
            }
        }};

        ${(props) => {
            if (props.$width != null) {
                return css`
                    width: ${props.$width}px;
                `;
            }

            switch (props.$stretch) {
                case true: {
                    return css`
                        width: 100%;
                    `;
                }
                case false:
                default: {
                    return;
                }
            }
        }};

        ${(props) => {
            switch (props.$isLoading) {
                case true: {
                    return css`
                        padding: 5px 0;
                        pointer-events: none;
                    `;
                }
                case false:
                case undefined:
                default: {
                    return;
                }
            }
        }};

        ${(props) => {
            switch (props.$isDisabled) {
                case true: {
                    return css`
                        opacity: 0.6;
                        pointer-events: none;
                        cursor: not-allowed;
                    `;
                }
                case false:
                case undefined:
                default: {
                    return;
                }
            }
        }};

        ${(props) => {
            if (props.$size === 'small' && props.$isLoading) {
                return css`
                    padding: 0;
                `;
            }
        }};
    `,
    Pill: styled.button<PillButtonProps>`
        position: relative;
        margin: 8px;
        margin-right: 12px;
        margin-left: 0;
        border: none;
        outline: none;
        border-radius: 64px;
        background-color: ${colors.borderDark};
        padding: 4px 12px;
        cursor: pointer;

        ${(props) =>
            props.$isSelected
                ? css`
                      background-color: ${colors.primary3};
                  `
                : null};
    `,
    ToggleWrapper: styled.div`
        position: relative;
        border-radius: 20px;
        background-color: rgba(160, 160, 160, 0.2);
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: fit-content;
    `,
    ToggleButton: styled.button<ToggleButtonProps>`
        border-radius: 20px;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 8px 12px;
        color: ${colors.light};
        font-size: 12px;
        letter-spacing: 0.2px;
        cursor: pointer;

        ${(props) =>
            props.$isSelected
                ? css`
                      background-color: ${colors.dark2};
                  `
                : null};
    `,
} as const;

export const Inputs = {
    Text: styled.input`
        position: relative;
        box-sizing: border-box;
        border: 1px solid ${colors.borderDark};
        border-radius: 4px;
        outline: none;
        width: 100%;
        background-color: ${colors.white};
        padding: 14px 26px;
        font-size: 16px;

        &::placeholder {
            color: ${colors.borderDarkInput};
        }
    `,
    TextArea: styled.textarea`
        box-sizing: border-box;
        border: 1px solid ${colors.borderDark};
        border-radius: 4px;
        outline: none;
        width: 100%;
        background-color: ${colors.white};
        padding: 14px 26px;
        font-family: 'OpenSans-Regular';
        font-size: 16px;
        line-height: 24px;

        &::placeholder {
            color: ${colors.borderDarkInput};
        }

        &:focus {
            border-color: ${colors.alertFocus};
        }
    `,
    CheckRadioInputWrapper: styled.div`
        margin-bottom: 6px;
        display: flex;
        align-items: center;
    `,
    RadioInputButton: styled.input`
        margin-top: 0;
        margin-right: 8px;
    `,
    Checkbox: styled.input`
        margin-right: 8px;
        width: 24px;
        height: 24px;
        cursor: pointer;

        &:checked {
            accent-color: ${colors.primary1};
        }

        @media only screen and (max-width: ${breakpoints.small}) {
            width: 64px;
        }
    `,
} as const;

export interface FormSelectProps extends FontProps {
    $fontFamily?: 'default' | 'alt';
    $size?: 'default' | 'small';
}

interface DropdownArrowProps {
    $isFlipped?: boolean;
}

export const Forms = {
    BackButtonWrapper: styled.div`
        position: absolute;
        left: 22px;
        top: 18px;
        width: 32px;
        height: 32px;
        cursor: pointer;
    `,
    CloseButtonWrapper: styled.div`
        position: absolute;
        right: 16px;
        top: 16px;
        width: 32px;
        height: 32px;
        cursor: pointer;
    `,
    FullPageOuterWrapper: styled.div`
        position: relative;
        z-index: 1;
        margin: 60px 40px;
        max-width: 600px;
        text-align: left;
        overflow: scroll;

        @media only screen and (max-width: ${breakpoints.small}) {
            margin: -12px -20px 0;
        }
    `,
    Wrapper: styled.form`
        width: 100%;
        text-align: left;

        @media only screen and (max-width: ${breakpoints.small}) {
            padding-bottom: 100px;
        }
    `,
    Heading: styled(Fonts.Heading3)`
        text-align: left;
    `,
    HeadingAlt: styled(Fonts.Heading7)`
        width: 100%;
        text-align: center;
    `,
    InputDisclaimerWrapper: styled.div`
        background-color: ${colors.borderWhiteBold};
        border-radius: 10px;
        padding: 12px;
    `,
    CheckboxWrapper: styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    `,
    SelectWrapper: styled.div<FormSelectProps>`
        background-color: ${colors.white};
        border-radius: 4px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        &:focus {
            outline: none;
        }

        ${(props) => {
            switch (props.$size) {
                case 'small': {
                    return css`
                        max-width: 360px;
                    `;
                }
                case 'default':
                default: {
                    return;
                }
            }
        }};
    `,
    Select: styled.select<FormSelectProps>`
        border-radius: 4px;
        border: none;
        background-color: transparent;
        width: 100%;
        padding: 13px 24px 12px;
        font-family: 'OpticianSans';
        font-size: 18px;
        letter-spacing: 0.1em;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        ${setFontColor}

        &:focus {
            outline: none;
        }

        ${(props) => {
            switch (props.$fontFamily) {
                case 'alt': {
                    return css`
                        padding: 12px 24px 11px;
                        font-family: 'OpenSans-Regular';
                        font-size: 16px;
                        line-height: 24px;
                    `;
                }
                case 'default':
                default: {
                    return;
                }
            }
        }};

        ${(props) => {
            switch (props.$size) {
                case 'small': {
                    return css`
                        padding: 8px 16px 7px;
                        font-family: 'OpenSans-Regular';
                        font-size: 14px;
                        line-height: 24px;
                    `;
                }
                case 'default':
                default: {
                    return;
                }
            }
        }};
    `,
    DropdownArrow: styled.img<DropdownArrowProps>`
        position: relative;
        top: 1px;
        right: 16px;
        pointer-events: none;
        transition: transform 0.2s;

        ${(props) => {
            switch (props.$isFlipped) {
                case true: {
                    return css`
                        transform: scaleY(-1);
                    `;
                }
                case false:
                default: {
                    return;
                }
            }
        }};
    `,
    ExplainerTextWrapper: styled.div`
        margin-bottom: 6px;
    `,
} as const;

interface BumperProps {
    $size?: 'small' | 'large';
}

interface DividerProps {
    $color?: 'borderDark' | 'borderDarkInput';
}

export const Spacing = {
    Bumper: styled.div<BumperProps>`
        min-height: 16px;

        ${(props) => {
            switch (props.$size) {
                case 'small': {
                    return css`
                        min-height: 8px;
                    `;
                }
                case 'large':
                default: {
                    return;
                }
            }
        }}
    `,
    Divider: styled.div<DividerProps>`
        width: 100%;
        min-height: 1px;
        background-color: ${colors.borderDark};

        ${(props) => {
            switch (props.$color) {
                case 'borderDarkInput': {
                    return css`
                        background-color: ${colors.borderDarkInput};
                    `;
                }
                case 'borderDark':
                default: {
                    return css`
                        background-color: ${colors.borderDark};
                    `;
                }
            }
        }}
    `,
};
