import React, { useCallback, useRef, useState } from 'react';
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile';

import { useSupabase } from 'hooks/useSupabase';
import { useAnalytics } from 'hooks/useAnalytics';
import { useToast } from 'hooks/useToast';

import { UnAuthenticateRoute } from 'components/AppRoutes';
import BackgroundWrapper from 'components/BackgroundWrapper';
import TextInput from 'components/TextInput';
import LoadingButton from 'components/LoadingButton';

import GrandLogo from 'assets/grand-logo-green-vert.svg';

import { colors, Fonts, Spacing } from 'styles/theme';
import { ContentInnerWrapper } from 'styles/backgroundWrapper';
import { AuthForm, BottomLink, Logo } from 'styles/auth';

function SignIn() {
    const { isSignedIn, signInWithPassword } = useSupabase();
    const { sendEvent } = useAnalytics();
    const { showErrorToast } = useToast();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSigningIn, setIsSigningIn] = useState<boolean>(false);

    const turnstileRef = useRef<TurnstileInstance>();

    const onEmailInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setEmail(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onPasswordInput = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setPassword(target.value);

            if (isError) {
                setIsError(false);
            }
        },
        [isError]
    );

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (isSigningIn) {
                return;
            }
            setIsSigningIn(true);

            if (captchaToken != null && !isSignedIn) {
                const error = await signInWithPassword?.({
                    email,
                    password,
                    captchaToken,
                });

                if (error != null) {
                    turnstileRef.current?.reset();
                    setIsError(true);
                    showErrorToast(error.message);
                } else {
                    sendEvent?.('login');
                }
            }

            setIsSigningIn(false);
        },
        [
            captchaToken,
            email,
            isSignedIn,
            isSigningIn,
            password,
            sendEvent,
            showErrorToast,
            signInWithPassword,
        ]
    );

    return (
        <>
            <UnAuthenticateRoute />

            <BackgroundWrapper>
                <ContentInnerWrapper>
                    <Logo src={GrandLogo} alt="grand-logo" />
                    <AuthForm onSubmit={onSubmit}>
                        <TextInput
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email address"
                            onInput={onEmailInput}
                            isError={isError}
                            required
                        />
                        <Spacing.Bumper />
                        <TextInput
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            onInput={onPasswordInput}
                            isError={isError}
                            required
                        />
                        <Spacing.Bumper />
                        <Turnstile
                            ref={turnstileRef}
                            siteKey="0x4AAAAAAAHdBQdhmUCPmC2X"
                            onSuccess={(token) => {
                                setCaptchaToken(token);
                            }}
                            options={{
                                theme: 'light',
                            }}
                            // Need to use inline styling as this is an external component
                            style={{
                                boxShadow: colors.boxShadow,
                            }}
                        />
                        <Spacing.Bumper />
                        <LoadingButton
                            type="submit"
                            value="Submit"
                            isLoading={isSigningIn}
                            $stretch
                        >
                            {`Sign in`}
                        </LoadingButton>
                        <Spacing.Bumper />
                        <BottomLink to="/password-reset">
                            <Fonts.SmallLink>{`Reset password`}</Fonts.SmallLink>
                        </BottomLink>
                    </AuthForm>
                </ContentInnerWrapper>
            </BackgroundWrapper>
        </>
    );
}

export default SignIn;
