import { EditProfileProps } from 'components/EditProfile';

import React, { useCallback, useMemo, useState } from 'react';

import { useSupabase } from 'hooks/useSupabase';
import { useAnalytics } from 'hooks/useAnalytics';
import { useToast } from 'hooks/useToast';

import LoadingButton from 'components/LoadingButton';

import X from 'assets/x-black.svg';

import Grandimal0 from 'assets/grandimal-0.png';
import Grandimal1 from 'assets/grandimal-1.png';
import Grandimal2 from 'assets/grandimal-2.png';
import Grandimal3 from 'assets/grandimal-3.png';
import Grandimal4 from 'assets/grandimal-4.png';
import Grandimal5 from 'assets/grandimal-5.png';
import Grandimal6 from 'assets/grandimal-6.png';
import Grandimal7 from 'assets/grandimal-7.png';
import Grandimal8 from 'assets/grandimal-8.png';
import Grandimal9 from 'assets/grandimal-9.png';
import Grandimal10 from 'assets/grandimal-10.png';
import Grandimal11 from 'assets/grandimal-11.png';
import Grandimal12 from 'assets/grandimal-12.png';
import Grandimal13 from 'assets/grandimal-13.png';
import Grandimal14 from 'assets/grandimal-14.png';
import Grandimal15 from 'assets/grandimal-15.png';

import Banner0 from 'assets/profile-banner-0.png';
import Banner1 from 'assets/profile-banner-1.png';
import Banner2 from 'assets/profile-banner-2.png';
import Banner3 from 'assets/profile-banner-3.png';
import Banner4 from 'assets/profile-banner-4.png';
import Banner5 from 'assets/profile-banner-5.png';

import { Buttons, Fonts, Forms, Overlays, Spacing } from 'styles/theme';
import { AvatarImageWrapper, AvatarImage } from 'styles/profile';
import {
    AvatarListWrapper,
    BannerImage,
    BannerListWrapper,
    BottomButtonsWrapper,
    CloseButtonWrapper,
    SelectableAvatarImageWrapper,
    SelectableBannerImageWrapper,
} from 'styles/imageSelector';

// If the AvatarList and BannerList array orders need to be modified, we will
// have to add switch functions and modify logic in the selector components
// to ensure that previous data based off of user selections is preserved

export const AvatarList = [
    Grandimal0,
    Grandimal1,
    Grandimal2,
    Grandimal3,
    Grandimal4,
    Grandimal5,
    Grandimal6,
    Grandimal7,
    Grandimal8,
    Grandimal9,
    Grandimal10,
    Grandimal11,
    Grandimal12,
    Grandimal13,
    Grandimal14,
    Grandimal15,
] as const;

export const BannerList = [
    Banner0,
    Banner1,
    Banner2,
    Banner3,
    Banner4,
    Banner5,
] as const;

interface ImageSelectorProps extends EditProfileProps {
    imageType: 'avatar' | 'banner';
}

function ImageSelector(props: ImageSelectorProps) {
    const { imageType, profile, onClose } = props;

    const { supabase, userId, getProfiles } = useSupabase();
    const { sendEvent } = useAnalytics();
    const { showSuccessToast, showErrorToast } = useToast();

    const parsedRawData = useMemo(
        () => JSON.parse(JSON.stringify(profile.raw_data ?? {})),
        [profile.raw_data]
    );

    const [imageIndex, setImageIndex] = useState<number>(
        parsedRawData?.[imageType] ?? 0
    );
    const [isUpdatingImage, setIsUpdatingImage] = useState<boolean>(false);

    const isEditingAvatar = imageType === 'avatar';
    const ImageList = isEditingAvatar ? AvatarList : BannerList;
    const ImageListWrapper = isEditingAvatar
        ? AvatarListWrapper
        : BannerListWrapper;
    const SelectableImageWrapper = isEditingAvatar
        ? SelectableAvatarImageWrapper
        : SelectableBannerImageWrapper;

    const onSubmit = useCallback(
        async (
            event:
                | React.FormEvent<HTMLFormElement>
                | React.FormEvent<HTMLButtonElement>
        ) => {
            event.preventDefault();

            if (isUpdatingImage) {
                return;
            }
            setIsUpdatingImage(true);

            const { error } = await supabase
                .from('profiles')
                .update({
                    raw_data: {
                        ...(parsedRawData ?? {}),
                        [imageType]: imageIndex,
                    },
                })
                .eq('id', userId);

            if (error == null) {
                getProfiles?.();
                onClose();
                showSuccessToast(`You've successfully updated your avatar!`);

                sendEvent?.('session_avatar_save', {
                    [imageType === 'avatar'
                        ? 'avatar_image_index'
                        : 'banner_image_index']: imageIndex,
                });
            } else {
                showErrorToast(error.message);
            }

            setIsUpdatingImage(false);
        },
        [
            getProfiles,
            imageIndex,
            imageType,
            isUpdatingImage,
            onClose,
            parsedRawData,
            sendEvent,
            showErrorToast,
            showSuccessToast,
            supabase,
            userId,
        ]
    );

    return (
        <>
            <Forms.CloseButtonWrapper onClick={onClose}>
                <img src={X} />
            </Forms.CloseButtonWrapper>
            <Overlays.ModalInnerScroller>
                <Forms.HeadingAlt>{`Choose My ${
                    isEditingAvatar ? 'Profile Avatar' : 'Header Background'
                }`}</Forms.HeadingAlt>
                <Spacing.Bumper />
                <Spacing.Bumper />
                <Forms.Wrapper onSubmit={onSubmit}>
                    <Fonts.SmallCapsText>{`Current`}</Fonts.SmallCapsText>
                    <Spacing.Bumper />
                    {isEditingAvatar ? (
                        <AvatarImageWrapper>
                            <AvatarImage
                                src={ImageList[parsedRawData?.[imageType] ?? 0]}
                            />
                        </AvatarImageWrapper>
                    ) : (
                        <>
                            <BannerImage
                                src={BannerList[parsedRawData?.banner ?? 0]}
                            />
                            <Spacing.Bumper />
                        </>
                    )}
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                    <Spacing.Divider />
                    <Spacing.Bumper />
                    <Fonts.SmallCapsText>
                        {isEditingAvatar ? `Grandimals` : `Grand Backgrounds`}
                    </Fonts.SmallCapsText>
                    <Spacing.Bumper />
                    <ImageListWrapper>
                        {ImageList.map((AvatarImage, index) => (
                            <>
                                <SelectableImageWrapper
                                    key={AvatarImage}
                                    onClick={() => setImageIndex(index)}
                                    $isSelected={imageIndex === index}
                                >
                                    <Buttons.OverlayMedium />
                                    {/* The Styled Component version of an img tag causes a
                                    filepath error when passing the image source here,
                                    so use the standard <img> tag instead */}
                                    <img
                                        src={ImageList[index]}
                                        style={{
                                            width: isEditingAvatar
                                                ? '96px'
                                                : '100%',
                                        }}
                                    />
                                </SelectableImageWrapper>
                                {isEditingAvatar ? null : <Spacing.Bumper />}
                            </>
                        ))}
                    </ImageListWrapper>
                    <Spacing.Bumper />
                    {isEditingAvatar ? <Spacing.Bumper /> : null}
                </Forms.Wrapper>
            </Overlays.ModalInnerScroller>
            <Spacing.Divider />
            <BottomButtonsWrapper>
                <LoadingButton onClick={onSubmit} isLoading={isUpdatingImage}>
                    {`Save`}
                </LoadingButton>
                <CloseButtonWrapper onClick={onClose}>
                    <Fonts.BodyTextLink>{`Close`}</Fonts.BodyTextLink>
                </CloseButtonWrapper>
            </BottomButtonsWrapper>
        </>
    );
}

export default ImageSelector;
