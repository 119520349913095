import styled, { css } from 'styled-components';

import { colors, Fonts } from 'styles/theme';

export const DropdownOuterWrapper = styled.div`
    width: 100%;
`;

export const DropdownInnerWrapper = styled.div`
    border: 1px solid lightgrey;
    border-radius: 4px;
    background-color: ${colors.white};
    width: 100%;
    padding: 6px 12px;
    transition: box-shadow 0.2s;
`;

export const ToggleDropdownTarget = styled.div`
    width: 100%;
    padding: 6px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
`;

export const SelectedOptionText = styled(Fonts.Heading5)`
    padding-right: 24px;
`;

export const DropdownArrowWrapper = styled.div`
    position: relative;
    right: -12px;
    display: flex;
    align-items: center;
`;

export const SectionWrapper = styled.div`
    border-radius: 8px;
    background-color: ${colors.light};
    padding: 16px;
    margin: 6px 4px 12px;
`;

export const SectionHeader = styled(Fonts.FinePrintText)`
    margin-bottom: 12px;
`;

interface OptionWrapperProps {
    $isDisabled: boolean;
}

export const OptionWrapper = styled.div<OptionWrapperProps>`
    margin: 16px 0;
    cursor: pointer;
    transition: opacity 0.2s;

    &:last-of-type {
        margin-bottom: 0;
    }

    &:hover {
        opacity: 0.6;
    }

    ${(props) => {
        switch (props.$isDisabled) {
            case true: {
                return css`
                    pointer-events: none;
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }};
`;
