import styled from 'styled-components';
import { breakpoints, colors, Fonts } from './theme';

export const MainWrapper = styled.div`
    margin: 32px;
    border-radius: 4px;
    background-color: ${colors.white};
    width: 800px;
    padding: 28px 32px;

    @media only screen and (max-width: ${breakpoints.small}) {
        margin: 16px;
        width: 100%;
    }
`;

export const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

export const StatListWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: ${breakpoints.small}) {
        flex-direction: column;
    }
`;

export const StatWrapper = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;

    @media only screen and (max-width: ${breakpoints.small}) {
        margin-top: 40px;
        width: 100%;
        flex-direction: column;

        &:first-of-type {
            margin-top: 8px;
        }
    }
`;

export const ReportTextResponsesWrapper = styled.div`
    width: 100%;
    text-align: left;
`;

export const ItalicText = styled(Fonts.BodyText)`
    font-style: italic;
`;
