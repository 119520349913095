import React from 'react';
import { useParams } from 'react-router-dom';

import MarkdownWrapper from 'components/MarkdownWrapper';

import { Fonts } from 'styles/theme';

function MarkdownRouteHandler() {
    const { markdownString } = useParams();

    return markdownString == null || markdownString === '' ? (
        <Fonts.BodyText>{`No markdown string provided`}</Fonts.BodyText>
    ) : (
        <MarkdownWrapper markdownString={markdownString} />
    );
}

export default MarkdownRouteHandler;
