import { ReactNode } from 'react';

import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import LoadingButton from 'components/LoadingButton';

import X from 'assets/x-black.svg';

import { Fonts, Forms, Overlays, Spacing } from 'styles/theme';
import {
    FormStepInnerWrapper,
    FormStepOuterWrapper,
    ProgressBar,
    ProgressBarWrapper,
    SteppedFormContentWrapper,
} from 'styles/steppedForm';

export interface FormStep {
    formNode: ReactNode;
    isComplete: boolean;
    submitText?: string;
    shouldHideProgress?: boolean;
    onSubmitStep?: () => void;
}

interface SteppedFormProps {
    formSteps: Array<FormStep>;
    onClose: () => void;
    onFinalSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
    isLoadingSubmission?: boolean;
}

function SteppedForm(props: SteppedFormProps) {
    const { formSteps, onClose, onFinalSubmit, isLoadingSubmission } = props;

    const [currentStep, setCurrentStep] = useState<number>(0);

    const scrollRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    useEffect(() => {
        scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });

        // Scroll to top of form each time the step changes
        // eslint-disable-next-line react-hooks-addons/no-unused-deps
    }, [currentStep]);

    const progressPercentage = useMemo(() => {
        if (currentStep === 0) {
            return 5;
        } else if (currentStep === formSteps.length - 1) {
            return 95;
        }

        return (currentStep / (formSteps.length - 1)) * 100;
    }, [currentStep, formSteps.length]);

    const handlePressBack = useCallback(() => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    }, [currentStep]);

    const handleSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (currentStep === formSteps.length - 1) {
                formSteps[currentStep]?.onSubmitStep?.();
                onFinalSubmit?.(event);
                onClose();
                return;
            }

            if (formSteps[currentStep]?.isComplete) {
                formSteps[currentStep]?.onSubmitStep?.();
                setCurrentStep(currentStep + 1);
            }
        },
        [currentStep, formSteps, onClose, onFinalSubmit]
    );

    return (
        <Overlays.ModalInnerScroller ref={scrollRef} $shouldCenterChildren>
            {formSteps[currentStep]?.shouldHideProgress ||
            currentStep === 0 ? null : (
                <Forms.BackButtonWrapper>
                    <Fonts.SmallLink
                        onClick={handlePressBack}
                    >{`Back`}</Fonts.SmallLink>
                </Forms.BackButtonWrapper>
            )}
            {formSteps[currentStep]?.shouldHideProgress ? null : (
                <ProgressBarWrapper>
                    <ProgressBar
                        style={{
                            width: `${progressPercentage}%`,
                        }}
                    />
                </ProgressBarWrapper>
            )}
            <Forms.CloseButtonWrapper onClick={onClose}>
                <img src={X} />
            </Forms.CloseButtonWrapper>

            <SteppedFormContentWrapper>
                <Spacing.Bumper />
                <Spacing.Bumper />
                <Forms.Wrapper onSubmit={handleSubmit}>
                    <FormStepOuterWrapper>
                        {formSteps.map((formStep, index) => (
                            <FormStepInnerWrapper
                                key={index}
                                $isPreviousStep={index < currentStep}
                                $isNextStep={index > currentStep}
                            >
                                {formStep.formNode}
                                <Spacing.Bumper />
                                <Spacing.Bumper />
                                <LoadingButton
                                    type="submit"
                                    value="Submit"
                                    isLoading={isLoadingSubmission ?? false}
                                    isDisabled={
                                        !formSteps[currentStep]?.isComplete
                                    }
                                >
                                    {formSteps[currentStep]?.submitText != null
                                        ? formSteps[currentStep]?.submitText
                                        : currentStep === formSteps.length - 1
                                        ? `Submit`
                                        : `Next`}
                                </LoadingButton>
                            </FormStepInnerWrapper>
                        ))}
                    </FormStepOuterWrapper>
                </Forms.Wrapper>
            </SteppedFormContentWrapper>
        </Overlays.ModalInnerScroller>
    );
}

export default SteppedForm;
