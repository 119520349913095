import { InputHTMLAttributes } from 'react';

import React, { useCallback, useMemo, useState } from 'react';

import { Inputs, colors } from 'styles/theme';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
    isError: boolean;
}

function TextInput(props: TextInputProps) {
    const { isError } = props;

    const [isFocused, setIsFocused] = useState<boolean>(false);

    const onFocus = useCallback(() => {
        if (!isFocused) {
            setIsFocused(true);
        }
    }, [isFocused]);

    const onBlur = useCallback(() => {
        if (isFocused) {
            setIsFocused(false);
        }
    }, [isFocused]);

    const borderColor = useMemo(() => {
        if (isError) {
            return colors.alertRed;
        } else if (isFocused) {
            return colors.alertFocus;
        } else {
            colors.borderDark;
        }
    }, [isError, isFocused]);

    return (
        <Inputs.Text
            {...props}
            onFocus={onFocus}
            onBlur={onBlur}
            style={{ borderColor: borderColor }}
        />
    );
}

export default TextInput;
