import React, { useCallback } from 'react';

import { RATING_RESPONSES } from 'libs/constants';

import RequiredAsterisk from 'components/RequiredAsterisk';
import SelectInput from 'components/SelectInput';

import { Fonts, Inputs, Overlays, Spacing } from 'styles/theme';
import { PeriodicSurveyWrapper } from 'styles/periodicSurvey';

export interface PeriodicSurveyData {
    overall_rating?: string;
    overall_improvement_feedback?: string;
    coach_rating?: string;
    coach_improvement_feedback?: string;
    positive_feedback?: string;
    negative_feedback?: string;
}

interface PeriodicSurveyProps {
    periodicSurvey: PeriodicSurveyData;
    setPeriodicSurvey: (periodicSurvey: PeriodicSurveyData) => void;
    isVisible: boolean;
}

function PeriodicSurvey(props: PeriodicSurveyProps) {
    const { periodicSurvey, setPeriodicSurvey, isVisible } = props;

    const onOverallRatingChange = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const newPeriodicSurvey = { ...periodicSurvey };
            const target = event.target as HTMLSelectElement;
            newPeriodicSurvey.overall_rating = target.value;
            setPeriodicSurvey(newPeriodicSurvey);
        },
        [periodicSurvey, setPeriodicSurvey]
    );

    const onOverallFeedbackInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const newPeriodicSurvey = { ...periodicSurvey };
            const target = event.target as HTMLTextAreaElement;
            newPeriodicSurvey.overall_improvement_feedback = target.value;
            setPeriodicSurvey(newPeriodicSurvey);
        },
        [periodicSurvey, setPeriodicSurvey]
    );

    const onCoachRatingChange = useCallback(
        (event: React.FormEvent<HTMLSelectElement>) => {
            const newPeriodicSurvey = { ...periodicSurvey };
            const target = event.target as HTMLSelectElement;
            newPeriodicSurvey.coach_rating = target.value;
            setPeriodicSurvey(newPeriodicSurvey);
        },
        [periodicSurvey, setPeriodicSurvey]
    );

    const onCoachFeedbackInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const newPeriodicSurvey = { ...periodicSurvey };
            const target = event.target as HTMLTextAreaElement;
            newPeriodicSurvey.coach_improvement_feedback = target.value;
            setPeriodicSurvey(newPeriodicSurvey);
        },
        [periodicSurvey, setPeriodicSurvey]
    );

    const onPositiveFeedbackInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const newPeriodicSurvey = { ...periodicSurvey };
            const target = event.target as HTMLTextAreaElement;
            newPeriodicSurvey.positive_feedback = target.value;
            setPeriodicSurvey(newPeriodicSurvey);
        },
        [periodicSurvey, setPeriodicSurvey]
    );

    const onNegativeFeedbackInput = useCallback(
        (event: React.FormEvent<HTMLTextAreaElement>) => {
            const newPeriodicSurvey = { ...periodicSurvey };
            const target = event.target as HTMLTextAreaElement;
            newPeriodicSurvey.negative_feedback = target.value;
            setPeriodicSurvey(newPeriodicSurvey);
        },
        [periodicSurvey, setPeriodicSurvey]
    );

    return (
        <PeriodicSurveyWrapper>
            {isVisible ? (
                <Overlays.PulseIcon
                    $position="top-left-inner"
                    $infinite={false}
                />
            ) : null}
            <Fonts.Heading3 $color="orange">
                {`Bi-Monthly Member Survey`}
            </Fonts.Heading3>
            <Spacing.Bumper />
            <Fonts.SmallText>
                {`Tell us about your overall coaching experience thus far, so we can learn and improve. Responses will be shared anonymously with your coach.`}
            </Fonts.SmallText>
            <Spacing.Bumper />
            <Spacing.Bumper />
            <Fonts.InputLabel>
                {`What’s your overall rating for the group coaching experience?`}
                <RequiredAsterisk />
            </Fonts.InputLabel>
            <Spacing.Bumper />
            <SelectInput
                onInput={onOverallRatingChange}
                value={periodicSurvey.overall_rating}
                required
            >
                <option value="" label="Select" hidden />
                {RATING_RESPONSES.map((overallRating) => (
                    <option key={overallRating} value={overallRating}>
                        {overallRating}
                    </option>
                ))}
            </SelectInput>
            <Spacing.Bumper />
            {RATING_RESPONSES.slice(2, 5).includes(
                periodicSurvey.overall_rating as (typeof RATING_RESPONSES)[number]
            ) ? (
                <>
                    <Spacing.Bumper />
                    <Fonts.InputLabel>
                        {`What could improve this rating?`}
                        <RequiredAsterisk />
                    </Fonts.InputLabel>
                    <Spacing.Bumper />
                    <Inputs.TextArea
                        id="overallFeedback"
                        name="overallFeedback"
                        value={
                            periodicSurvey.overall_improvement_feedback ?? ''
                        }
                        onInput={onOverallFeedbackInput}
                        required
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                </>
            ) : null}
            <Spacing.Bumper />
            <Spacing.Divider />
            <Spacing.Bumper />
            <Spacing.Bumper />
            <Fonts.InputLabel>
                {`What’s your overall satisfaction rating with your coach?`}
                <RequiredAsterisk />
            </Fonts.InputLabel>
            <Spacing.Bumper />
            <SelectInput
                onInput={onCoachRatingChange}
                value={periodicSurvey.coach_rating}
                required
            >
                <option value="" label="Select" hidden />
                {RATING_RESPONSES.map((coachRating) => (
                    <option key={coachRating} value={coachRating}>
                        {coachRating}
                    </option>
                ))}
            </SelectInput>
            <Spacing.Bumper />
            {RATING_RESPONSES.slice(2, 5).includes(
                periodicSurvey.coach_rating as (typeof RATING_RESPONSES)[number]
            ) ? (
                <>
                    <Spacing.Bumper />
                    <Fonts.InputLabel>
                        {`Share feedback on what your coach should continue or improve.`}
                    </Fonts.InputLabel>
                    <Spacing.Bumper />
                    <Inputs.TextArea
                        id="coachFeedback"
                        name="coachFeedback"
                        value={periodicSurvey.coach_improvement_feedback ?? ''}
                        onInput={onCoachFeedbackInput}
                    />
                    <Spacing.Bumper />
                    <Spacing.Bumper />
                </>
            ) : null}
            <Spacing.Bumper />
            <Spacing.Divider />
            <Spacing.Bumper />
            <Spacing.Bumper />
            <Fonts.InputLabel>
                {`Share specific feedback for what’s worked well overall.`}
            </Fonts.InputLabel>
            <Spacing.Bumper />
            <Inputs.TextArea
                id="positiveFeedback"
                name="positiveFeedback"
                value={periodicSurvey.positive_feedback ?? ''}
                onInput={onPositiveFeedbackInput}
            />
            <Spacing.Bumper />
            <Spacing.Bumper />
            <Spacing.Bumper />
            <Spacing.Divider />
            <Spacing.Bumper />
            <Spacing.Bumper />
            <Fonts.InputLabel>
                {`Share specific feedback for what could be improved overall.`}
            </Fonts.InputLabel>
            <Spacing.Bumper />
            <Inputs.TextArea
                id="negativeFeedback"
                name="negativeFeedback"
                value={periodicSurvey.negative_feedback ?? ''}
                onInput={onNegativeFeedbackInput}
            />
        </PeriodicSurveyWrapper>
    );
}

export default PeriodicSurvey;
