import styled from 'styled-components';

import { breakpoints, colors } from 'styles/theme';

export const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    background: linear-gradient(${colors.flatSkyGreen}, ${colors.flatSkyBlue});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    overflow: hidden;
`;

export const Clouds = styled.img`
    position: fixed;
    bottom: -120px;
    width: 140%;
    min-width: 600px;
    overflow: hidden;
`;

export const ProfileWrapper = styled.div`
    position: absolute;
    background-color: ${colors.light};
    height: 100%;
    width: 70%;
    max-width: 800px;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    overflow: scroll;
    pointer-events: all;

    @media only screen and (max-width: ${breakpoints.small}) {
        width: 85%;
    }
`;

export const Banner = styled.img`
    border: 1px solid ${colors.borderDark};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
    max-height: 280px;
    object-fit: cover;
    object-position: center;
`;
