import styled from 'styled-components';

import { Fonts, breakpoints, colors } from 'styles/theme';

export const MainWrapper = styled.div`
    height: 100%;

    @media only screen and (max-width: ${breakpoints.medium}) {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: scroll;
    }

    @media only screen and (max-width: ${breakpoints.small}) {
        margin: 0 -20px;
    }
`;

export const AuthFormWrapper = styled.div`
    position: relative;
    right: 370px;

    @media only screen and (max-width: ${breakpoints.medium}) {
        position: absolute;
        right: auto;
    }
`;

export const ScrollingContentWrapper = styled.div`
    position: fixed;
    right: 100px;
    height: 100vh;
    width: 540px;
    overflow: scroll;

    @media only screen and (max-width: ${breakpoints.medium}) {
        position: static;
        margin-top: 125vh;
        height: auto;
        width: 100%;
        overflow: hidden;
    }
`;

export const ScrollingContentSection = styled.div`
    height: 100vh;
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;

    @media only screen and (max-width: ${breakpoints.medium}) {
        margin-top: 140px;
        max-width: 440px;

        &:first-of-type {
            margin-top: 0;
            height: auto;
        }
        &:last-of-type {
            margin-top: 160px;
            margin-bottom: 48px;
        }
    }

    @media only screen and (max-width: ${breakpoints.small}) {
        margin-top: 90px;
        max-width: 440px;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-top: 110px;
            margin-bottom: 48px;
        }
    }
`;

export const ScrollingContentMainHeader = styled(Fonts.Heading1)`
    width: 100%;
`;

export const ScrollingContentSectionHeader = styled(Fonts.SmallCapsText)`
    width: 100%;
`;

export const ScrollingContentSectionBodyText = styled(Fonts.BodyText)`
    margin-top: 4px;
    width: 100%;
`;

export const ScrollingContentImage = styled.img`
    width: calc(100% - 200px);
    margin-right: 48px;

    @media only screen and (max-width: ${breakpoints.medium}) {
        width: calc(100% - 20px);
    }
`;

export const ScrollCTAWrapper = styled.div`
    position: fixed;
    bottom: 0;
    z-index: 100;
    background: linear-gradient(transparent, ${colors.white}, ${colors.white});
    width: 100vw;
    height: 100px;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
`;

export const ScrollCTA = styled.div`
    position: absolute;
    bottom: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: ${breakpoints.medium}) {
        bottom: 10px;
    }
`;

export const ScrollCTACaret = styled.img`
    margin-top: 16px;
    width: 16px;

    @media only screen and (max-width: ${breakpoints.medium}) {
        margin-top: 4px;
    }
`;
