import { Database } from 'libs/supabaseTypes';

import React, { useEffect, useState } from 'react';

import { useSupabase } from 'hooks/useSupabase';
import { useToast } from 'hooks/useToast';

import { AuthenticateCouncilMemberRoute } from 'components/AppRoutes';

import OrangeMountainsBackgroundImage from 'assets/background-orange-mountains.png';

import { Fonts, Overlays } from 'styles/theme';
import {
    ClosingSessionNumber,
    ClosingSessionNumberWrapper,
    ClosingTextWrapper,
    ClosingWrapper,
    ClosingsList,
    ClosingsListWrapper,
    ClosingsWrapper,
    FeedbackPrompt,
    FeedbackResponse,
    FeedbackWrapper,
    Heading,
    LearningsText,
    MainWrapper,
    OrangeMountainsLandscape,
} from 'styles/closings';

type ClosingData = Database['public']['Tables']['closings']['Row'];

function Closings() {
    const { supabase, userId } = useSupabase();
    const { showErrorToast } = useToast();

    const [closings, setClosings] = useState<Array<ClosingData>>([]);

    useEffect(() => {
        const getClosings = async () => {
            const { data, error } = await supabase
                .from('closings')
                .select('*')
                .eq('created_by', userId)
                .order('session_date', { ascending: false });

            if (error == null) {
                setClosings(data);
            } else {
                showErrorToast(error.message);
            }
        };

        if (userId !== '') {
            getClosings();
        }
    }, [showErrorToast, supabase, userId]);

    return (
        <MainWrapper>
            <AuthenticateCouncilMemberRoute />
            <Overlays.TextureOverlay />
            <OrangeMountainsLandscape src={OrangeMountainsBackgroundImage} />
            <ClosingsWrapper>
                <Heading>{`Session Closings`}</Heading>
                <Fonts.LargeText>
                    {`All of your session learnings and feedback are`}
                </Fonts.LargeText>
                <Fonts.LargeText>{`saved here.`}</Fonts.LargeText>
                <ClosingsListWrapper>
                    {closings.length > 0 ? (
                        <ClosingsList>
                            {closings.map((closing) => {
                                let closingSessionNumber = '';
                                if (closing.session_number == null) {
                                    closingSessionNumber = '--';
                                } else if (closing.session_number < 10) {
                                    closingSessionNumber = `0${closing.session_number}`;
                                } else {
                                    closingSessionNumber =
                                        closing.session_number.toString();
                                }
                                const sessionDate = new Date(
                                    closing.session_date ?? ''
                                );
                                const sessionDateString = `${sessionDate.toLocaleString(
                                    'en-us',
                                    {
                                        month: 'short',
                                    }
                                )} ${sessionDate.getDate()}, ${sessionDate.getFullYear()}`;

                                const parsedClosingData = JSON.parse(
                                    JSON.stringify(closing.raw_data ?? {})
                                );

                                return (
                                    <ClosingWrapper key={closing.id}>
                                        <ClosingSessionNumberWrapper>
                                            <ClosingSessionNumber>
                                                {closingSessionNumber}
                                            </ClosingSessionNumber>
                                        </ClosingSessionNumberWrapper>
                                        <ClosingTextWrapper>
                                            <Fonts.SmallCapsText>
                                                {sessionDateString}
                                            </Fonts.SmallCapsText>
                                            <LearningsText>
                                                {parsedClosingData?.learnings ??
                                                    ''}
                                            </LearningsText>
                                            <Fonts.Heading7>{`Session and product feedback:`}</Fonts.Heading7>
                                            <FeedbackWrapper>
                                                <FeedbackPrompt>
                                                    {`Your response to the statement “I feel that this session was a great use of my time” was: `}
                                                </FeedbackPrompt>
                                                <FeedbackResponse>
                                                    {`“${
                                                        parsedClosingData?.exit ??
                                                        ''
                                                    }”`}
                                                </FeedbackResponse>
                                            </FeedbackWrapper>

                                            {parsedClosingData?.other == null ||
                                            parsedClosingData?.other ===
                                                '' ? null : (
                                                <FeedbackWrapper>
                                                    <FeedbackPrompt>
                                                        {`There was something else you wanted to share: `}
                                                    </FeedbackPrompt>
                                                    <FeedbackResponse>
                                                        {`“${parsedClosingData?.other}”`}
                                                    </FeedbackResponse>
                                                </FeedbackWrapper>
                                            )}
                                        </ClosingTextWrapper>
                                    </ClosingWrapper>
                                );
                            })}
                        </ClosingsList>
                    ) : (
                        <Fonts.Heading4>
                            {`When you finish your first session, your closing will be displayed here!`}
                        </Fonts.Heading4>
                    )}
                </ClosingsListWrapper>
            </ClosingsWrapper>
        </MainWrapper>
    );
}

export default Closings;
