import { JoinedGroupProgramHistory } from 'queries/groups';
import { ClosingData, GroupData } from 'components/Sessions';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { groupProgramHistoryQuery } from 'queries/groups';

import { useSupabase } from 'hooks/useSupabase';
import { useToast } from 'hooks/useToast';
import { useBrowserData } from 'hooks/useBrowserData';

import SubmitClosing from 'components/SubmitClosing';

import X from 'assets/x-black.svg';

import { Buttons, Fonts, Overlays } from 'styles/theme';
import {
    Banner,
    ButtonWrapper,
    CloseIcon,
    LeftContentWrapper,
} from 'styles/sessionClosingBanner';

function SessionClosingBanner() {
    const { supabase, userId } = useSupabase();
    const { showErrorToast } = useToast();
    const { isSafari } = useBrowserData();

    const [isDismissed, setIsDismissed] = useState<boolean>(false);
    const [group, setGroup] = useState<GroupData | null>(null);
    const [closings, setClosings] = useState<Array<ClosingData>>([]);
    const [groupProgramHistory, setGroupProgramHistory] =
        useState<JoinedGroupProgramHistory | null>(null);
    const [isCompletingClosing, setIsCompletingClosing] =
        useState<boolean>(false);

    useEffect(() => {
        const getGroup = async () => {
            const { data, error } = await supabase.from('groups').select('*');
            if (error == null) {
                setGroup(data?.[0] ?? null);
            } else {
                showErrorToast(error.message);
            }
        };

        const getClosings = async () => {
            if (userId === '') {
                return;
            }
            const { data, error } = await supabase
                .from('closings')
                .select('*')
                .order('session_date', { ascending: false });
            if (error == null) {
                setClosings(data);
            } else {
                showErrorToast(error.message);
            }
        };

        if (userId !== '') {
            getGroup();
            getClosings();
        }
        // Adding isCompletingClosing to our dependencies to refetch after closing submission
        // eslint-disable-next-line react-hooks-addons/no-unused-deps
    }, [supabase, userId, isCompletingClosing, showErrorToast]);

    useEffect(() => {
        const getSelectedGroupProgramHistory = async () => {
            const { data, error } = await groupProgramHistoryQuery.eq(
                'id',
                group?.group_program_id ?? ''
            );

            if (error == null) {
                const joinedGroupProgramHistory: JoinedGroupProgramHistory =
                    data;
                setGroupProgramHistory(joinedGroupProgramHistory);
            } else {
                showErrorToast(error.message);
            }
        };

        if (group == null || group.group_program_id == null) {
            setGroupProgramHistory(null);
        } else {
            getSelectedGroupProgramHistory();
        }
    }, [group, showErrorToast]);

    const isGroupOngoing = useMemo(
        () => (groupProgramHistory?.[0]?.session_details ?? []).length === 0,
        [groupProgramHistory]
    );

    const hasCompletedPreviousClosing = useMemo(() => {
        if (group == null || group.previous_session_date == null) {
            return true;
        }

        return closings.some(
            (closing) =>
                closing.created_by === userId &&
                closing.session_date === group?.previous_session_date
        );
    }, [closings, group, userId]);

    const hasCompletedTodaysClosing = useMemo(() => {
        if (!(group?.has_session_today ?? false)) {
            return true;
        }

        return closings.some(
            (closing) =>
                closing.created_by === userId &&
                closing.session_number === group?.session_counter &&
                // Check session date as a backup in case the counter is off
                // and is repeating session numbers that already occurred
                closing.session_date === group?.next_session_date
        );
    }, [
        closings,
        group?.has_session_today,
        group?.next_session_date,
        group?.session_counter,
        userId,
    ]);

    const onCompleteClosing = useCallback(() => {
        setIsCompletingClosing(true);
    }, []);

    const onClose = useCallback(() => {
        setIsCompletingClosing(false);
    }, []);

    const onDismiss = useCallback(() => {
        setIsDismissed(true);
    }, []);

    return group != null &&
        !isDismissed &&
        ((group?.has_session_today && !hasCompletedTodaysClosing) ||
            (!group?.has_session_today && !hasCompletedPreviousClosing)) ? (
        <>
            <Banner>
                <LeftContentWrapper>
                    <Fonts.Heading5>
                        {!group?.has_session_today &&
                        !hasCompletedPreviousClosing
                            ? `Your previous session wrapped!`
                            : `Your session is today!`}
                    </Fonts.Heading5>
                    <ButtonWrapper>
                        <Overlays.PulseIcon />
                        <Buttons.Primary
                            onClick={onCompleteClosing}
                            $stretch={false}
                        >
                            <Buttons.OverlayDark />
                            {`Open`}
                        </Buttons.Primary>
                    </ButtonWrapper>
                </LeftContentWrapper>
                <CloseIcon src={X} onClick={onDismiss} />
            </Banner>

            {(group != null && !isSafari) || isCompletingClosing
                ? createPortal(
                      <Overlays.ModalOuterWrapper $isOpen={isCompletingClosing}>
                          <Overlays.BackgroundOverlay
                              onClick={onClose}
                              $isOpen={isCompletingClosing}
                          />
                          <Overlays.ModalInnerWrapper>
                              <SubmitClosing
                                  sessionNumber={
                                      !group?.has_session_today &&
                                      !hasCompletedPreviousClosing
                                          ? (group?.session_counter ?? 1) - 1
                                          : group?.session_counter ?? 0
                                  }
                                  sessionDate={
                                      !group?.has_session_today &&
                                      !hasCompletedPreviousClosing
                                          ? group?.previous_session_date ?? ''
                                          : group?.next_session_date ?? ''
                                  }
                                  isPreviousSessionClosing={
                                      !group?.has_session_today &&
                                      !hasCompletedPreviousClosing
                                  }
                                  isOngoing={isGroupOngoing}
                                  onClose={onClose}
                                  isVisible={isCompletingClosing}
                              />
                          </Overlays.ModalInnerWrapper>
                      </Overlays.ModalOuterWrapper>,
                      document.getElementById('app-wrapper') ?? document.body
                  )
                : null}
        </>
    ) : null;
}
export default SessionClosingBanner;
