import styled from 'styled-components';

import { colors } from 'styles/theme';

export const AppWrapper = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${colors.light};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'OpenSans-Regular';
`;
