import { Database } from 'libs/supabaseTypes';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { useBrowserData } from 'hooks/useBrowserData';
import { useSupabase } from 'hooks/useSupabase';
import { useAnalytics } from 'hooks/useAnalytics';
import { useToast } from 'hooks/useToast';

import { getCurrentAndNextPeriod } from 'libs/utils';

import { AuthenticateCouncilMemberRoute } from 'components/AppRoutes';
import AssessmentSubtotals from 'components/AssessmentSubtotals';
import SubmitAssessment from 'components/SubmitAssessment';
import Reflection from 'components/Reflection';

import BlueMountainsBackgroundImage from 'assets/background-blue-mountains.png';
import RetroBear from 'assets/retro-bear.png';
import RetroBearInactive from 'assets/retro-bear-inactive.png';
import RetroDeer from 'assets/retro-deer.png';
import RetroDeerInactive from 'assets/retro-deer-inactive.png';
import RetroHawk from 'assets/retro-hawk.png';
import RetroHawkInactive from 'assets/retro-hawk-inactive.png';
import RetroOtter from 'assets/retro-otter.png';
import RetroOtterInactive from 'assets/retro-otter-inactive.png';
import Check from 'assets/check.svg';
import Lock from 'assets/lock.svg';

import { Fonts, Overlays, Spacing } from 'styles/theme';
import {
    BlueMountainsLandscape,
    Heading,
    MainWrapper,
    IndicatorIcon,
    RetrosList,
    RetrosListWrapper,
    RetroTextWrapper,
    RetroUpperWrapper,
    RetrosWrapper,
    RetroWrapper,
    SubHeading,
} from 'styles/retrospectives';

export type RetroData = Database['public']['Tables']['retros']['Row'];

function Retrospectives() {
    const { isSafari } = useBrowserData();
    const { supabase } = useSupabase();
    const { sendEvent } = useAnalytics();
    const { showErrorToast } = useToast();

    const [retros, setRetros] = useState<Array<RetroData>>([]);
    const [isBlankRetroSelected, setIsBlankRetroSelected] =
        useState<boolean>(false);
    const [selectedRetro, setSelectedRetro] = useState<RetroData | null>(null);
    const [hasCompletedCurrentRetro, setHasCompletedCurrentRetro] =
        useState<boolean>(false);

    const { currentPeriod, nextPeriod } = useMemo(
        () => getCurrentAndNextPeriod(),
        []
    );

    useEffect(() => {
        const getRetros = async () => {
            const { data, error } = await supabase
                .from('retros')
                .select('*')
                .order('period', { ascending: false });

            if (error == null) {
                setRetros(data);

                if (data.some((retro) => retro.period === currentPeriod)) {
                    setHasCompletedCurrentRetro(true);
                }
            } else {
                showErrorToast(error.message);
            }
        };
        getRetros();
        // Adding isBlankRetroSelected and selectedRetro to our dependencies so we force a refetch
        // eslint-disable-next-line react-hooks-addons/no-unused-deps
    }, [
        supabase,
        isBlankRetroSelected,
        selectedRetro,
        showErrorToast,
        currentPeriod,
    ]);

    const getRetroImageSrc = useCallback(
        (period: string, isActive: boolean) => {
            const periodQuarter = period.slice(5);
            switch (periodQuarter) {
                case 'Q1': {
                    return isActive ? RetroOtter : RetroOtterInactive;
                }
                case 'Q2': {
                    return isActive ? RetroHawk : RetroHawkInactive;
                }
                case 'Q3': {
                    return isActive ? RetroDeer : RetroDeerInactive;
                }
                case 'Q4':
                default: {
                    return isActive ? RetroBear : RetroBearInactive;
                }
            }
        },
        []
    );

    const onSelectBlankRetro = useCallback(() => {
        setSelectedRetro(null);
        setIsBlankRetroSelected(true);

        sendEvent?.('retro_selected', {
            retro_period: currentPeriod,
        });
    }, [currentPeriod, sendEvent]);

    const onSelectRetro = useCallback(
        (retro: RetroData) => () => {
            setIsBlankRetroSelected(false);
            setSelectedRetro(retro);

            sendEvent?.('retro_selected', {
                retro_period: retro.period,
            });
        },
        [sendEvent]
    );

    const onClose = useCallback(() => {
        setIsBlankRetroSelected(false);
        setSelectedRetro(null);
    }, []);

    return (
        <>
            <MainWrapper>
                <AuthenticateCouncilMemberRoute />
                <Overlays.TextureOverlay />
                <BlueMountainsLandscape src={BlueMountainsBackgroundImage} />
                <RetrosWrapper>
                    <Heading>{`The Grand Retro`}</Heading>
                    <SubHeading>
                        {`This is your place to track your personal growth, stay accountable to your goals, and track your session learnings. Reflecting is your way to track progress.`}
                    </SubHeading>
                    <RetrosListWrapper>
                        <RetrosList>
                            {hasCompletedCurrentRetro ? null : (
                                <>
                                    <Fonts.Heading4>
                                        {`Complete a current assessment`}
                                    </Fonts.Heading4>
                                    <RetroWrapper
                                        onClick={onSelectBlankRetro}
                                        role="button"
                                        aria-label={`select-${currentPeriod}-retro`}
                                    >
                                        <img
                                            src={getRetroImageSrc(
                                                currentPeriod,
                                                true
                                            )}
                                        />
                                        <RetroTextWrapper>
                                            <Fonts.SmallCapsText>{`${currentPeriod} assessment`}</Fonts.SmallCapsText>
                                            <Fonts.SmallText>
                                                {`Start your ${currentPeriod} assessment. Completeting your retrospective enables you to reflect on where you were in this moment of time. Compare it to your past retrospectives for additional insights.`}
                                            </Fonts.SmallText>
                                        </RetroTextWrapper>
                                    </RetroWrapper>
                                </>
                            )}

                            {retros.length > 0 ? (
                                <>
                                    <Fonts.Heading4>
                                        {`Review a completed retrospective`}
                                    </Fonts.Heading4>
                                    {retros.map((retro) => {
                                        // TODO: figure out all this weird typing with supabase Json
                                        // to avoid this data massaging in multiple components
                                        // It's particularly non-performant here because it's not memoized
                                        const parsedSubtotals = JSON.parse(
                                            JSON.stringify(
                                                retro?.subtotals ?? ''
                                            )
                                        );
                                        return (
                                            <RetroWrapper
                                                key={retro.period}
                                                onClick={onSelectRetro(retro)}
                                                role="button"
                                                aria-label={`select-${retro.period}-retro`}
                                                $isPastRetro
                                            >
                                                <RetroUpperWrapper>
                                                    <img
                                                        src={getRetroImageSrc(
                                                            retro.period ?? '',
                                                            true
                                                        )}
                                                    />
                                                    <IndicatorIcon
                                                        src={Check}
                                                        $isPastRetro
                                                    />
                                                    <RetroTextWrapper>
                                                        <Fonts.SmallCapsText>{`${retro.period} retrospective`}</Fonts.SmallCapsText>
                                                        <Fonts.SmallText>
                                                            {retro.reflections ==
                                                                null ||
                                                            retro.reflections ==
                                                                ''
                                                                ? `View your ${retro.period} respective. Reviewing your retrospective enables you to reflect on where you were in this moment of time. Compare it to your current retrospective for additional insights.`
                                                                : `“${retro.reflections.slice(
                                                                      0,
                                                                      180
                                                                  )}${
                                                                      retro
                                                                          .reflections
                                                                          .length <
                                                                      180
                                                                          ? ''
                                                                          : '...'
                                                                  }”`}
                                                        </Fonts.SmallText>
                                                    </RetroTextWrapper>
                                                </RetroUpperWrapper>
                                                <AssessmentSubtotals
                                                    subtotals={parsedSubtotals}
                                                />
                                                <Fonts.SmallLink>
                                                    {`View more`}
                                                </Fonts.SmallLink>
                                                <Spacing.Bumper />
                                            </RetroWrapper>
                                        );
                                    })}
                                </>
                            ) : null}

                            <Fonts.Heading4>{`Available soon`}</Fonts.Heading4>
                            <RetroWrapper
                                role="button"
                                aria-label={`select-${nextPeriod}-retro`}
                                $inactive
                            >
                                <img
                                    src={getRetroImageSrc(nextPeriod, false)}
                                />
                                <IndicatorIcon src={Lock} />
                                <RetroTextWrapper>
                                    <Fonts.SmallCapsText>{`${nextPeriod} assessment`}</Fonts.SmallCapsText>
                                </RetroTextWrapper>
                            </RetroWrapper>
                        </RetrosList>
                    </RetrosListWrapper>
                </RetrosWrapper>
            </MainWrapper>

            {!isSafari || isBlankRetroSelected
                ? createPortal(
                      <Overlays.ModalOuterWrapper
                          $isOpen={isBlankRetroSelected}
                      >
                          <Overlays.BackgroundOverlay
                              onClick={onClose}
                              $isOpen={isBlankRetroSelected}
                          />
                          <Overlays.ModalInnerWrapper>
                              <Overlays.ModalInnerScroller>
                                  <SubmitAssessment
                                      onCompleteAssessment={onSelectRetro}
                                      onClose={onClose}
                                  />
                              </Overlays.ModalInnerScroller>
                          </Overlays.ModalInnerWrapper>
                      </Overlays.ModalOuterWrapper>,
                      document.getElementById('app-wrapper') ?? document.body
                  )
                : null}

            {!isSafari || selectedRetro != null
                ? createPortal(
                      <Overlays.ModalOuterWrapper
                          $isOpen={selectedRetro != null}
                      >
                          <Overlays.BackgroundOverlay
                              onClick={onClose}
                              $isOpen={selectedRetro != null}
                          />
                          <Overlays.ModalInnerWrapper>
                              <Overlays.ModalInnerScroller>
                                  <Reflection
                                      selectedRetro={selectedRetro}
                                      onClose={onClose}
                                  />
                              </Overlays.ModalInnerScroller>
                          </Overlays.ModalInnerWrapper>
                      </Overlays.ModalOuterWrapper>,
                      document.getElementById('app-wrapper') ?? document.body
                  )
                : null}
        </>
    );
}

export default Retrospectives;
