import styled, { css } from 'styled-components';

import { breakpoints, colors, Fonts, isMobile } from 'styles/theme';

export const CategoryWrapper = styled.div`
    width: 100%;
    display: flex;
`;

export const ResponsesWrapper = styled.div`
    margin-bottom: 12px;
    background-color: ${colors.borderWhite};
    border: 1px solid ${colors.borderDark};
    border-radius: 4px;
    width: 100%;
    padding: 12px;
    padding-bottom: 0;
    cursor: pointer;

    @media only screen and (max-width: ${breakpoints.medium}) {
        padding: 8px;
        padding-bottom: 0;
    }
`;

export const ResponseCategoryWrapper = styled.div`
    position: relative;
    width: 100%;
    padding: 4px 0 16px;
    display: flex;
    align-items: center;
    padding-right: 32px;

    @media only screen and (max-width: ${breakpoints.medium}) {
        padding: 4px 0 12px;
        padding-right: 32px;
    }
`;

interface DropdownProps {
    $isDropdownOpen: boolean;
}

export const DropdownCaret = styled.img<DropdownProps>`
    position: absolute;
    right: 4px;
    transition: 0.2s;

    ${(props) => {
        switch (props.$isDropdownOpen) {
            case true: {
                return css`
                    transform: scaleY(-1);
                `;
            }
            case false:
            default: {
                return;
            }
        }
    }};
`;

export const ResponseQuestionWrapper = styled.div`
    margin: 16px 0 32px;
    width: 100%;
    display: flex;
    align-items: center;

    &:last-of-type {
        margin-bottom: 20px;
    }
`;

export const ResponseNumber = styled(Fonts.Heading5)`
    margin-left: 8px;
    margin-right: 28px;
    min-width: 40px;
    text-align: center;

    @media only screen and (max-width: ${breakpoints.medium}) {
        margin-left: -4px;
        margin-right: 4px;
    }
`;

export const QuestionText = styled(isMobile ? Fonts.SmallText : Fonts.BodyText)`
    margin-right: 24px;
`;
