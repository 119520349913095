import React from 'react';
import Markdown from 'react-markdown';

// Using a plain CSS file to edit the child tags generated by react-markdown
import 'styles/markdownWrapper.css';

interface MarkdownWrapperProps {
    markdownString: string;
}

function MarkdownWrapper(props: MarkdownWrapperProps) {
    const { markdownString } = props;
    return (
        <div className="markdownWrapper">
            <Markdown>{markdownString}</Markdown>
        </div>
    );
}

export default MarkdownWrapper;
