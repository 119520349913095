import styled from 'styled-components';

import { breakpoints, colors, Fonts } from 'styles/theme';

const REPORT_CONTENT_WIDTH = 1000;

export const GroupIndexWrapper = styled.div`
    position: relative;
    z-index: 100;
    margin-top: -32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: ${breakpoints.small}) {
        width: 100vw;
        padding: 0 32px;
    }
`;

export const GroupReportListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;

    @media only screen and (max-width: ${breakpoints.small}) {
        width: 100vw;
        padding: 0 16px;
    }
`;

export const GroupReportCard = styled.div`
    margin: 12px;
    box-shadow: ${colors.boxShadow};
    border-radius: 8px;
    background-color: ${colors.light};
    width: 400px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    text-align: left;
    cursor: pointer;
    transition: 0.2s;

    @media only screen and (max-width: ${breakpoints.small}) {
        width: 100%;
        max-width: 400px;
    }

    &:hover {
        filter: brightness(95%);
    }
`;

export const MainWrapper = styled.div`
    background-color: ${colors.light};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
`;

export const Heading = styled.div`
    background-color: ${colors.dark1};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
`;

export const TopBarWrapper = styled.div`
    width: ${REPORT_CONTENT_WIDTH}px;
    padding: 20px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: ${breakpoints.small}) {
        width: 100%;
        padding: 32px;
        flex-direction: column;
    }
`;

export const TopBarText = styled.div`
    max-width: 400px;
    text-align: left;

    @media only screen and (max-width: ${breakpoints.small}) {
        margin-top: 24px;
    }
`;

export const CumulativeDataWrapper = styled.div`
    width: ${REPORT_CONTENT_WIDTH}px;
    padding: 40px 80px;
    display: flex;
    flex-direction: column;
    align-items: start;

    @media only screen and (max-width: ${breakpoints.small}) {
        width: 100%;
        padding: 32px 32px 12px;
    }
`;

export const CumulativeDataContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: ${breakpoints.small}) {
        flex-direction: column;
    }
`;

export const CumulativeDataSection = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;

    @media only screen and (max-width: ${breakpoints.small}) {
        width: 100%;
        margin-bottom: 20px;
    }
`;

export const LargeNumberText = styled(Fonts.BodyTextBold)`
    font-size: 36px;
    line-height: 64px;
`;

export const BulletList = styled.ol`
    list-style: outside;
    margin-top: 12px;
    margin-left: 32px;
    text-align: left;
`;

export const ReportDetailsWrapper = styled.div`
    width: ${REPORT_CONTENT_WIDTH}px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: ${breakpoints.small}) {
        padding: 0 24px;
        width: 100%;
    }
`;

export const RatingOuterWrapper = styled.div`
    margin-top: 32px;
    border: 1px solid ${colors.borderDark};
    background-color: ${colors.borderWhiteBold};
    width: 800px;
    padding: 32px 48px;
    display: flex;
    align-items: center;
    text-align: left;

    @media only screen and (max-width: ${breakpoints.small}) {
        margin-top: 20px;
        width: 100%;
        padding: 20px 32px;
    }
`;

export const RatingImageWrapper = styled.div`
    margin-left: 32px;
    border-radius: 16px;
    background-color: ${colors.primary3};
    padding: 12px;
    cursor: pointer;

    @media only screen and (max-width: ${breakpoints.small}) {
        margin-left: 20px;
        padding: 12px;
    }
`;
