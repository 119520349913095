import styled, { keyframes } from 'styled-components';

import { colors } from 'styles/theme';

export const LoaderWrapper = styled.div`
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
`;

const RingAnimation = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `;

const LoaderRing = styled.div`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: ${RingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${colors.white} transparent transparent transparent;
`;

export const LoadingRingOne = styled(LoaderRing)`
    animation-delay: -0.45s;
`;

export const LoadingRingTwo = styled(LoaderRing)`
    animation-delay: -0.3s;
`;

export const LoadingRingThree = styled(LoaderRing)`
    animation-delay: -0.15s;
`;
